<template>
  <div
    class="control has-icons-right kydemy-datetime-picker kydemy-datetime-picker-input"
    :class="{'has-icons-left': leftIcon, 'is-loading': loading, 'is-fullwidth': isFullWidth}"
    :style="{width: fieldWidth, 'max-width': maxWidth ? maxWidth : null }"
  >
    <div
      :class="{'is-active': isActive, 'is-up': effectiveIsUp, 'is-right': isRight, [`is-${size}`]: size, 'is-fullwidth': isFullWidth || minWidth}"
      class="dropdown dropdown-list"
    >
      <div class="dropdown-trigger">
        <div class="field has-addons">
          <div
            class="control has-icons-left has-icons-right"
            :class="{ 'flex-max': addClear}"
          >
            <input
              :id="id"
              ref="dropdownTrigger"
              v-model="inputText"
              v-tooltip="{ content: tooltip, trigger: 'manual', show: focused, placement: tooltipPlacement, classes: tooltipClass }"
              class="input"
              type="text"
              :placeholder="placeholderText"
              :class="{[`is-${size}`]: size, 'is-rounded': isRounded, 'has-text-right': hasTextRight,'has-text-centered': hasTextCenter}"
              :disabled="disabled || loading"
              readonly
              @focus="focused = true"
              @blur="focused = false"
              @click="onInputClick"
            >
            <span
              v-if="leftIcon"
              class="icon is-small is-left"
            >
              <font-awesome-icon :icon="leftIcon" />
            </span>
            <span
              v-if="!loading"
              class="icon is-small is-right"
            >
              <font-awesome-icon :icon="isActive ? 'chevron-up': 'chevron-down'" />
            </span>
          </div>
          <div
            v-if="addClear"
            class="control flex-min"
          >
            <button
              class="button"
              :class="{ 'is-rounded': isRounded , 'is-danger-light': selectedDate !== null, [`is-${size}`]: size, 'p-h-05': size === 'small'}"
              :disabled="selectedDate === null"
              @click="selectedDate = null"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
      </div>
      <div
        id="dropdown-menu"
        ref="dropdownMenu"
        class="dropdown-menu is-fixed"
        role="menu"
      >
        <KydemyDateTimePicker
          v-model="selectedDate"
          :locale="locale"
          :highlighted-days="highlightedDays"
          :disabled-days="disabledDays"
          :enabled-days="enabledDays"
          :range="range"
          :multiple="multiple"
          :selectable="selectable"
          :disabled="disabled"
          :only-selected-clickable="onlySelectedClickable"
          :display-month="displayMonth"
          :interactive="interactive"
          :min-date="minDate"
          :max-date="maxDate"
          :time-enabled="timeEnabled"
          :is24h="is24hFormat()"
          @displayDate="onDisplaDateChange"
        />
        <div class="close-dropdown-button">
          <button
            class="delete is-large"
            aria-label="close"
            @click="isActive = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KydemyMonthCalendarMixin from './KydemyMonthCalendarMixin'
import KydemyDateTimePicker from './KydemyDateTimePicker'

export default {
  name: 'KydemyDateTimePickerInput',
  components: { KydemyDateTimePicker },
  mixins: [KydemyMonthCalendarMixin],
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    leftIcon: {
      type: [String, Array],
      default: 'calendar'
    },
    id: {
      type: [String],
      default: null
    },
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: null
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    hasTextRight: {
      type: Boolean,
      default: false
    },
    hasTextCenter: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom'
    },
    tooltipClass: {
      type: String,
      default: 'kydemyTooltip'
    },
    placeholder: {
      type: String,
      default: null
    },
    formatPattern: {
      type: String,
      default: null
    },
    isUp: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    interactive: {
      type: Boolean,
      default: false
    },
    isUpperCase: {
      type: Boolean,
      default: false
    },
    addClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focused: false,
      isActive: false,
      displayDate: null,
      selectedDate: null,
      effectiveIsUp: false,
      inputText: ''
    }
  },
  computed: {
    fieldWidth () {
      if (this.isFullWidth) {
        return '100%'
      } else if (this.minWidth) {
        return this.minWidth
      }
      return 'auto'
    },
    placeholderText () {
      let value = ''
      if (this.placeholder) {
        value = this.placeholder
      } else if (this.displayDate) {
        value = this.formatDate(this.displayDate)
      }
      if (this.isUpperCase) {
        value.toUpperCase()
      }
      return value
    }
  },
  watch: {
    value (newVal) {
      if (this.selectedDate !== newVal) {
        this.selectedDate = newVal
      }
    },
    selectedDate (newVal) {
      if (this.bindString) {
        this.$emit('input', newVal.toISOString(true))
      } else {
        this.$emit('input', newVal)
      }
      if (this.autoClose) {
        this.isActive = false
      }
      let newText = newVal ? this.formatDate(newVal) : ''
      if (this.isUpperCase) {
        newText = newText.toUpperCase()
      }
      this.inputText = newText.replace(/\./g, '')
    },
    isActive (newVal) {
      this.updatePosition()
      if (this.$eventHub && newVal === true) {
        this.$eventHub.$emit('expand-dropdown', this._uid)
      }
    },
    isUp (newVal) {
      this.effectiveIsUp = newVal
      this.updatePosition()
    }
  },
  created () {
    this.selectedDate = this.value
    if (this.$eventHub) {
      this.$eventHub.$on('expand-dropdown', (controlUID) => {
        if (this._uid !== controlUID) {
          this.isActive = false
        }
      })
    }
    this.effectiveIsUp = this.isUp
  },
  mounted () {
    this.updatePosition()
  },
  methods: {
    show () {
      this.isActive = true
    },
    updatePosition () {
      const trigger = this.$refs.dropdownTrigger
      const menu = this.$refs.dropdownMenu
      const triggerBox = trigger.getBoundingClientRect()
      const menuBoxHeight = this.timeEnabled ? 390 : 350
      if (this.isActive) {
        const newUp = (triggerBox.top + triggerBox.height)
        const newBottom = (window.innerHeight - triggerBox.top)
        if (this.effectiveIsUp && (newBottom - menuBoxHeight) < 20) {
          this.effectiveIsUp = false
        }
        if (this.effectiveIsUp === false && (newUp + menuBoxHeight) > window.innerHeight) {
          this.effectiveIsUp = true
        }

        if (this.effectiveIsUp) {
          menu.style.top = 'unset'
          menu.style.bottom = newBottom + 'px'
        } else {
          menu.style.top = newUp + 'px'
          menu.style.bottom = 'unset'
        }
        if (this.isRight) {
          menu.style.left = 'unset'
          menu.style.right = (window.innerWidth - (triggerBox.left + triggerBox.width)) + 'px'
        } else {
          menu.style.left = triggerBox.left + 'px'
          menu.style.right = 'unset'
        }
      }
    },
    formatDate (date) {
      if (!date || typeof date !== 'object') {
        return null
      } else if (Array.isArray(date)) {
        return date.map(d => this.formatDate(d)).join(' - ')
      }
      if (this.formatPattern) {
        return date.format(this.formatPattern)
      }
      return date.format('dddd D MMMM, YYYY')
    },
    onInputClick () {
      this.isActive = !this.isActive
    },
    onDisplaDateChange (newDD) {
      this.displayDate = newDD
    }
  }
}
</script>
