<template>
  <div
    v-if="enabled"
    class="columns is-centered is-mobile kydemy-social-icons"
  >
    <div
      v-if="websiteURL"
      v-tooltip="$t('social_networks.website')"
      class="column is-narrow"
    >
      <a
        :href="websiteURL"
        class="avatar website"
      >
        <span class="icon is-large has-text-white">
          <font-awesome-icon
            :icon="['fab', 'wordpress']"
            size="2x"
          />
        </span>
      </a>
    </div>
    <div
      v-if="facebookURL"
      v-tooltip="$t('social_networks.facebook')"
      class="column is-narrow"
    >
      <a
        :href="facebookURL"
        class="avatar facebook"
      >
        <span class="icon is-large has-text-white">
          <font-awesome-icon
            size="2x"
            :icon="['fab', 'facebook-f']"
          />
        </span>
      </a>
    </div>
    <div
      v-if="instagramURL"
      v-tooltip="$t('social_networks.instagram')"
      class="column is-narrow"
    >
      <a
        :href="instagramURL"
        class="avatar instagram"
      >
        <span class="icon is-large has-text-white">
          <font-awesome-icon
            size="2x"
            :icon="['fab', 'instagram']"
          />
        </span>
      </a>
    </div>
    <div
      v-if="twitterURL"
      v-tooltip="$t('social_networks.twitter')"
      class="column is-narrow"
    >
      <a
        :href="twitterURL"
        class="avatar twitter"
      >
        <span class="icon is-large has-text-white">
          <font-awesome-icon
            size="2x"
            :icon="['fab', 'twitter']"
          />
        </span>
      </a>
    </div>
    <div
      v-if="youtubeURL"
      v-tooltip="$t('social_networks.youtube')"
      class="column is-narrow"
    >
      <a
        :href="youtubeURL"
        class="avatar youtube"
      >
        <span class="icon is-large has-text-white">
          <font-awesome-icon
            size="2x"
            :icon="['fab', 'youtube']"
          />
        </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SocialNetworkIcons',
  props: {
    configuration: { type: Object, default: null }
  },
  data () {
    return {
      socialNetworksURLs: null,
      enabled: false
    }
  },
  computed: {
    websiteURL () {
      return this.getURL('WEBSITE_URL')
    },
    facebookURL () {
      return this.getURL('FACEBOOK_URL')
    },
    instagramURL () {
      return this.getURL('INSTAGRAM_URL')
    },
    twitterURL () {
      return this.getURL('TWITTER_URL')
    },
    youtubeURL () {
      return this.getURL('YOUTUBE_URL')
    }
  },
  watch: {
    configuration () {
      this.updateSocialNetworks()
    }
  },
  created () {
    if (!this.effectiveRole && this.$store.getters.hasSettings === true) {
      this.enabled = true
    } else {
      this.updateSocialNetworks()
    }
  },
  methods: {
    updateSocialNetworks () {
      this.enabled = false
      if (this.configuration && this.configuration.social_networks) {
        this.socialNetworksURLs = {}
        for (const socialLink of this.configuration.social_networks) {
          if (socialLink && socialLink.value && socialLink.value.length > 5) {
            this.socialNetworksURLs[socialLink.code] = socialLink
          }
        }
        this.enabled = true
      } else {
        this.enabled = false
        this.socialNetworksURLs = null
      }
    },
    getURL (code) {
      if (this.socialNetworksURLs && code in this.socialNetworksURLs) {
        return this.socialNetworksURLs[code].value.trim()
      } else if (this.$store.getters.hasSettings === true) {
        const setting = this.getSetting(code)
        if (setting && setting.value && setting.value.length > 0) {
          return setting.value.trim()
        }
      }
      return null
    }
  }
}
</script>
