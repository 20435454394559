import color from 'color'

const colors = [
  '#9B59B6',
  '#3498DB',
  '#1ABC9C',
  '#E74C3C',
  '#16A085',
  '#F39C12',
  '#C0392B',
  '#27AE60',
  '#2980B9',
  '#F1C40F',
  '#2C3E50',
  '#32CD32',
  '#E67E22',
  '#6B8E23',
  '#D35400',
  '#BDC3C7',
  '#8E44AD',
  '#008080',
  '#4682B4',
  '#95A5A6',
  '#2ECC71',
  '#4169E1',
  '#BC8F8F',
  '#663399',
  '#00CED1',
  '#707B7C',
  '#1E90FF',
  '#8B4513',
  '#34495E',
  '#BDB76B',
  '#CC6384'
]
const darkColors = []
const ligthColors = []
let kolor
for (const cIndex in colors) {
  kolor = color(colors[cIndex])
  darkColors.push(kolor.darken(0.3).string())
  ligthColors.push(kolor.lighten(0.7).string())
}

export default { colors: colors, darkColors: darkColors, ligthColors: ligthColors, color: color() }
