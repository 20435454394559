import rest from '../../rest'

const STORAGE_KEY = 'TRACKS'
const STORAGE_KEY_DEFAULT = 'DEFAULT_TRACK'
const TRACKS_LOADED = 'TRACKS_LOADED'
const TRACKS_CLEAR = 'TRACKS_CLEAR'

const state = {
  tracks: localStorage.getItem(STORAGE_KEY) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY)),
  defaultTrack: localStorage.getItem(STORAGE_KEY_DEFAULT) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY_DEFAULT)),
  tracksLoaded: localStorage.getItem(STORAGE_KEY) !== null
}

const mutations = {
  [TRACKS_LOADED] (state) {
    const tracksDataStr = localStorage.getItem(STORAGE_KEY)
    if (tracksDataStr && tracksDataStr.length > 0) {
      state.tracksLoaded = true
      const tracks = JSON.parse(tracksDataStr)
      state.tracks = tracks
      const filtered = tracks.filter(t => t.default_track === true)
      if (filtered && filtered.length > 0) {
        localStorage.setItem(STORAGE_KEY_DEFAULT, JSON.stringify(filtered[0]))
        state.defaultTrack = filtered[0]
      }
    } else {
      state.tracksLoaded = false
      state.tracks = null
      state.defaultTrack = null
    }
  },
  [TRACKS_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY)
    localStorage.removeItem(STORAGE_KEY_DEFAULT)
    state.tracksLoaded = false
    state.tracks = null
    state.defaultTrack = null
  }
}

const actions = {
  async getMembershipTracks ({ commit }) {
    commit(TRACKS_CLEAR)
    const response = await rest.getAxiosRequest('memberships/tracks/')
    if (response.status === 200) {
      const sortedTracks = response.data.sort((a, b) => a.track_letter.charCodeAt(0) - b.track_letter.charCodeAt(0))
      localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedTracks))
      commit(TRACKS_LOADED)
    } else {
      localStorage.removeItem(STORAGE_KEY)
      localStorage.removeItem(STORAGE_KEY_DEFAULT)
      commit(TRACKS_LOADED)
    }
  },
  async clearMembershipTracks ({ commit }) {
    commit(TRACKS_CLEAR)
  }
}
export default {
  state,
  mutations,
  actions
}
