import Vue from 'vue'
import Vuex from 'vuex'
import rest from '../rest'
import modules from './modules'
import ModulesFunctionsPlugin from './modules/modules-functions-plugin'

Vue.use(Vuex)

const CONFIGURATION = 'CONFIGURATION'
const LOGIN = 'LOGIN'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_ERROR = 'LOGIN_ERROR'
const LOGIN_ACTIVE_ERROR = 'LOGIN_ACTIVE_ERROR'
const LOGOUT = 'LOGOUT'
const CLEAR = 'CLEAR'
const ACCOUNT_DATA = 'ACCOUNT_DATA'
const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA'
const ACCOUNT_DATA_ERROR = 'ACCOUNT_DATA_ERROR'
const SETTINGS = 'SETTINGS'
const MEMBERSHIPS = 'MEMBERSHIPS'
const MEMBERSHIPS_ERROR = 'MEMBERSHIPS_ERROR'
const RELATIVES = 'RELATIVES'
const RELATIVES_ERROR = 'RELATIVES_ERROR'
const DISCOUNTS = 'DISCOUNTS'
const DISCOUNTS_ERROR = 'DISCOUNTS_ERROR'
const OUTDATED_CLIENT = 'OUTDATED_CLIENT'
const EXPIRED_CLIENT = 'EXPIRED_CLIENT'
const DIFFERENT_CLIENT = 'DIFFERENT_CLIENT'
const TIMEKEEPER = 'TIMEKEEPER'
const PENDING_STATUS = 'PENDING_STATUS'

const store = new Vuex.Store({
  modules,
  plugins: [ModulesFunctionsPlugin],
  state: {
    Configuration: localStorage.getItem('configuration') !== null ? JSON.parse(localStorage.getItem('configuration')) : null,
    AccountData: localStorage.getItem('account_data') !== null ? JSON.parse(localStorage.getItem('account_data')) : null,
    AccountID: localStorage.getItem('account_id'),
    Token: localStorage.getItem('token'),
    isLoggedIn: !!localStorage.getItem('token'),
    Settings: localStorage.getItem('settings') !== null ? JSON.parse(localStorage.getItem('settings')) : null,
    Relatives: localStorage.getItem('relatives') !== null ? JSON.parse(localStorage.getItem('relatives')) : null,
    Discounts: localStorage.getItem('discounts') !== null ? JSON.parse(localStorage.getItem('discounts')) : null,
    TimeKeeperSettings: localStorage.getItem('timekeeper') !== null ? JSON.parse(localStorage.getItem('timekeeper')) : null,
    pending: false,
    loginError: false,
    loginActiveError: false,
    settingsMap: null,
    loadingMemberships: false,
    Memberships: localStorage.getItem('memberships') ? JSON.parse(localStorage.getItem('memberships')) : null,
    membershipsMap: null,
    membershipsIDMap: null,
    numMessagesUnread: 0,
    numPendingRegistrations: 0,
    numNotificationsUnread: 0,
    StripeClientID: localStorage.getItem('StripeClientID') !== null ? localStorage.getItem('StripeClientID') : null,
    StripeAPIKey: localStorage.getItem('StripeAPIKey') !== null ? localStorage.getItem('StripeAPIKey') : null,
    effectiveRole: null,
    loadingSettings: false,
    loadingTimeKeeperSettings: false,
    loadingRelatives: false,
    loadingDiscounts: false,
    outdatedClient: false,
    expiredClient: false,
    differentClient: false
  },
  mutations: {
    [CONFIGURATION] (state) {
      const confStr = localStorage.getItem('configuration')
      if (confStr && confStr.length > 0) {
        state.Configuration = JSON.parse(confStr)
        state.StripeAPIKey = state.Configuration.stripe_pk
        state.StripeClientID = state.Configuration.stripe_id
        localStorage.setItem('StripeAPIKey', state.StripeAPIKey)
        localStorage.setItem('StripeClientID', state.StripeClientID)
      }
    },
    [LOGIN] (state) {
      state.pending = true
      state.loginError = false
      state.loginActiveError = false
    },
    [LOGIN_SUCCESS] (state) {
      state.isLoggedIn = true
      state.pending = true
      state.Token = localStorage.getItem('token')
      state.AccountID = parseInt(localStorage.getItem('account_id'))
      state.loginError = false
      state.loginActiveError = false
      this.dispatch('getSettings')
    },
    [LOGIN_ERROR] (state) {
      state.isLoggedIn = false
      state.pending = false
      state.loginError = true
      state.loginActiveError = false
    },
    [LOGIN_ACTIVE_ERROR] (state) {
      state.isLoggedIn = false
      state.pending = false
      state.loginError = false
      state.loginActiveError = true
    },
    [LOGOUT] (state) {
      state.effectiveRole = null
      state.AccountData = null
      state.AccountID = null
      state.Token = null
      state.isLoggedIn = false
      state.pending = false
      state.loginError = false
      state.loginActiveError = false
      state.outdatedClient = false
      console.log('EMITTING LOGOUT!!')
      this._vm.$eventHub.$emit('logout')
    },
    [CLEAR] (state) {
      state.isLoggedIn = false
      state.pending = false
      state.Token = null
      state.AccountID = null
      state.AccountData = null
      state.Discounts = null
      state.Memberships = null
      state.Settings = null
      state.Relatives = null
      state.loginError = false
      state.loginActiveError = false
      state.loadingSettings = false
      state.loadingRelatives = false
      state.loadingDiscounts = false
      state.outdatedClient = false
      state.TimeKeeperSettings = null
      state.loadingTimeKeeperSettings = false
    },
    [UPDATE_ACCOUNT_DATA] (state) {
      state.AccountData = null
      state.AccountData = JSON.parse(localStorage.getItem('account_data'))
      state.AccountID = state.AccountData.account_id
    },
    [ACCOUNT_DATA] (state) {
      state.effectiveRole = null
      state.AccountData = JSON.parse(localStorage.getItem('account_data'))
      state.AccountID = state.AccountData.account_id
      state.effectiveRole = store.getters.effectiveRole
      // state.pending = false
      if (store.getters.hasMemberships === false) {
        this.dispatch('loadMemberships')
      }
      if (store.getters.hasRelatives === false) {
        this.dispatch('loadRelatives')
      }
      if (store.getters.hasDiscounts === false) {
        this.dispatch('loadDiscounts')
      }
      const roles = store.getters.accountRoles ? store.getters.accountRoles : []
      if (roles.includes('teacher') || roles.includes('manager') || roles.includes('admin')) {
        if (store.getters.getSetting('TIME_KEEPER_ENABLED').value === true && store.getters.hasTimeKeeperSettings === null) {
          this.dispatch('loadTimeKeeperSettings')
        }
      } else {
        this.TimeKeeperSettings = false
      }
      if (!store.state.classrooms.classroomsLoaded) {
        this.dispatch('getClassrooms')
      }
      this._vm.$eventHub.$emit('login', state.AccountID)
    },
    [ACCOUNT_DATA_ERROR] (state) {
      state.isLoggedIn = false
      state.pending = false
      state.AccountData = null
      this.dispatch('logout')
    },
    [SETTINGS] (state) {
      state.Settings = null
      state.Settings = JSON.parse(localStorage.getItem('settings'))
      state.settingsMap = new Map()
      state.Settings.forEach(s => {
        state.settingsMap.set(s.code, s)
      })
      if (store.getters.hasAccountData === false) {
        this.dispatch('getAccountData')
      } else {
        if (store.getters.hasRelatives === false) {
          this.dispatch('loadRelatives')
        }
        if (store.getters.hasDiscounts === false) {
          this.dispatch('loadDiscounts')
        }
      }
      state.loadingSettings = false
    },
    [TIMEKEEPER] (state) {
      state.TimeKeeperSettings = null
      state.TimeKeeperSettings = JSON.parse(localStorage.getItem('timekeeper'))
      state.loadingTimeKeeperSettings = false
    },
    [MEMBERSHIPS] (state) {
      state.Memberships = null
      state.Memberships = JSON.parse(localStorage.getItem('memberships'))
    },
    [MEMBERSHIPS_ERROR] (state) {
      state.Memberships = null
      this.dispatch('logout')
    },
    [RELATIVES] (state) {
      state.Relatives = null
      state.Relatives = JSON.parse(localStorage.getItem('relatives'))
      state.loadingRelatives = false
      const roles = state.AccountData && state.AccountData.roles ? state.AccountData.roles : []
      if (state.Relatives.length === 0 && roles.includes('student')) {
        store.dispatch('effectiveRole', 'student')
      }
    },
    [RELATIVES_ERROR] (state) {
      state.Relatives = null
      this.dispatch('logout')
    },
    [DISCOUNTS] (state) {
      state.Discounts = null
      state.Discounts = JSON.parse(localStorage.getItem('discounts'))
      state.loadingDiscounts = false
    },
    [DISCOUNTS_ERROR] (state) {
      state.Discounts = null
      this.dispatch('logout')
    },
    [OUTDATED_CLIENT] (state) {
      state.outdatedClient = true
    },
    [EXPIRED_CLIENT] (state) {
      this.dispatch('clear')
      state.expiredClient = true
    },
    [DIFFERENT_CLIENT] (state) {
      this.dispatch('clear')
      state.differentClient = true
    },
    [PENDING_STATUS] (state, statusValues) {
      state.numMessagesUnread = statusValues.messages
      state.numNotificationsUnread = statusValues.notifications
      if (statusValues.registrations) {
        state.numPendingRegistrations = statusValues.registrations
      }
    }
  },
  actions: {
    async loadConfiguration ({ commit }, force) {
      if (store.state.Configuration !== null && !force) {
        return
      }
      try {
        const response = await rest.getAxiosRequest('config/')
        if (response.status && response.status === 200) {
          localStorage.setItem('configuration', JSON.stringify(response.data))
        }
      } catch (err) {
        localStorage.removeItem('configuration')
      }
      commit(CONFIGURATION)
    },
    reloadKydemy () {
      store.dispatch('clear')
      window.localStorage.setItem('redirectLogin', true)
      window.location.reload(true)
    },
    updateStatus ({ commit }, statusValues) {
      commit(PENDING_STATUS, statusValues)
      store.dispatch('updateNumStreamingClasses', statusValues.streaming)
    },
    async login ({ commit }, creds) {
      commit(LOGIN)
      const apiResponse = await rest.apiPost('accounts/login/', {
        username: creds.username,
        password: creds.password
      })
      if (apiResponse.hasContent()) {
        localStorage.setItem('token', apiResponse.data.token)
        localStorage.setItem('account_id', apiResponse.data.account_id)
        localStorage.setItem('remember_me', creds.rememberMe)
        localStorage.setItem('account_data', null)
        commit(LOGIN_SUCCESS)
      } else {
        if (apiResponse.status === 423) {
          commit(LOGIN_ACTIVE_ERROR)
        } else {
          commit(LOGIN_ERROR)
        }
      }
    },
    effectiveRole ({ commit }, effectiveRole) {
      localStorage.setItem('effectiveRole', effectiveRole)
      store.state.effectiveRole = effectiveRole
    },
    impersonate ({ commit }, impData) {
      const impToken = localStorage.getItem('token')
      const impAccountID = localStorage.getItem('account_id')
      store.dispatch('clearLocalStore')
      store.dispatch('updateStreamingClasses', [])
      if (impData.roles) {
        if (impData.roles.includes('teacher')) {
          store.dispatch('effectiveRole', 'teacher')
        } else if (impData.roles.includes('relative')) {
          store.dispatch('effectiveRole', 'relative')
        } else if (impData.roles.includes('student')) {
          store.dispatch('effectiveRole', 'student')
        }
      }
      localStorage.setItem('imp_token', impToken)
      localStorage.setItem('imp_account_id', impAccountID)
      localStorage.setItem('imp_fullname', store.getters.fullName)
      localStorage.setItem('imp', true)
      localStorage.setItem('token', impData.token)
      localStorage.setItem('account_id', impData.id)
      localStorage.setItem('remember_me', false)
      localStorage.setItem('memberships', JSON.stringify(store.state.Memberships))
      localStorage.setItem('discounts', JSON.stringify(store.state.Discounts))
      store.state.AccountData = null
      store.state.Relatives = null
      store.state.TimeKeeperSettings = null
      store.state.Discounts = null
      store.state.Memberships = null
      this._vm.$eventHub.$emit('reconnect-ws')
      store.dispatch('getAccountData')
    },
    stopImpersonation () {
      const redirectAccountID = localStorage.getItem('account_id')
      const token = localStorage.getItem('imp_token')
      const accountID = parseInt(localStorage.getItem('imp_account_id'))
      store.dispatch('clearLocalStore')
      store.state.effectiveRole = null
      store.state.AccountData = null
      store.state.AccountID = accountID
      localStorage.setItem('token', token)
      localStorage.setItem('account_id', accountID.toString())
      localStorage.setItem('redirect_account_id', redirectAccountID)
      localStorage.setItem('memberships', JSON.stringify(store.state.Memberships))
      localStorage.setItem('discounts', JSON.stringify(store.state.Discounts))
      this._vm.$eventHub.$emit('reconnect-ws')
      store.dispatch('getSettings')
      store.dispatch('loadTimeKeeperSettings')
    },
    async reloadAccountData ({ commit }) {
      const accID = store.state.AccountID
      if (accID == null || accID <= 0) {
        return
      }
      try {
        const response = await rest.getAxiosRequest(`accounts/${accID}/`)
        if (response && response.status === 200) {
          localStorage.setItem('account_data', JSON.stringify(response.data))
          commit(UPDATE_ACCOUNT_DATA)
        }
      } catch (err) {
        /* eslint no-console: "off" */
        console.log('ERROR Updating account data', err)
      }
    },
    getAccountData ({ commit }) {
      const accID = localStorage.getItem('account_id')
      if (accID == null || accID <= 0) {
        return
      }
      store.state.AccountData = null
      rest.getAxiosRequest('accounts/' + accID + '/').then(
        response => {
          localStorage.setItem('account_data', JSON.stringify(response.data))
          commit(ACCOUNT_DATA)
        }
      ).catch(
        () => {
          commit(ACCOUNT_DATA_ERROR)
        }
      )
    },
    async getSettings ({ commit }) {
      if (store.state.loadingSettings === false && store.state.isLoggedIn === true) {
        store.state.loadingSettings = true
        store.state.Settings = null
        try {
          let response = await rest.getAxiosRequest('config/settings/')
          const platformSettings = response.data
          try {
            response = await rest.getAxiosRequest(`accounts/${store.state.AccountID}/settings/`)
            if (response.status === 200) {
              platformSettings.push(...response.data)
            }
          } catch (_) {}
          localStorage.setItem('settings', JSON.stringify(platformSettings))
          commit(SETTINGS)
        } catch (_) {
          commit(ACCOUNT_DATA_ERROR)
        }
      }
    },
    loadTimeKeeperSettings ({ commit }) {
      if (store.state.loadingTimeKeeperSettings === false && store.state.isLoggedIn === true) {
        store.state.loadingTimeKeeperSettings = true
        store.state.TimeKeeperSettings = null
        rest.getAxiosRequest('staff/' + store.state.AccountID + '/timekeeper/settings/').then(
          response => {
            localStorage.setItem('timekeeper', JSON.stringify(response.data))
            commit(TIMEKEEPER)
          }
        ).catch(
          () => {
            store.state.loadingTimeKeeperSettings = false
            store.state.TimeKeeperSettings = false
          }
        )
      }
    },
    logout ({ commit }) {
      store.dispatch('clear')
      store.dispatch('clearLocalStore')
      store.dispatch('clearSubscriptionContents')
      localStorage.removeItem('imp_fullname')
      localStorage.removeItem('imp_token')
      localStorage.removeItem('imp_account_id')
      commit(LOGOUT)
    },
    clear ({ commit }) {
      store.dispatch('clearLocalStore')
      commit(CLEAR)
    },
    async reloadMemberships () {
      store.state.Memberships = null
      store.state.membershipsMap = null
      store.state.membershipsIDMap = null
      await store.dispatch('loadMemberships')
    },
    async loadMemberships ({ commit }) {
      if (store.state.isLoggedIn === true && store.state.Memberships === null && !store.state.loadingMemberships) {
        store.state.loadingMemberships = true
        store.state.Memberships = null
        localStorage.removeItem('memberships')
        const apiResponse = await rest.apiGet('memberships/')
        if (apiResponse.isError()) {
          commit(MEMBERSHIPS_ERROR)
        } else {
          if (apiResponse.hasContent()) {
            localStorage.setItem('memberships', JSON.stringify(apiResponse.data))
          } else {
            localStorage.setItem('memberships', JSON.stringify([]))
          }
          commit(MEMBERSHIPS)
        }
        store.state.loadingMemberships = false
      }
    },
    clearLocalStore () {
      let buildVersion = localStorage.getItem('build_version')
      if (!buildVersion || buildVersion.length === 0) {
        buildVersion = '9118ebd15ff1474d1ad4040ae45358bd912d01f0'
      }
      const commonSearches = localStorage.getItem('quick-searches')
      localStorage.clear()
      localStorage.setItem('build_version', buildVersion)
      if (commonSearches) {
        localStorage.setItem('quick-searches', commonSearches)
      }
      sessionStorage.clear()
    },
    loadRelatives ({ commit }) {
      const setting = store.state.settingsMap.get('RELATIVES_ENABLED')
      if (setting && setting.value === true && store.state.loadingRelatives === false &&
        store.getters.accountID && store.getters.accountID > 0) {
        store.state.loadingRelatives = true
        const self = this
        self.relatives = null
        const relURL = 'accounts/' + store.getters.accountID + '/relatives/'
        rest.getAxiosRequest(relURL).then(
          response => {
            if (response.status === 200) {
              const relatives = response.data.filter(r => ['child', 'grandchild'].indexOf(r.type) >= 0)
              localStorage.setItem('relatives', JSON.stringify(relatives))
              commit(RELATIVES)
            }
            store.state.loadingRelatives = false
          }
        ).catch(
          e => {
            commit(RELATIVES_ERROR)
            store.state.loadingRelatives = false
          }
        )
      }
    },
    loadDiscounts ({ commit }) {
      const discountsSetting = store.state.settingsMap.get('DISCOUNTS_ENABLED')
      if (discountsSetting && discountsSetting.value === true && store.state.loadingDiscounts === false) {
        store.state.loadingDiscounts = true
        const self = this
        self.Discounts = null
        const relURL = 'discounts/'
        rest.getAxiosRequest(relURL).then(
          response => {
            if (response.status === 200) {
              const discounts = response.data
              localStorage.setItem('discounts', JSON.stringify(discounts))
            } else {
              localStorage.setItem('discounts', JSON.stringify([]))
            }
            commit(DISCOUNTS)
            store.state.loadingDiscounts = false
          }
        ).catch(
          e => {
            commit(DISCOUNTS_ERROR)
            store.state.loadingDiscounts = false
          }
        )
      }
    },
    outdatedClient ({ commit }) {
      commit(OUTDATED_CLIENT)
    },
    expiredClient ({ commit }) {
      commit(EXPIRED_CLIENT)
    },
    differentClient ({ commit }) {
      commit(DIFFERENT_CLIENT)
    }
  },
  getters: {
    applicationRoles: () => {
      const appRoles = ['student', 'teacher', 'manager', 'admin']
      const relSetting = store.getters.getSetting('RELATIVES_ENABLED')
      const subSetting = store.getters.getSetting('SUBSCRIPTIONS_ENABLED')
      const leadSetting = store.getters.getSetting('LEADS_ENABLED')
      if (relSetting && relSetting.value && relSetting.value === true) {
        appRoles.push('relative')
      }
      if (subSetting && subSetting.value && subSetting.value === true) {
        appRoles.push('subscriber')
      }
      if (leadSetting && leadSetting.value && leadSetting.value === true) {
        appRoles.push('lead')
      }
      return appRoles
    },
    getAuthenticationToken: state => {
      return state.Token
    },
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    isLoading: state => {
      return state.pending
    },
    isLoginError: state => {
      return state.loginError
    },
    hasConfiguration: state => {
      return state.Configuration !== null
    },
    hasAccountData: state => {
      return state.AccountData !== false && state.AccountData !== null
    },
    hasMemberships: state => {
      const has = state.Memberships !== false && state.Memberships !== null && Array.isArray(state.Memberships) && state.Memberships.length > 0
      if (has === false) {
        store.dispatch('loadMemberships')
      }
      return has
    },
    accountRoles: state => {
      if (state.AccountData !== false && state.AccountData != null) {
        return state.AccountData.roles
      }
      return null
    },
    accountData: state => {
      if (store.getters.hasAccountData === true) {
        return state.AccountData
      }
      return null
    },
    accountLevels: state => {
      if (store.getters.hasAccountData === true) {
        return state.AccountData.levels
      }
      return null
    },
    accountID: state => {
      if (store.getters.hasAccountData === true) {
        return state.AccountData.account_id
      }
      return null
    },
    fullName: state => {
      if (store.getters.hasAccountData === true) {
        return state.AccountData.firstname + ' ' + state.AccountData.lastname
      }
    },
    hasAvatar: state => {
      return store.getters.hasAccountData === true && state.AccountData.avatar != null && state.AccountData.avatar.thumbnail_url !== null
    },
    avatarURL: state => {
      if (store.getters.hasAvatar === true) {
        return state.AccountData.avatar.thumbnail_url
      }
      return null
    },
    hasSettings: state => {
      return state.Settings !== null && state.Settings !== false && state.Settings !== undefined
    },
    settings: state => {
      if (store.getters.hasSettings) {
        return state.Settings
      } else {
        store.dispatch('getSettings')
      }
      return null
    },
    getSetting (state) {
      return code => {
        if (store.getters.hasSettings) {
          if (!state.settingsMap && state.Settings !== null) {
            state.settingsMap = new Map()
            state.Settings.forEach(s => {
              state.settingsMap.set(s.code, s)
            })
          }
          if (state.settingsMap.has(code)) {
            return state.settingsMap.get(code)
          }
        } else {
          store.dispatch('getSettings')
        }
        return null
      }
    },
    memberships (state) {
      if (store.getters.hasMemberships === true) {
        return state.Memberships
      }
      return null
    },
    getMembershipData (state, getters) {
      return value => {
        if (Number.isInteger(value)) {
          return getters.getMembershipByID(value)
        }
        return getters.getMembershipByCode(value)
      }
    },
    getMembershipByID (state) {
      return ID => {
        if (store.getters.hasMemberships) {
          if (state.membershipsIDMap === null) {
            state.membershipsIDMap = new Map()
            state.Memberships.forEach(m => { state.membershipsIDMap.set('m_' + m.membership_id, m) })
          }
          const key = 'm_' + ID
          if (state.membershipsIDMap.has(key)) {
            return state.membershipsIDMap.get(key)
          }
        }
        return null
      }
    },
    getMembershipByCode (state) {
      return code => {
        if (store.getters.hasMemberships) {
          if (state.membershipsMap === null) {
            state.membershipsMap = new Map()
            state.Memberships.forEach(m => { state.membershipsMap.set(m.code, m) })
          }
          if (state.membershipsMap.has(code)) {
            return state.membershipsMap.get(code)
          }
        }
        return null
      }
    },
    getMembershipColor (state) {
      return mshpID => {
        const mshp = Number.isInteger(mshpID) ? store.getters.getMembershipByID(mshpID) : store.getters.getMembershipByCode(mshpID)
        if (mshp !== null && mshp.color) {
          let color = mshp.color
          if (color.indexOf('#') < 0) {
            color = '#' + color
          }
          return color
        }
        return null
      }
    },
    getMembershipName (state) {
      return mshpID => {
        const mshp = store.getters.getMembershipByID(mshpID)
        if (mshp !== null) {
          return mshp.name
        }
        return null
      }
    },
    getMembershipCode (state) {
      return mshpID => {
        const mshp = store.getters.getMembershipByID(mshpID)
        if (mshp && mshp.code) {
          return mshp.code
        }
        return null
      }
    },
    getCurrentMembership (state) {
      if (store.getters.hasMemberships === true && store.getters.hasAccountData) {
        return store.getters.getMembershipByID(state.AccountData.membership_id)
      }
      return null
    },
    numUnreadNotifications (state) {
      return state.numNotificationsUnread
    },
    numUnreadMessages (state) {
      return state.numMessagesUnread
    },
    numPendingRegistrations (state) {
      return state.numPendingRegistrations
    },
    stripeClientID (state) {
      return state.StripeClientID
    },
    stripeAPIKey (state) {
      return state.StripeAPIKey
    },
    effectiveRole (state) {
      if (state.effectiveRole === null) {
        const roles = store.getters.accountRoles
        if (roles && roles.length > 0) {
          if (roles.indexOf('admin') >= 0) {
            state.effectiveRole = 'admin'
          } else if (roles.indexOf('manager') >= 0) {
            state.effectiveRole = 'manager'
          } else if (roles.indexOf('teacher') >= 0) {
            state.effectiveRole = 'teacher'
          } else if (roles.indexOf('relative') >= 0) {
            state.effectiveRole = 'relative'
          } else if (roles.indexOf('student') >= 0) {
            state.effectiveRole = 'student'
          }
          if (state.effectiveRole === 'relative' && roles.includes('student') && state.Relatives && state.Relatives.length === 0) {
            store.dispatch('effectiveRole', 'student')
            state.effectiveRole = 'student'
          }
        }
      }
      return state.effectiveRole
    },
    relatives (state) {
      if (store.getters.hasRelatives === true) {
        return state.Relatives
      }
      return []
    },
    hasRelatives (state) {
      return state.Relatives !== false && state.Relatives !== null
    },
    discounts (state) {
      if (store.getters.hasDiscounts === true) {
        return state.Discounts
      }
      return []
    },
    hasDiscounts (state) {
      return state.Discounts !== null && state.Discounts !== false
    },
    isLoadingDiscounts (state) {
      return state.loadingDiscounts
    },
    isOutdatedClient (state) {
      return state.outdatedClient
    },
    isExpiredClient (state) {
      return state.expiredClient
    },
    isDifferentClient (state) {
      return state.differentClient
    },
    hasTimeKeeperSettings (state) {
      return state.TimeKeeperSettings && state.TimeKeeperSettings !== false
    },
    timetimeKeeperSettings (state) {
      if (store.getters.hasTimeKeeperSettings) {
        return state.TimeKeeperSettings
      }
      return null
    },
    isTimeKeeperEnabled (state) {
      if (store.getters.hasTimeKeeperSettings) {
        return state.TimeKeeperSettings.enabled === true
      }
      return false
    },
    isImpersonating () {
      return localStorage.getItem('imp_account_id') !== null
    }
  }
})

export default store
