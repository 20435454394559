import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faWordpress,
  faTwitter,
  faCcStripe,
  faGoogle,
  faWindows,
  faApple,
  faWhatsapp,
  faCcVisa,
  faCcMastercard,
  faCcAmazonPay,
  faCcApplePay,
  faCcAmex,
  faCcPaypal,
  faCcJcb,
  faCcDiscover,
  faStripe,
  faStripeS,
  faPaypal,
  faAndroid
} from '@fortawesome/free-brands-svg-icons'
import {
// CURRENCIES
  faEuroSign,
  faDollarSign,
  faPoundSign,
  faYenSign,
  faRubleSign,
  faWonSign,
  faLiraSign,
  faRupeeSign,
  faGuaraniSign,
  // HEADER BAR
  faInfoCircle,
  faGlassMartini,
  faMusic,
  faShoePrints,
  faMoneyBill,
  faCube,
  faPaintBrush,
  faBolt,
  faBalanceScale,
  // ADMIN CONFIG
  faKeyboard,
  faBars,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faShoppingCart,
  faCubes,
  faTrophy,
  faBuilding,
  faRing,
  faFlag,
  faShareAlt,
  faPencilRuler,
  faUndoAlt,
  faRedoAlt,
  faTimes,
  faPercent,
  faArrowUp,
  faArrowDown,
  faPlusCircle,
  faEdit,
  faSave,
  faCloudUploadAlt,
  faExclamationCircle,
  faUndo,
  faCheckCircle,
  faTimesCircle,
  faTag,
  faCertificate,
  faPalette,
  faSwatchbook,
  faFont,
  faImages,
  faBrush,
  faSyncAlt,
  faDownload,
  faMapSigns,
  faMagic,
  faEraser,
  faMapMarkerAlt,
  faSign,
  faIdCardAlt,
  faBusinessTime,
  faAngleLeft,
  faAngleRight,
  faArrowCircleLeft,
  faArrowCircleRight,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobeAsia,
  faGlobeAfrica,
  faBan,
  faKey,
  faEnvelopeOpenText,
  faCashRegister,
  faPrint,
  faGripVertical,
  faExpandAlt,
  faVideoSlash,
  faMicrophoneSlash,
  faMicrophone,
  faArrowsAltH,
  faExchangeAlt,
  faFlagCheckered,
  faSignature,
  faFileSignature,
  faFileContract,
  faDatabase,
  faProjectDiagram,
  faBacon,
  faTools,
  faBroadcastTower,
  faBell,
  faTabletScreenButton,
  // LOGIN
  faExclamationTriangle, faCheck, faLock, faEnvelope, faEllipsisH,
  // CLASS DETAILS
  faAddressCard,
  faSlidersH,
  faComments,
  faFileAlt,
  faCalendar,
  faLink,
  faVideo,
  faArrowsAlt,
  faWeightHanging,
  faUsers,
  faChartLine,
  faChartBar,
  faHashtag,
  faCopy,
  faDotCircle,
  faMoneyCheck,
  faMoneyCheckAlt,
  faUserLock,

  // CLASSES LIST
  faPlus,
  faMinus,
  faMale,
  faFemale,
  faChild,
  faStar,
  faCalendarCheck,
  faTrash,
  faThLarge,
  faTable,
  faArrowLeft,
  faArrowRight,
  faSearch,
  faCalendarAlt,
  faCalendarDay,
  faCalendarWeek,
  faCalendarTimes,
  // CLASS COUPLE
  faMinusCircle,
  // MONTH SELECTOR
  faAngleUp,
  faAngleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  // MEMBER LIST
  faUserPlus,
  faUserClock,
  faClock,
  faIdCard,
  faUser,
  faUserTimes,
  faExclamation,
  faChevronUp,
  faChevronDown,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faPenNib,
  // USER MENU
  faCogs,
  faCog,
  faUserCircle,
  faUserTie,
  faUserAstronaut,
  faUserFriends,
  faUserGraduate,
  faUserCog,
  faGraduationCap,
  faSignOutAlt,
  faHouseUser,
  // USER DETAILS
  faAddressBook,
  faPhone,
  faUpload,
  faCreditCard,
  faUniversity,
  faStickyNote,
  faPencilAlt,
  faMinusSquare,
  faEye,
  faEyeSlash,
  faPaperclip,
  // PAYMENTS
  faSearchDollar,
  faHourglassHalf,
  faFilePdf,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFileCsv,
  faFileImage,
  faFileAudio,
  faFileCode,
  faFileZipper,
  faPercentage,
  faCoins,
  faPiggyBank,
  faSquare,
  faClone,
  faForward,
  faSadCry,
  faFileDownload,
  faPlusSquare,
  faCalendarPlus,
  faHandHoldingUsd,
  faExternalLinkAlt,
  faWallet,
  faFileInvoice,
  faBox,
  faBoxOpen,
  // EVENTS
  faMapMarker,
  faList,
  faTags,
  // MESSAGES
  faEnvelopeOpen,
  faHistory,
  faPaperPlane,
  faInfo,
  faUnlock,
  faReply,
  faMailBulk,
  faInbox,
  faUserTag,
  faAt,
  faPenAlt,
  faStopwatch,
  faMouse,
  faCode,
  // IMPORT
  faCloudDownloadAlt,
  faListAlt,
  faCheckSquare,
  faWindowClose,
  // STUDENT VIEWS
  faColumns,
  faThList,
  faBirthdayCake,
  faListUl,
  faTh,
  faQuestionCircle,
  faQuestion,
  faFolderOpen,
  faBook,
  faLocationArrow,
  // TASKS
  faTasks,
  faQrcode,
  faChevronLeft,
  faChevronRight,
  faPen,
  faUserCheck,
  faAlignLeft,
  faHandPointRight,
  // TIMEKEEPER
  faPlay,
  faStop,
  faPause,
  faPenFancy,
  // REPORTS
  faUserInjured,
  faStreetView,
  faMobileAlt,
  faDesktop,
  faMedal,

  // REGISTRATION
  faSignInAlt,
  faGlobe,

  // STREAMING
  faPlayCircle,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faDoorOpen,
  faHourglassEnd,
  faPowerOff,
  faStepBackward,
  faStepForward,
  faChalkboardTeacher,
  faCircleLeft,
  faCircleRight,

  // EXPENSES
  faBeer,
  faBookOpen,
  faBroom,
  faBullhorn,
  faBurn,
  faCar,
  faCarCrash,
  faCarSide,
  faChair,
  faCity,
  faCommentDollar,
  faCommentsDollar,
  faCompactDisc,
  faConciergeBell,
  faCouch,
  faCut,
  faDumbbell,
  faFaucet,
  faFileInvoiceDollar,
  faGasPump,
  faGift,
  faGlassMartiniAlt,
  faHamburger,
  faHammer,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHands,
  faHospital,
  faHospitalUser,
  faHotel,
  faIcons,
  faIdBadge,
  faLaptop,
  faLaptopHouse,
  faLifeRing,
  faLightbulb,
  faNetworkWired,
  faPaintRoller,
  faPassport,
  faPeopleArrows,
  faPizzaSlice,
  faPlug,
  faReceipt,
  faRecycle,
  faSchool,
  faShieldAlt,
  faStore,
  faStoreAlt,
  faTheaterMasks,
  faTicket,
  faTicketAlt,
  faTint,
  faToiletPaper,
  faUserShield,
  faUtensils,
  faWifi,
  faWind,
  faWrench,
  faMarker,
  faAlignJustify,
  // EVALUATIONS
  faUpRightFromSquare,
  faMessage
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendar as faCalendarO,
  faClock as faClockO,
  faBuilding as faBuildingO,
  faAddressCard as faAddressCardO,
  faCalendarCheck as faCalendarCheckO,
  faTrashAlt as faTrashAltO,
  faPaperPlane as faPaperPlaneO,
  faUserCircle as faUserCircleO,
  faCreditCard as faCreditCardO,
  faIdCard as faIdCardO,
  faCalendarPlus as faCalendarPlusO,
  faImage as faImageO,
  faEnvelopeOpen as faEnvelopeOpenO,
  faCommentAlt as faCommentAltO,
  // TIMEKEEPER
  faPauseCircle as faPauseCircleO,
  faPlayCircle as faPlayCircleO,
  // STREAMING
  faStar as FaStarO,
  faCircleXmark
} from '@fortawesome/free-regular-svg-icons'

library.add(
// CURRENCIES
  faEuroSign,
  faDollarSign,
  faPoundSign,
  faYenSign,
  faRubleSign,
  faWonSign,
  faLiraSign,
  faRupeeSign,
  faGuaraniSign,
  // HEADER BAR
  faInfoCircle,
  faMoneyBill,
  faMusic,
  faShoePrints,
  faGlassMartini,
  faCube,
  faPaintBrush,
  faBolt,
  faBalanceScale,
// ADMIN CONFIG
  faKeyboard,
  faBars,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faShoppingCart,
  faCubes,
  faTrophy,
  faBuilding,
  faRing,
  faFlag,
  faShareAlt,
  faBuildingO,
  faAddressCardO,
  faPencilRuler,
  faUndoAlt,
  faRedoAlt,
  faCalendarCheckO,
  faTimes,
  faPercent,
  faArrowUp,
  faArrowDown,
  faPlusCircle,
  faEdit,
  faSave,
  faCcStripe,
  faStripe,
  faStripeS,
  faPaypal,
  faAndroid,
  faTrashAltO,
  faCloudUploadAlt,
  faPaperPlaneO,
  faExclamationCircle,
  faUndo,
  faCheckCircle,
  faTimesCircle,
  faTag,
  faCertificate,
  faIdCardO,
  faPalette,
  faFont,
  faSwatchbook,
  faImages,
  faBrush,
  faSyncAlt,
  faDownload,
  faMapSigns,
  faMagic,
  faEraser,
  faMapMarkerAlt,
  faSign,
  faIdCardAlt,
  faBusinessTime,
  faAngleLeft,
  faAngleRight,
  faArrowCircleLeft,
  faArrowCircleRight,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobeAsia,
  faGlobeAfrica,
  faBan,
  faKey,
  faEnvelopeOpenText,
  faCashRegister,
  faPrint,
  faGripVertical,
  faExpandAlt,
  faVideoSlash,
  faMicrophoneSlash,
  faMicrophone,
  faArrowsAltH,
  faExchangeAlt,
  faFlagCheckered,
  faSignature,
  faFileSignature,
  faFileContract,
  faDatabase,
  faProjectDiagram,
  faBacon,
  faTools,
  faBroadcastTower,
  faBell,
  faTabletScreenButton,
  // LOGIN
  faExclamationTriangle,
  faCheck,
  faLock,
  faEnvelope,
  faEllipsisH,

  // SOCIAL
  faFacebookF,
  faInstagram,
  faYoutube,
  faWordpress,
  faTwitter,
  faGoogle,
  faWindows,
  faApple,
  faWhatsapp,

  // CLASS DETAILS
  faAddressCard,
  faSlidersH,
  faComments,
  faFileAlt,
  faCalendar,
  faLink,
  faVideo,
  faArrowsAlt,
  faWeightHanging,
  faUsers,
  faChartLine,
  faChartBar,
  faHashtag,
  faCopy,
  faDotCircle,
  faMoneyCheck,
  faMoneyCheckAlt,
  faCalendarAlt,
  faCalendarDay,
  faCalendarWeek,
  faCalendarTimes,
  faUserLock,

// CLASSES LIST
  faPlus,
  faMinus,
  faMale,
  faFemale,
  faChild,
  faStar,
  faCalendarCheck,
  faTrash,
  faCalendar,
  faCalendarO,
  faThLarge,
  faTable,
  faArrowLeft,
  faArrowRight,
  faSearch,

// CLASS COUPLE
  faMinusCircle,
  faClockO,

// MONTH SELECTOR
  faAngleUp,
  faAngleDown,
  faChevronCircleLeft,
  faChevronCircleRight,

// MEMBER LIST
  faUserPlus,
  faUserClock,
  faClock,
  faIdCard,
  faUser,
  faUserTimes,
  faExclamation,
  faChevronUp,
  faChevronDown,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faPenNib,

// USER MENU
  faCogs,
  faCog,
  faUserGraduate,
  faUserTie,
  faUserCog,
  faUserAstronaut,
  faUserFriends,
  faGraduationCap,
  faUserCircle,
  faSignOutAlt,
  faHouseUser,

// USER DETAILS
  faAddressBook,
  faPhone,
  faUpload,
  faUserCircleO,
  faCreditCard,
  faCreditCardO,
  faUniversity,
  faStickyNote,
  faPencilAlt,
  faMinusSquare,
  faEye,
  faEyeSlash,
  faPaperclip,

// PAYMENTS
  faSearchDollar,
  faHourglassHalf,
  faFilePdf,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFileCsv,
  faFileImage,
  faFileAudio,
  faFileCode,
  faFileZipper,
  faPercentage,
  faCoins,
  faPiggyBank,
  faSquare,
  faClone,
  faForward,
  faSadCry,
  faFileDownload,
  faPlusSquare,
  faCalendarPlus,
  faHandHoldingUsd,
  faExternalLinkAlt,
  faWallet,
  faFileInvoice,
  faBox,
  faBoxOpen,

// EVENTS
  faMapMarker,
  faList,
  faTags,
  faCalendarPlusO,
  faImageO,

// MESSAGES
  faEnvelopeOpen,
  faHistory,
  faPaperPlane,
  faInfo,
  faUnlock,
  faReply,
  faMailBulk,
  faInbox,
  faUserTag,
  faEnvelopeOpenO,
  faCommentAltO,
  faAt,
  faPenAlt,
  faStopwatch,
  faMouse,
  faCode,

// IMPORT
  faCloudDownloadAlt,
  faListAlt,
  faCheckSquare,
  faWindowClose,

// STUDENT VIEWS
  faColumns,
  faThList,
  faBirthdayCake,
  faListUl,
  faTh,
  faQuestionCircle,
  faQuestion,
  faFolderOpen,
  faBook,
  faLocationArrow,

// TASKS
  faTasks,
  faQrcode,
  faChevronLeft,
  faChevronRight,
  faPen,
  faUserCheck,
  faAlignLeft,
  faHandPointRight,

// TIMEKEEPER
  faPlay,
  faStop,
  faPause,
  faPauseCircleO,
  faPlayCircleO,
  faPenFancy,

// REPORTS
  faUserInjured,
  faStreetView,
  faMobileAlt,
  faDesktop,
  faMedal,

// CARDS
  faCcVisa,
  faCcMastercard,
  faCcAmazonPay,
  faCcApplePay,
  faCcAmex,
  faCcPaypal,
  faCcJcb,
  faCcDiscover,

// REGISTRATION
  faSignInAlt,
  faGlobe,

// STREAMING
  faPlayCircle,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faDoorOpen,
  faHourglassEnd,
  faPowerOff,
  FaStarO,
  faStepBackward,
  faStepForward,
  faChalkboardTeacher,
  faCircleLeft,
  faCircleRight,

// EXPENSES
  faBeer,
  faBookOpen,
  faBroom,
  faBullhorn,
  faBurn,
  faCar,
  faCarCrash,
  faCarSide,
  faChair,
  faCity,
  faCommentDollar,
  faCommentsDollar,
  faCompactDisc,
  faConciergeBell,
  faCouch,
  faCut,
  faDumbbell,
  faFaucet,
  faFileInvoiceDollar,
  faGasPump,
  faGift,
  faGlassMartiniAlt,
  faHamburger,
  faHammer,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHands,
  faHospital,
  faHospitalUser,
  faHotel,
  faIcons,
  faIdBadge,
  faLaptop,
  faLaptopHouse,
  faLifeRing,
  faLightbulb,
  faNetworkWired,
  faPaintRoller,
  faPassport,
  faPeopleArrows,
  faPizzaSlice,
  faPlug,
  faReceipt,
  faRecycle,
  faSchool,
  faShieldAlt,
  faStore,
  faStoreAlt,
  faTheaterMasks,
  faTicket,
  faTicketAlt,
  faTint,
  faToiletPaper,
  faUserShield,
  faUtensils,
  faWifi,
  faWind,
  faWrench,
  faMarker,
  faAlignJustify,
  faCircleXmark,
  // EVALUATIONS
  faUpRightFromSquare,
  faMessage
)

// CUSTOM ICONS
export const faBizum = {
  prefix: 'fac',
  iconName: 'bizum',
  icon: [512, 512, [], null, 'm 157.61568,184.68752 c 18.23986,13.26806 43.66487,9.39861 56.92959,-8.84444 L 262.08,110.05932 c 13.26528,-18.242506 9.39584,-43.671399 -8.84306,-56.938762 -18.24028,-13.267224 -43.66528,-9.39764 -56.93084,8.844862 l -48.08668,65.78362 c -12.7125,18.24264 -8.84347,43.67181 9.39626,56.93848 z M 375.38696,91.816814 C 357.14668,78.549589 331.72168,82.419173 318.4564,100.66168 l -191.24101,263.1342 c -13.26528,18.24306 -9.39625,43.67223 8.84348,56.9389 18.23972,13.26805 43.66487,9.39861 56.93015,-8.84445 l 191.241,-263.13476 c 13.81806,-18.24236 9.39583,-43.67139 -8.84306,-56.938756 z M 139.37581,70.810699 C 152.64109,52.568197 148.77192,27.139165 130.5322,13.871927 112.29248,0.60463368 86.867332,4.4742591 73.602095,22.716804 c -13.26528,18.242502 -9.396238,43.671534 8.84357,56.938758 18.239725,13.267363 43.664865,9.39764 56.930145,-8.844863 z M 429.55364,432.3445 c -18.2389,-13.26806 -43.6639,-9.39722 -56.92918,8.84445 -13.26528,18.24305 -9.39722,43.67223 8.84306,56.93889 18.24028,13.26806 43.66528,9.39723 56.93056,-8.84444 13.26528,-18.24306 9.39584,-43.67223 -8.84444,-56.9389 z M 354.93696,327.86393 c -18.23889,-13.26667 -43.6639,-9.39722 -56.92918,8.84584 l -48.0875,65.78334 c -13.26528,18.24167 -9.39584,43.67084 8.84305,56.93889 18.24028,13.26667 43.66529,9.39723 56.93057,-8.84583 l 48.0875,-65.78334 c 13.26528,-18.24167 9.39584,-43.67084 -8.84444,-56.9389 z']
}

library.add(faBizum)

Vue.component('font-awesome-icon', FontAwesomeIcon)
export default library
