<template functional>
  <transition name="fade">
    <div
      v-if="props.show"
      class="loading-overlay"
      :style="{'z-index': props.zIndex ? props.zIndex : 3}"
    >
      <a class="button is-large is-loading" />
      <span
        v-if="props.progress"
        class="has-text-grey-light is-size-3 is-progress has-text-centered"
      >
        {{ props.progress }}%
      </span>
      <span
        v-else-if="props.message"
        class="has-text-grey-light is-size-5 is-message is-uppercase has-text-centered"
        v-html="props.message"
      />
    </div>
  </transition>
</template>
<script>
export default {
  name: 'LoadingIndicator',
  props: {
    show: { type: Boolean, default: false },
    progress: { type: String, default: null },
    message: { type: String, default: null },
    zIndex: { type: Number, default: null }
  }
}
</script>
