var render = function render(_c,_vm){return _c('div',{staticClass:"message",class:[
    _vm.props.bulmaClass ? 'is-' + _vm.props.bulmaClass : 'has-text-grey',
    _vm.props.bulmaTextClass ? 'has-text-' + _vm.props.bulmaTextClass : null,
    {[`is-${_vm.props.size}`]: _vm.props.size},
  ],style:({'max-width': _vm.props.maxWidth ? _vm.props.maxWidth : null})},[_c('div',{staticClass:"message-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('span',{staticClass:"icon",class:[_vm.props.bulmaClass ? 'has-text-' + _vm.props.bulmaClass : 'has-text-grey', {'is-large': !_vm.props.size || _vm.props.size !== 'small'}]},[_c('font-awesome-icon',{attrs:{"icon":_vm.props.icon,"size":_vm.props.size === 'small' ? '2x' : '3x'}})],1)]),_c('div',{staticClass:"media-content"},[_c('p',{class:[{
            'is-size-65': _vm.props.size==='small', 'is-uppercase': _vm.props.isUppercase,
            'has-text-centered': _vm.props.textAlign === 'center','has-text-right': _vm.props.textAlign === 'right', 'has-text-left': _vm.props.textAlign === 'left'
          }, _vm.props.bulmaTextClass ? 'has-text-' + _vm.props.bulmaTextClass : null],domProps:{"innerHTML":_vm._s(_vm.parent.$t(_vm.props.translationCode, _vm.props.translationParams))}}),(_vm.props.extraContent)?_c('p',{class:{
            'is-size-65': _vm.props.size==='small', 'is-uppercase': _vm.props.isUppercase,
            'has-text-centered': _vm.props.textAlign === 'center','has-text-right': _vm.props.textAlign === 'right', 'has-text-left': _vm.props.textAlign === 'left'
          },domProps:{"innerHTML":_vm._s(_vm.props.extraContent)}}):_vm._e(),_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }