<template>
  <div class="tasks-wrapper qr-wrapper">
    <div
      class="tasks-background"
      :class="{'show': active}"
      @click="active = false"
    />
    <div
      class="kydemy kydemy-tasks kydemy-qr"
      :class="{'active': active, 'tasks-button': !active, 'tasks-list': active}"
    >
      <transition-group
        name="groupfade"
        mode="out-in"
      >
        <button
          v-if="!active"
          :key="'tasks-button'"
          class="box-button"
          @click="active = true"
        >
          <span class="icon is-large has-text-primary">
            <font-awesome-icon
              icon="qrcode"
              size="2x"
            />
          </span>
        </button>
        <div
          v-else
          :key="'tasks-list'"
          class="task-list-container"
        >
          <a class="close" @click="active=false">
            <BulmaIcon
              :icon="['far', 'circle-xmark']"
              size="3x"
              bulma-class="dark"
            />
          </a>
          <template v-if="qrBase64">
            <figure class="image is-square">
              <img alt="Access QR code" :src="qrBase64" />
            </figure>
            <div class="columns form-columns is-multiline is-mobile is-fullwidth is-center m-t-0 m-b-2 m-h-3 is-size-3">
              <div class="column is-half">
                ID
              </div>
              <div class="column is-half has-text-left has-text-weight-bold">
                {{ accountID }}
              </div>
              <div class="column is-half">
                PIN
              </div>
              <div class="column is-half has-text-left has-text-weight-bold">
                {{ pinCode }}
              </div>
            </div>
          </template>
          <LoadingIndicator
            v-else
            :show="loading"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>

import BulmaIcon from '@/components/bulma/BulmaIcon'
import Crisp from '@/mixins/crisp'
import LoadingIndicator from '@/components/controls/LoadingIndicator.vue'

export default {
  name: 'KydemyAccessQR',
  mixins: [Crisp],
  components: {
    LoadingIndicator,
    BulmaIcon
  },
  data () {
    return {
      active: false,
      loading: true,
      qrBase64: null,
      error: false,
      pinCode: null
    }
  },
  watch: {
    active (newVal) {
      if (newVal) {
        this.hideChatButton()
        this.getQrImage()
      } else {
        this.showChatButton()
      }
    }
  },
  mounted () {
    document.getElementById('app').classList.add('has-qr')
  },
  methods: {
    async getQrImage () {
      this.loading = true
      this.pinCode = null
      let apiResponse = await this.apiPost(`accounts/${this.accountID}/attendance/qr/`, null)
      if (!apiResponse.hasContent() || !apiResponse.data.pin || apiResponse.data.pin.toString().length !== 4) {
        this.error = true
        this.loading = false
        return
      }
      this.pinCode = apiResponse.data.pin
      apiResponse = await this.getAxiosFileBinary(`accounts/${this.accountID}/attendance/qr/${apiResponse.data.pin}/image/`)
      if (apiResponse.status !== 200) {
        this.error = true
        this.loading = false
        return
      }
      this.qrBase64 = await this.blobToBase64(apiResponse.data)
      this.loading = false
    },
    blobToBase64 (data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = err => reject(err)
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(data)
      })
    }
  }
}
</script>
