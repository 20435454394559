<template>
  <div
    class="dropdown dropdown-list is-fullwidth is-right"
    :class="{'is-active': dropdownRolesActive, 'is-fullwidth': isFullWidth}"
  >
    <div class="dropdown-trigger">
      <button
        class="button is-rounded"
        aria-haspopup="true"
        :disabled="isEffectiveTeacher() || disabledControl"
        aria-controls="dropdown-menu"
        :class="{[`is-${size}`]: size}"
        @click="dropdownRolesActive=!dropdownRolesActive"
      >
        <nav class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <span class="icon has-text-grey">
                <font-awesome-icon :icon="memberRolesIcon" />
              </span>
            </div>
          </div>
          <div class="level-item">
            <span>{{ getMemberRoles() }}</span>
          </div>
          <div class="level-right">
            <div class="level-item">
              <span class="icon is-small has-text-grey">
                <font-awesome-icon icon="angle-down" />
              </span>
            </div>
          </div>
        </nav>
      </button>
    </div>
    <div
      id="dropdown-menu"
      class="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          v-for="(name, role) in enabledRoles"
          :key="'role_'+role"
          class="dropdown-item has-text-left"
          :class="{'is-active': selectedRoles.indexOf(role)>=0, 'is-disabled': role === 'admin' && !isAdmin()}"
          @click="updateRoles(role)"
        >
          <span class="icon m-r-07">
            <font-awesome-icon :icon="getRoleIcon(role)" />
          </span>
          <span>{{ name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RoleSelector',
  props: {
    multiple: { type: Boolean, default: false },
    title: { type: String, default: '' },
    value: { type: Array, default: null },
    size: { type: String, default: 'normal' },
    disabledRoles: { type: Array, default: null },
    disabledControl: { type: Boolean, default: false },
    isFullWidth: { type: Boolean, default: false }
  },
  data () {
    return {
      selectedRoles: [],
      dropdownRolesActive: false
    }
  },
  computed: {
    memberRolesIcon () {
      switch (this.selectedRoles.length) {
        case 0:
          return 'user-tag'
        case 1:
          return this.getRoleIcon(this.selectedRoles[0])
        default:
          return 'user-tag'
      }
    },
    enabledRoles () {
      const roles = this.$t('member.roles')
      const effectiveRolesDisabled = []
      if (this.disabledRoles !== null && this.disabledRoles.length > 0) {
        effectiveRolesDisabled.push(...this.disabledRoles)
      }
      if (!this.isSettingEnabled('RELATIVES_ENABLED')) {
        effectiveRolesDisabled.push('relative')
      }
      if (!this.isSettingEnabled('SUBSCRIPTIONS_ENABLED')) {
        effectiveRolesDisabled.push('subscriber')
      }
      if (effectiveRolesDisabled.length > 0) {
        effectiveRolesDisabled.forEach(dr => {
          delete roles[dr]
        })
      }
      return roles
    }
  },
  watch: {
    value (newVal) {
      this.selectedRoles = newVal
    },
    dropdownRolesActive: function (newVal) {
      if (this.multiple !== true) {
        if (newVal === true) {
          window.addEventListener('click', this.windowClick)
        } else {
          window.removeEventListener('click', this.windowClick)
        }
      }
      if (this.$eventHub && newVal === true) {
        this.$eventHub.$emit('expand-dropdown', this._uid)
      }
    }
  },
  created () {
    this.selectedRoles = this.value
    const self = this
    this.$eventHub.$on('expand-dropdown', function (compUID) {
      if (self.dropdownRolesActive && self._uid !== compUID) {
        self.dropdownRolesActive = false
      }
    })
  },
  methods: {
    updateRoles (role) {
      if (!this.selectedRoles) {
        return
      }
      if (role !== 'admin' || this.isAdmin()) {
        if (this.multiple === false) {
          this.selectedRoles = []
        }
        const oldValue = JSON.stringify(this.selectedRoles)
        const index = this.selectedRoles.indexOf(role)
        if (index >= 0) {
          this.selectedRoles.splice(index, 1)
        } else {
          this.selectedRoles.push(role)
        }
        const newValue = JSON.stringify(this.selectedRoles)
        if (oldValue !== newValue) {
          this.$emit('input', this.selectedRoles)
        }
        if (role === 'lead' && index < 0 && this.selectedRoles.includes('student')) {
          this.selectedRoles.splice(this.selectedRoles.indexOf('student'), 1)
        }
        if (role === 'student' && index < 0 && this.selectedRoles.includes('lead')) {
          this.selectedRoles.splice(this.selectedRoles.indexOf('lead'), 1)
        }
        if (this.multiple === false) {
          this.dropdownRolesActive = false
        }
      }
    },
    getMemberRoles: function () {
      const roles = []
      let role = null
      for (const index in this.selectedRoles) {
        role = this.selectedRoles[index]
        roles.push(this.$t('member.roles.' + role))
      }
      if (roles.length > 0) {
        return roles.join(', ')
      } else if (this.multiple) {
        return this.$t('member.no_roles')
      } else {
        return this.$t('member.no_role')
      }
    },
    windowClick: function (event) {
      if (event.target !== event.currentTarget) {
        this.isActive = false
      }
    }
  }
}
</script>
