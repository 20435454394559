// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueLocalStorage from 'vue-localstorage'
import Portal from 'vue2-portal'
import VueSimpleBreakpoints from 'vue-simple-breakpoints'
import FloatingVue from 'floating-vue'
import moment from 'moment'
import VueScrollTo from 'vue-scrollto'
import * as GmapVue from 'gmap-vue'
import mixins from './mixins'
import Vuelidate from 'vuelidate'
import FlagIcon from 'vue-flag-icon'
import config from './config'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import VueSignaturePad from 'vue-signature-pad'
import errorsMixin from './mixins/errors-mixin'
import rest from './rest'
import KSocket from './websocket'
import Notifications from 'vue-notification'

Vue.config.productionTip = false
Vue.use(VueLocalStorage)
Vue.use(Portal)
Vue.use(VueSimpleBreakpoints, {
  mobile: 768,
  tablet: 1023,
  small_desktop: 1215,
  large_desktop: 1407
})
Vue.use(FloatingVue)
Vue.use(VueScrollTo)
Vue.use(FlagIcon)
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCpIPvg-p8jT-61ZrbvE1pmVlC4zq35pcY',
    libraries: 'places'
  }
})
Vue.use(VueSignaturePad)
Vue.use(Notifications)

Vue.mixin(mixins)

Vue.filter('format_phone', function (account) {
  if (account.phone_number != null) {
    const numberStr = account.phone_number.toString()
    const phonePrefix = account.phone_prefix ? '+' + account.phone_prefix + ' - ' : ''
    return phonePrefix + numberStr.substr(0, 3) + ' ' + numberStr.substr(3, 2) + ' ' + numberStr.substr(5, 2) + ' ' + numberStr.substr(7)
  }
  return ''
})

Vue.filter('tinydate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D MMM').toUpperCase()
})
Vue.filter('smalldate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('ddd D, MMM')
})
Vue.filter('shortdate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D MMM YYYY')
})
Vue.filter('shorterdate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D MMM YY').replace('.', '')
})
Vue.filter('fulldate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('dddd D, MMMM YYYY')
})
Vue.filter('middate', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D, MMMM YYYY')
})
Vue.filter('middate-alt', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('ddd D, MMM YYYY')
})
Vue.filter('datetimeShort', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D MMM, HH:mm')
})
Vue.filter('datetime-mid', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('D MMM YYYY, HH:mm')
})
Vue.filter('datetime-long', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('dddd D MMMM, HH:mm')
})
Vue.filter('time', function (dt) {
  return moment(dt).format('HH:mm')
})
Vue.filter('dateArray', function (dates) {
  return dates.map(d => moment(d).format('D MMM')).join(', ')
})

Vue.filter('monthLong', function (dt, langCode) {
  moment.locale(langCode)
  return moment(dt).format('MMMM YYYY')
})

Vue.filter('monthMid', function (dt, langCode) {
  moment.locale(langCode)
  const mnt = moment(dt)
  return mnt.format('MMM').substring(0, 3).toUpperCase() + ' ' + mnt.format('YYYY')
})

Vue.filter('monthShort', function (dt, langCode) {
  moment.locale(langCode)
  const mnt = moment(dt)
  return mnt.format('MMM').substring(0, 3).toUpperCase()
})

Vue.use(Vuelidate)
config.submitSentryErrors = (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development')

Sentry.init({
  dsn: 'https://c2f3bb77a9b145a9874272971154febd@sentry.io/1412285',
  environment: config.BuildMode,
  release: config.BuildVersion,
  enabled: config.submitSentryErrors,
  integrations: [new VueIntegration({
    Vue,
    attachProps: true
  })],
  ignoreErrors: [
    'Expected mapDiv of type HTMLElement but was passed undefined',
    'Illegal invocation',
    'ChunkLoadError',
    'Cannot read property \'_t\' of null',
    'AbortError',
    'ResizeObserver loop limit exceeded',
    'NotAllowedError',
    'Network Error',
    'Error: timeout of 0ms exceeded',
    'null is not an object (evaluating \'r._t\')',
    'Request failed with status code 401',
    'Request failed with status code 403',
    'Request failed with status code 404',
    'a[b].target.className.indexOf is not a function',
    'InvalidStateError: The object is in an invalid state',
    'r is null',
    'Redirected when going from',
    'Avoided redundant navigation to current location:',
    'Navigation cancelled from',
    'peer closed',
    'request timeout',
    'AwaitQueue closed',
    'Unexpected end of input',
    'Request aborted',
    'TypeError: Failed to fetch'
  ],
  beforeSend (event, hint) {
    if (config.submitSentryErrors) {
      return event
    } else {
      /* eslint no-console: "off" */
      console.log('%cKYDEMY ERROR', 'font-weight: bold; background: #AA0000; color: yellow; font-size: x-large; padding: 0.5rem 1rem;')
      if (event.exception && event.exception.values && event.exception.values.length > 0 && event.exception.values[0].value) {
        /* eslint no-console: "off" */
        console.log('%c' + event.exception.values[0].value, 'font-weight: bold; background: #FFCCCC; color: #775555; font-size: medium; padding: 0.2rem 5rem;')
      }
      if (event.request && event.request.url) {
        /* eslint no-console: "off" */
        console.log('%c' + event.request.url, 'background: #DDEEFF; color: #004578; font-size: 0.7rem; padding: 0.2rem 5rem;')
      }
      /* eslint no-console: "off" */
      console.log(event)
      console.log(hint.originalException || hint.syntheticException)
      return null
    }
  }
})
Vue.prototype.$eventHub = new Vue()
Vue.prototype.$sentry = Sentry
Vue.prototype.$rest = rest
Vue.prototype.$ws = {}

let buildMode = process.env.NODE_ENV === 'development' ? 'development' : 'production'
if (config.BuildVersion.includes('test')) {
  buildMode = 'test'
}

const app = new Vue({
  i18n,
  router,
  store,
  mixins: [errorsMixin],
  data () {
    return {
      buildMode: buildMode
    }
  },
  mounted () {
    this.connectWS()
    this.$eventHub.$on('reconnect-ws', this.reconnectWS)
  },
  methods: {
    connectWS () {
      console.log('... connecting new WS')
      this.$ws = new KSocket(this.$eventHub)
      if (this.accountID != null) {
        this.$ws.connect()
      }
    },
    reconnectWS () {
      console.log('... RE connecting WS')
      if (this.$ws !== null) {
        this.$ws.close()
      }
      this.connectWS()
    }
  },
  render: h => h(App)
}).$mount('#app')

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || config.BuildVersion.includes('test') || config.BuildVersion.includes('dev')) {
  window.Kydemy = app
  window.__KYDEMY__ = app
  window.KydemyRouter = router
  window.KydemyConfiguration = config
  window.moment = moment
} else {
  window.__KYDEMY__ = app
}
if (window.Cypress) {
  window.app = app
}

if (localStorage) {
  const cacheBuildVersion = localStorage.getItem('build_version')
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || config.BuildVersion.includes('test') || config.BuildVersion.includes('dev')) {
    /* eslint no-console: "off" */
    console.log('BUILD VERSION: ', cacheBuildVersion, '/ CONF VERSION: ', config.BuildVersion, ' / SAME VERSION: ', config.BuildVersion === cacheBuildVersion)
  }
  if (!cacheBuildVersion || config.BuildVersion !== cacheBuildVersion) {
    localStorage.setItem('build_version', config.BuildVersion)
    window.location.reload(true)
  }
}

window.addEventListener('keydown', event => {
  let keyCode = null
  if (event.key !== undefined) {
    keyCode = event.code
  } else if (event.keyIdentifier !== undefined) {
    keyCode = event.keyIdentifier
  } else if (event.keyCode !== undefined) {
    keyCode = event.keyCode
  }

  if (keyCode === 13 || keyCode === 'Enter') {
    app.$eventHub.$emit('keyEnter')
  } else if (keyCode === 27 || keyCode === 'Escape') {
    app.$eventHub.$emit('keyEscape')
  }
})
window.addEventListener('click', event => {
  app.$eventHub.$emit('click')
})

app.$eventHub.$on('logout', () => {
  app.$ws.close()
})

app.$eventHub.$on('login', () => {
  app.$ws.connect()
})

app.$eventHub.$on('ws-status', (payload) => {
  app.$store.dispatch('updateStatus', payload)
})

app.$eventHub.$on('ws-streaming-classes', (payload) => {
  app.$store.dispatch('updateStreamingClasses', payload)
})

app.$eventHub.$on('ws-qr-access', (payload) => {
  console.log('QR ACCESS HANDLER!!')
  console.log('app Notify', app.$notify)
  const acc = payload.account
  const qrAcc = payload.qr_account
  const hasDebts = payload.has_debts
  const dur = hasDebts ? -1 : 8000
  const text = app.$i18n.t(hasDebts ? 'qr.access_has_debts' : 'qr.access_no_debts', {
    name: acc.firstname + ' ' + acc.lastname,
    qr_name: qrAcc.firstname,
    qr_location: qrAcc.lastname
  })
  const title = app.$i18n.t(hasDebts ? 'qr.access_has_debts_title' : 'qr.access_no_debts_title')
  const cls = hasDebts ? 'is-danger' : 'is-success'

  app.$notify({
    title: title,
    text: text,
    data: {
      classes: cls,
      avatar_url: acc.avatar.thumbnail_url,
      name: acc.firstname + ' ' + acc.lastname,
      showClose: dur < 0
    },
    duration: dur
  })
})

app.$eventHub.$on('get-streaming-classes', () => {
  app.$ws.sendMessage('streaming-classes', null)
})

export { app, router }
