import rest from '../../rest'

const STORAGE_KEY = 'TAGS'
const TAGS_LOADED = 'TAGS_LOADED'
const TAGS_CLEAR = 'TAGS_CLEAR'

const state = {
  tags: localStorage.getItem(STORAGE_KEY) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY)),
  tagsLoaded: localStorage.getItem(STORAGE_KEY) !== null,
  loadingTags: false
}

const mutations = {
  [TAGS_LOADED] (state) {
    const tagsDataStr = localStorage.getItem(STORAGE_KEY)
    if (tagsDataStr && tagsDataStr.length > 0) {
      state.tags = JSON.parse(tagsDataStr)
    } else {
      state.tags = null
    }
    state.tagsLoaded = true
    state.loadingTags = false
  },
  [TAGS_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY)
    state.tagsLoaded = false
    state.tags = null
  }
}

const actions = {
  async getTags ({ commit }) {
    if (state.loadingTags === false) {
      state.loadingTags = true
      commit(TAGS_CLEAR)
      const response = await rest.getAxiosRequest('tags/')
      if (response.status === 200) {
        const sortedTags = response.data.sort((a, b) => a.name.localeCompare(b.name))
        localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedTags))
        commit(TAGS_LOADED)
      } else {
        localStorage.removeItem(STORAGE_KEY)
        commit(TAGS_LOADED)
      }
    }
  },
  async clearTags ({ commit }) {
    commit(TAGS_CLEAR)
  },
  async createTag ({ dispatch }, newTag) {
    const response = await rest.postAxiosRequest('tags/', newTag)
    if (response.status === 201) {
      await dispatch('getTags')
      return true
    } else {
      return false
    }
  }
}
export default {
  state,
  mutations,
  actions
}
