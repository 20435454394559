<template>
  <div class="kydemy-time-picker-menu">
    <transition-group
      name="fade"
      :duration="{ enter: 200, leave: 100 }"
      mode="out-in"
    >
      <div
        v-if="timeDisplayMode === 'time' && selectedTime"
        :key="'time_picker'"
        class="kydemy-time-picker"
      >
        <div class="columns is-mobile is-paddingless is-marginless is-variable is-0">
          <div class="column column is-paddingless">
            <button
              class="button is-white"
              @click="timeDisplayMode = 'hours'"
              @mousewheel="hoursMouseWheel"
            >
              <span v-if="is24h">{{ selectedTime.format('HH') }}</span>
              <span v-else>{{ selectedTime.format('hh') }}</span>
            </button>
          </div>
          <div class="column is-narrow column is-paddingless">
            <span class="separator">:</span>
          </div>
          <div class="column is-paddingless">
            <button
              class="button is-white"
              @click="timeDisplayMode = 'minutes'"
              @mousewheel="minutesMouseWheel"
            >
              {{ selectedTime.format('mm') }}
            </button>
          </div>
          <div
            v-if="!is24h"
            class="column is-narrow column is-paddingless"
          >
            <div class="field has-addons">
              <p class="control">
                <button
                  class="button is-white"
                  :class="{'is-primary': selectedTime.format('A') === 'AM'}"
                  @click="setTimeAM"
                >
                  <span class="is-size-7">AM</span>
                </button>
              </p>
              <p class="control">
                <button
                  class="button is-white"
                  :class="{'is-primary': selectedTime.format('A') === 'PM'}"
                  @click="setTimePM"
                >
                  <span class="is-size-7">PM</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="timeDisplayMode === 'hours'"
        :key="'hour_picker'"
        class="kydemy-hour-picker"
        :class="{'is-24-hour': is24h, 'is-12-hour': !is24h}"
      >
        <div class="columns is-marginless is-paddingless is-variable is-1 is-multiline is-mobile">
          <div
            v-for="hour in displayHours"
            :key="'cal_hour_'+hour"
            class="column"
          >
            <button
              class="button is-white"
              :class="{'is-primary': selectedTime.format((is24h ? 'HH' : 'hh')) === hour }"
              @click="selectHour(hour)"
            >
              {{ hour }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="timeDisplayMode === 'minutes'"
        :key="'minute_picker'"
        class="kydemy-minute-picker"
      >
        <div class="columns is-marginless is-paddingless is-variable is-1 is-multiline is-mobile">
          <div
            v-for="minute in displayMinutes"
            :key="'cal_minute_'+minute"
            class="column is-minute"
          >
            <button
              class="button is-white"
              :class="{'is-primary': selectedTime.format('mm') === minute }"
              @click="selectMinute(minute)"
            >
              {{ minute }}
            </button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import moment from 'moment'
import KydemyTimePickerMixin from './KydemyTimePickerMixin'

const format12H = 'YYYY-MM-DD h:mm A'

export default {
  name: 'KydemyTimePicker',
  mixins: [KydemyTimePickerMixin],
  data () {
    return {
      timeDisplayMode: 'time',
      selectedTime: null,
      displayHours: null,
      displayMinutes: null
    }
  },
  computed: {
    isAM () {
      if (this.selectedTime) {
        return this.selectedTime.format('A').toUpperCase() === 'AM'
      }
      return false
    }
  },
  watch: {
    value (newValue) {
      if (newValue && this.selectedTime && this.selectedTime.unix() !== newValue.unix()) {
        this.value = newValue
        this.selectedTime = newValue
      }
    },
    selectedTime (newVal) {
      this.$emit('displayDate', newVal)
    }
  },
  created () {
    const hourFormat = (this.is24h ? 'HH' : 'hh')
    this.displayHours = Array.apply(0, Array((this.is24h ? 24 : 12))).map(function (_, i) {
      return moment({ hour: i + 1 }).format(hourFormat)
    })
    this.displayMinutes = Array.apply(0, Array(12)).map(function (_, i) {
      return moment({ minute: i * 5 }).format('mm')
    })
    this.selectedTime = this.value
  },
  methods: {
    hoursMouseWheel (event) {
      event.stopPropagation()
      event.preventDefault()
      let newTime = null
      if (event.deltaY > 0) {
        newTime = this.selectedTime.clone().add(1, 'hour')
      } else {
        newTime = this.selectedTime.clone().subtract(1, 'hour')
      }
      this.selectedTime = newTime
      this.emitSelectedTime()
    },
    minutesMouseWheel (event) {
      event.stopPropagation()
      event.preventDefault()
      let newTime = null
      if (event.deltaY > 0) {
        newTime = this.selectedTime.clone().add(5, 'minutes')
      } else {
        newTime = this.selectedTime.clone().subtract(5, 'minutes')
      }
      this.selectedTime = newTime
      this.$forceUpdate()
      this.emitSelectedTime()
    },
    setTimeAM () {
      if (!this.isAM) {
        const pmTime = this.selectedTime.format(format12H)
        this.selectedTime = moment(pmTime.replace(' PM', ' AM'), format12H)
        this.emitSelectedTime()
      }
    },
    setTimePM () {
      if (this.isAM) {
        const amTime = this.selectedTime.format(format12H)
        this.selectedTime = moment(amTime.replace(' AM', ' PM'), format12H)
        this.emitSelectedTime()
      }
    },
    emitSelectedTime () {
      this.$emit('input', this.selectedTime.clone())
    },
    selectHour (hour) {
      const hourNumber = parseInt(hour)
      let newTime = this.selectedTime.clone().hours(hourNumber)
      if (!this.is24h) {
        newTime = moment(this.selectedTime.format(format12H.replace('h:', `${hourNumber}:`)), format12H)
      }
      this.selectedTime = newTime
      this.timeDisplayMode = 'time'
      this.emitSelectedTime()
    },
    selectMinute (minute) {
      this.selectedTime = this.selectedTime.clone().minutes(parseInt(minute))
      this.timeDisplayMode = 'time'
      this.emitSelectedTime()
    }
  }
}
</script>
