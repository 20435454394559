<template>
  <a
    class="panel-block"
    @click.stop.prevent="selectMember(member)"
  >
    <nav
      class="level is-mobile"
      style="width: 100%"
      :class="{'is-size-7': isSmall}"
    >
      <div class="level-left">
        <div class="level-item">
          <figure
            class="panel-icon image avatar"
            :class="{'is-24x24': isSmall, 'is-32x32': !isSmall}"
          >
            <img
              v-if="member.thumbnail_url"
              :alt="member.firstname"
              :src="member.thumbnail_url"
            >
            <img
              v-else-if="member.avatar && member.avatar.thumbnail_url"
              :alt="member.firstname"
              :src="member.avatar.thumbnail_url"
            >
          </figure>
          <span class="level-item">
            {{ member.firstname }} {{ member.lastname }}
          </span>
        </div>
      </div>
      <div
        v-if="searchRole === 'member'"
        class="level-right"
      >
        <div class="level-item">
          <div class="tags has-addons">
            <span
              class="tag"
              :class="getClassesClass(member)"
            >
              {{ member.num_classes_month }}
            </span>
            <span class="tag is-dark">
              {{ member.max_classes_month }}
            </span>
          </div>
        </div>
        <div class="level-item">
          <MembershipTag :membership-data="$store.getters.getMembershipData(member.membership_id)" />
        </div>
      </div>
      <div
        v-else-if="searchRole === 'teacher' && member.max_classes_month"
        class="level-right"
      >
        <div class="level-item">
          <div class="tags has-addons">
            <span class="tag is-dark">
              {{ $t('member.search.classes_month') }}
            </span>
            <span class="tag is-primary">
              {{ member.max_classes_month }}
            </span>
          </div>
        </div>
      </div>
    </nav>
  </a>
</template>

<script>
import MembershipTag from './MembershipTag'

export default {
  components: { MembershipTag },
  props: {
    member: { type: Object, required: true },
    searchRole: { type: String, default: null },
    isSmall: { type: Boolean, default: false }
  },
  data () {
    return {}
  },
  methods: {
    selectMember: function (member) {
      this.$emit('onMemberSelected', member)
      this.$emit('onClearSearch', false)
    },
    getClassesClass: function (member) {
      if (member.max_classes_month !== undefined) {
        const diff = member.max_classes_month - member.num_classes_month
        if (diff > 1) {
          return 'is-success'
        } else if (diff === 1) {
          return 'is-warning'
        } else {
          return 'is-danger'
        }
      }
    }
  }
}
</script>
