export default {
  methods: {
    initFbScript (f, b, e, v, n, t, s) {
      if (f.fbq) {
        return
      }
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) { f._fbq = n }
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    },
    initFbPixel () {
      this.initFbScript(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      window.fbq('init', '821219118067711')
    },
    trackFacebookEvent (action, value) {
      if (window && window.fbq) {
        window.fbq('track', action, value)
      } else {
        if (!window.fbEventsQueue) {
          window.fbEventsQueue = [[action, value]]
        } else {
          window.fbEventsQueue.push([action, value])
        }
        setTimeout(() => this.sendQueuedEvents(), 10000)
      }
    },
    sendQueuedEvents () {
      if (window && window.fbq) {
        const queue = window.fbEventsQueue
        window.fbEventsQueue = []
        queue.forEach(([action, value]) => window.fbq('track', action, value))
      } else {
        setTimeout(() => this.sendQueuedEvents(), 10000)
      }
    },
    trackAddPaymentInfo (plan, price) {
      this.trackFacebookEvent('AddPaymentInfo', {
        content_category: 'kydemy',
        content_name: plan,
        value: price,
        currency: 'EUR'
      })
    },
    trackSubscription (plan, price) {
      this.trackFacebookEvent('Subscribe', {
        content_category: 'kydemy',
        content_ids: plan,
        value: price,
        currency: 'EUR'
      })
    },
    trackStartTrial () {
      this.trackFacebookEvent('StartTrial', { content_category: 'kydemy', currency: 'EUR' })
    }
  }
}
