<template>
  <div
    :class="{'is-active': show}"
    class="modal"
  >
    <div class="modal-background" />
    <div
      :class="{'is-narrow': isNarrow, 'is-wide': isWide, 'is-medium': isMedium}"
      class="modal-card"
    >
      <header class="modal-card-head">
        <template v-if="showHeader">
          <p class="modal-card-title m-0">
            {{ title }}
          </p>
          <button
            v-if="addCloseIcon"
            class="delete"
            aria-label="close"
            @click="modalCancel"
          />
        </template>
      </header>
      <section class="modal-card-body has-text-left">
        <div
          v-if="content"
          :class="[contentClasses]"
          class="field"
          v-html="content"
        />
        <div
          v-if="contentBold"
          class="field"
        >
          <b v-html="contentBold" />
        </div>
        <portal-target
          v-if="show"
          name="modal-dialog-portal"
        />
        <slot />
        <div
          v-if="contentButtons"
          class="field is-grouped is-grouped-centered"
        >
          <div
            v-for="(btn,index) in contentButtons"
            :key="'content_btn_'+index"
            class="control"
          >
            <button
              :class="[btn.class]"
              class="button"
              @click="invokeButtonFunction(btn)"
            >
              <span class="icon">
                <font-awesome-icon :icon="btn.icon" />
              </span>
              <span>{{ btn.text }}</span>
            </button>
          </div>
        </div>
      </section>
      <footer
        v-if="visibleFooterButtons.length > 0 || addCloseFooterButton"
        class="modal-card-foot"
      >
        <div
          v-if="footerButtonsCentered"
          class="buttons is-centered"
          style="width: 100%"
        >
          <button
            v-for="(btn,index) in visibleFooterButtons"
            :key="'footer_btn_'+index"
            :class="[btn.class]"
            :disabled="btn.disabled"
            class="button"
            @click="invokeButtonFunction(btn)"
          >
            <span class="icon">
              <font-awesome-icon :icon="btn.icon" />
            </span>
            <span>{{ btn.text }}</span>
          </button>
        </div>
        <nav
          v-else
          :class="{'is-mobile': footerButtonsMobile}"
          class="level"
          style="width: 100%"
        >
          <div class="level-left">
            <div class="level-item">
              <a
                v-if="addCloseFooterButton"
                class="button"
                :class="{'is-small': footerButtonsMobile}"
                @click="modalCancel"
              >
                <span class="icon">
                  <font-awesome-icon :icon="closeButtonIcon" />
                </span>
                <span v-if="closeButtonCaption && closeButtonCaption.length > 0">{{ closeButtonCaption }}</span>
                <span v-else>{{ $t('modal_confirmation.cancel') }}</span>
              </a>
            </div>
          </div>
          <div class="level-right">
            <div
              v-for="(btn,index) in visibleFooterButtons"
              :key="'right_footer_btn_'+index"
              class="level-item"
            >
              <button
                :class="[{'is-small': footerButtonsMobile}, btn.class]"
                :disabled="btn.disabled"
                class="button"
                @click="invokeButtonFunction(btn)"
              >
                <span class="icon">
                  <font-awesome-icon :icon="btn.icon" />
                </span>
                <span>{{ btn.text }}</span>
              </button>
            </div>
          </div>
        </nav>
      </footer>
      <transition name="fade">
        <LoadingIndicator
          :show="loading"
          :progress="loadingProgress"
        />
      </transition>
    </div>
  </div>
</template>
<script>

import LoadingIndicator from './LoadingIndicator'

export default {
  name: 'ModalConfirmation',
  components: { LoadingIndicator },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: null
    },
    contentClasses: {
      type: String,
      default: null
    },
    contentBold: {
      type: String,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    addCloseIcon: {
      type: Boolean,
      default: true
    },
    contentButtons: {
      type: Array,
      default: null
    },
    footerButtons: {
      type: Array,
      default: null
    },
    footerButtonsCentered: {
      type: Boolean,
      default: false
    },
    footerButtonsMobile: {
      type: Boolean,
      default: false
    },
    addCloseFooterButton: {
      type: Boolean,
      default: true
    },
    closeButtonCaption: {
      type: String,
      default: null
    },
    closeButtonIcon: {
      type: String,
      default: 'times'
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingProgress: {
      type: String,
      default: null
    },
    isNarrow: {
      type: Boolean,
      default: false
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isMedium: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    visibleFooterButtons () {
      if (this.footerButtons && this.footerButtons.length > 0) {
        return this.footerButtons.filter(fb => fb.show !== false)
      }
      return []
    }
  },
  watch: {
    footerButtons: {
      handler () {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    invokeButtonFunction (btn) {
      btn.function.apply(null, btn.params)
      if (this.autoClose === true) {
        this.modalCancel()
      }
    },
    modalCancel () {
      this.$emit('modalCancel')
    }
  }
}
</script>
