<template>
  <div class="wizard-tab">
    <RouterLink :to="{ name: 'configuration', query: { section: 'general_information' }}">
      <p class="is-size-6 is-uppercase has-text-weight-bold has-text-info">
        {{ $t('wizard.wizard_tab_title') }}
      </p>
      <p class="is-size-7">
        {{ $t('wizard.wizard_tab_clear') }}
      </p>
    </RouterLink>
  </div>
</template>
<script>
export default {
  name: 'WizardTab'
}
</script>
