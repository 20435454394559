import rest from '../../rest'

const state = {
  holidays: [],
  holidaysLoaded: false
}

const HOLIDAYS_LOADED = 'HOLIDAYS_LOADED'
const HOLIDAYS_CLEAR = 'HOLIDAYS_CLEAR'

const mutations = {
  [HOLIDAYS_LOADED] (state, holidays) {
    state.holidaysLoaded = true
    state.holidays = holidays
  },
  [HOLIDAYS_CLEAR] (state) {
    state.holidaysLoaded = false
    state.holidays = null
  }
}

const actions = {
  async loadHolidays ({ commit }) {
    const response = await rest.getAxiosRequest('config/holidays/')
    if (response.status) {
      if (response.status === 200) {
        commit(HOLIDAYS_LOADED, response.data)
      } else if (response.status === 204) {
        commit(HOLIDAYS_LOADED, [])
      }
    }
  },
  async clearHolidays ({ commit }) {
    commit(HOLIDAYS_CLEAR)
  }
}
export default {
  state,
  mutations,
  actions
}
