<template>
  <div
    class="kydemy-avatar"
    :class="{'is-inline-block': isInlineBlock, 'is-unread': isUnread, 'is-relative': !noPosition, 'is-inactive': inactive}"
    :style="{'width': (size ? size + 'px' : 'unset'), 'height': (size ? size + 'px': 'unset')}"
    v-tooltip="tooltip ?? (inactive ? $t('members.inactive') : null)"
    @mouseover="onMouseOver"
    @mouseleave="isHovering = false"
  >
    <figure
      class="image avatar m-0"
      :class="[avatarSize,{'is-inline-block': isInlineBlock}]"
      :style="{'background-color': color ?? (inactive ? 'transparent' : '#999999')}"
      ref="avatarFigure"
    >
      <img
        v-if="imageObj"
        :alt="alt"
        :src="imgURL"
      >
    </figure>
    <template v-if="imageObj">
      <portal
        v-if="portalName"
        :to="portalName"
      >
        <transition
          name="fade"
          :duration="{ leave: 120 }"
        >
          <figure
            v-show="hoverable && isHovering"
            class="image avatar avatar-hover"
            :class="{'is-square': isSquare, 'is-4by3': !isSquare}"
            :style="{top: hoverTop +'px', left: hoverLeft+'px'}"
          >
            <img
              :alt="alt"
              :src="imgHoverURL"
            >
          </figure>
        </transition>
      </portal>
      <template v-else>
        <transition
          name="fade"
          :duration="{ leave: 120 }"
        >
          <figure
            v-show="hoverable && isHovering"
            class="image avatar avatar-hover is-fixed"
            :class="{'is-square': isSquare, 'is-4by3': !isSquare}"
            :style="{top: hoverTop +'px', left: hoverLeft+'px', borderRadius: '100%', overflow: 'hidden'}"
          >
            <img
              :alt="alt"
              :src="imgHoverURL"
            >
          </figure>
        </transition>
      </template>
    </template>
  </div>
</template>
<script>

const avatarHoverSize = 225

export default {
  props: {
    inactive: {
      type: Boolean,
      default: false
    },
    image: {
      type: [Object, String],
      default: null
    },
    color: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      required: true
    },
    hoverable: {
      default: true,
      type: Boolean
    },
    portalName: {
      default: 'avatars',
      type: String
    },
    alt: {
      type: String,
      required: true
    },
    isSquare: {
      type: Boolean,
      default: true
    },
    isInlineBlock: {
      type: Boolean,
      default: false
    },
    isUnread: {
      type: Boolean,
      default: false
    },
    noPosition: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imageObj: null,
      isHovering: false,
      hoverTop: 0,
      hoverLeft: 0
    }
  },
  computed: {
    avatarSize () {
      return 'is-' + this.size + 'x' + this.size
    },
    imgThumbURL () {
      return this.imageObj.thumbnail_url
    },
    imgLowResURL () {
      return this.imageObj.lowres_url
    },
    imgHighResURL () {
      return this.imageObj.highres_url
    },
    imgURL () {
      if (this.size <= 64) {
        return this.imgThumbURL
      } else if (this.size <= 250) {
        return this.imgLowResURL
      } else {
        return this.imgHighResURL
      }
    },
    imgHoverURL () {
      if (this.size <= 64) {
        return this.imgLowResURL
      } else {
        return this.imgHighResURL
      }
    }
  },
  created () {
    if (!this.image) {
      this.imageObj = null
    } else if (typeof this.image === 'string') {
      this.imageObj = {
        thumbnail_url: this.image,
        lowres_url: this.image,
        highres_url: this.image
      }
    } else if (this.image.avatar) {
      this.imageObj = this.image.avatar
    } else if (this.image.image) {
      this.imageObj = this.image.image
    } else {
      this.imageObj = this.image
    }
  },
  methods: {
    onMouseOver (ev) {
      if (!this.hoverable) {
        return
      }
      if (this.$refs.avatarFigure) {
        let hTop = this.$refs.avatarFigure.getBoundingClientRect().top - ((avatarHoverSize - this.size) / 2)
        let hLeft = this.$refs.avatarFigure.getBoundingClientRect().left + (this.size * 1.5)
        if (hLeft + avatarHoverSize > window.innerWidth) {
          hLeft = this.$refs.avatarFigure.getBoundingClientRect().left - (avatarHoverSize + (this.size / 2))
        }
        if (hTop + avatarHoverSize > window.innerHeight) {
          hTop = window.innerHeight - (avatarHoverSize + (this.size / 2))
        }
        this.hoverTop = hTop
        this.hoverLeft = hLeft
      }
      this.isHovering = true
    }
  }
}
</script>
