import rest from '../../rest'

const BUSINESSES = 'BUSINESSES'

const state = {
  Businesses: localStorage.getItem(BUSINESSES) !== null ? JSON.parse(localStorage.getItem(BUSINESSES)) : null,
  BusinessesLoaded: localStorage.getItem(BUSINESSES) !== null
}

const getters = {}

const actions = {
  async createBusiness ({ commit, state, dispatch }, businessData) {
    const apiResponse = await rest.apiPost('invoices/businesses/', businessData)
    if (apiResponse.status >= 200 && apiResponse.status < 300) {
      await dispatch('loadBusinesses', true)
      return apiResponse.getLocationID()
    } else {
      return false
    }
  },
  async loadBusinesses ({ commit, state }, force) {
    if (!state.BusinessesLoaded || force === true) {
      state.BusinessesLoaded = false
      const apiResponse = await rest.apiGet('invoices/businesses/')
      if (apiResponse.isOK()) {
        localStorage.setItem(BUSINESSES, JSON.stringify(apiResponse.data))
        commit(BUSINESSES)
      } else {
        localStorage.setItem(BUSINESSES, null)
        state.BusinessesLoaded = false
      }
    }
  }
}

const mutations = {
  [BUSINESSES] (state) {
    state.Businesses = JSON.parse(localStorage.getItem(BUSINESSES))
    state.BusinessesLoaded = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
