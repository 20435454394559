export default {
  methods: {
    async postFrontEndError (description, data) {
      if (!this.$sentry) {
        return
      }
      const messageData = {
        url: window.location.toString(),
        account_id: this.accountID,
        name: this.fullName,
        email: this.accountDetails.email,
        component: this.$options.name,
        path: window.location.hash,
        data: data
      }
      this.$sentry.setContext('Error Context', messageData)
      this.$sentry.captureMessage(description)

      messageData.description = description
      await this.apiPost('traces/errors/', messageData)
    }
  }
}
