<template>
  <div
    v-if="$t"
    id="app"
    class="kydemy-background"
    :style="{'background-image': 'url(' +getThemeImage('background')+ ')', 'background-repeat': 'no-repeat'}"
    :class="{'kydemy-background-dark': darkBackground}"
  >
    <router-view />
    <KydemyTasks v-if="(isEffectiveManager() || isEffectiveTeacher()) && !getExcludedViews().includes($route.name)" />
    <KydemyAccessQR v-if="isSettingEnabled('INTEGRATION_QR') && ['landing', 'relative-landing'].includes($route.name)" />
    <TimeKeeperRecorder v-if="isSettingEnabled('TIME_KEEPER_ENABLED') && !getExcludedViews().includes($route.name)" />
    <ModalConfirmation
      :show="showClientErrorModal !== null"
      :add-close-icon="false"
      :add-close-footer-button="false"
      :auto-close="false"
      :title="modalTitle"
      :content="modalContent"
      :content-bold="modalBold"
      :content-buttons="[]"
      :footer-buttons="[{icon: (showClientErrorModal === 'offline' ? 'sync-alt' : 'sign-out-alt'), class: 'is-link', text: $t(showClientErrorModal === 'offline' ? 'reload_client_button' : 'outdated_client_button'),function: reloadKydemy}]"
      :is-narrow="true"
      @modalCancel="showDeleteAccountConfirm = false"
    />
    <WizardTab
      v-if="isSettingEnabled('HAS_WIZARD_RANDOM_DATA') && !getExcludedViews(['configuration']).includes($route.name)"
    />
    <a
      v-if="showHelpButton"
      id="help-button"
      @click="showHelpArticles"
    >
      <span
        :class="[$breakpoints.isMobile() ? '' : 'is-large']"
        class="icon has-text-info-dark has-background-white has-text-weight-bold"
      >
        <bulma-icon icon="circle-question" :size="$breakpoints.isMobile()  ? '2x' : '3x'" />
      </span>
    </a>
    <notifications
      position="bottom left"
      :ignore-duplicates="true"
      :max="5"
    >
      <template slot="body" slot-scope="{ item, close }">
        <div
          :class="item.data.classes"
          class="notification has-text-left m-l-1 m-b-1"
        >
          <button
            v-if="item.data.showClose"
            class="delete"
            @click="close"
          />
          <p class="title is-size-6 m-b-03">
            {{ item.title }}
          </p>
          <div class="media">
            <div class="media-left m-t-04 m-r-05">
              <KydemyAvatar
                v-if="item.data.avatar_url"
                :alt="item.data.name"
                :size="24"
                :image="item.data.avatar_url"
              />
            </div>
            <div class="media-content">
              <p class="is-size-7 m-0" v-html="item.text" />
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import KydemyTasks from './components/KydemyTasks'
import { mapState } from 'vuex'
import ModalConfirmation from './components/controls/ModalConfirmation'
import WizardTab from './components/WizardTab'
import TimeKeeperRecorder from './components/TimeKeeperRecorder'
import KydemyAccessQR from '@/components/KydemyAccessQR'
import Crisp from '@/mixins/crisp'
import Conversions from '@/mixins/conversions'
import BulmaIcon from '@/components/bulma/BulmaIcon.vue'
import KydemyAvatar from '@/components/controls/KydemyAvatar.vue'

export default {
  name: 'App',
  components: {
    KydemyAvatar,
    BulmaIcon,
    KydemyAccessQR,
    TimeKeeperRecorder,
    WizardTab,
    ModalConfirmation,
    KydemyTasks
  },
  mixins: [Crisp, Conversions],
  data () {
    return {
      showClientErrorModal: null,
      darkBackground: false
    }
  },
  computed: {
    modalTitle () {
      switch (this.showClientErrorModal) {
        case 'outdated':
          return this.$t('outdated_client_title')
        case 'expired':
          return this.$t('expired_client_title')
        case 'different':
          return this.$t('different_client_title')
        case 'offline':
          return this.$t('offline_client_title')
      }
      return ''
    },
    modalContent () {
      switch (this.showClientErrorModal) {
        case 'outdated':
          return this.$t('outdated_client_message')
        case 'expired':
          return this.$t('expired_client_message')
        case 'different':
          return this.$t('different_client_message')
        case 'offline':
          return this.$t('offline_client_message')
      }
      return ''
    },
    modalBold () {
      switch (this.showClientErrorModal) {
        case 'outdated':
          return this.$t('outdated_client_bold')
        case 'expired':
          return this.$t('expired_client_bold')
        case 'different':
          return this.$t('different_client_bold')
        case 'offline':
          return this.$t('offline_client_bold')
      }
      return ''
    },
    ...mapState(['outdatedClient', 'expiredClient', 'differentClient', 'Configuration'])
  },
  watch: {
    Configuration () {
      this.updateBgColor()
      this.applyI18nReplacements()
    },
    outdatedClient (newVal) {
      this.showClientErrorModal = newVal ? 'outdated' : null
    },
    expiredClient (newVal) {
      this.showClientErrorModal = newVal ? 'expired' : null
    },
    differentClient (newVal) {
      this.showClientErrorModal = newVal ? 'different' : null
    },
    '$store.getters.accountData' (accData) {
      if (accData && this.isEffectiveManager() && accData.account_id > 1) {
        setTimeout(() => this.initFbPixel(), 1)
        this.bootCrisp()
      } else {
        this.hideChatButton()
        this.resetCrisp()
      }
    },
    '$route' (newRoute) {
      if (newRoute && newRoute.name) {
        this.bootMixins()
      }
    }
  },
  created () {
    if (window.localStorage && window.localStorage.getItem('redirectLogin')) {
      window.localStorage.removeItem('redirectLogin')
      this.$router.push({ name: 'login' }).catch(() => { /* SAME ROUTE */ })
    }
    this.checkPlatformExists()
    this.updateBgColor()
    this.applyI18nReplacements()
  },
  mounted () {
    window.addEventListener('offline', this.onConnectionLost)
    window.addEventListener('online', this.onConnectionResume)
    if (window.navigator.onLine === false && !this.isDevMode()) {
      this.showClientErrorModal = 'offline'
    }
    if (!this.isSettingEnabled('TIME_KEEPER_ENABLED') || this.getExcludedViews().includes(this.$route.name)) {
      const store = this.$store
      this.$root.$on('logout', () => {
        store.dispatch('logout')
      })
    }
    this.$eventHub.$on('logout', this.logout)
  },
  methods: {
    async bootMixins () {
      if (this.$store.getters.hasAccountData && this.isEffectiveManager()) {
        const accData = this.$store.getters.accountData
        if (accData.account_id > 1) {
          await this.bootCrisp()
          this.initFbPixel()
          this.checkHelpArticles()
        }
      }
    },
    async logout () {
      await this.$router.push('/login').catch(() => { /* SAME ROUTE */ })
      await this.apiDelete('accounts/logout/')
    },
    mergeTranslations (translations, replacements) {
      let auxVal = null
      for (const key in replacements) {
        auxVal = replacements[key]
        if (typeof auxVal === 'object') {
          translations[key] = this.mergeTranslations(translations[key], auxVal)
        } else if (Array.isArray(auxVal)) {
          translations[key] = auxVal
        } else {
          translations[key] = auxVal
        }
      }
      return translations
    },
    applyI18nReplacements () {
      if (this.Configuration) {
        this.$i18n.KydemyConfiguration = this.Configuration
        if (this.Configuration.i18n_replacements && this.$i18n && this.$i18n.availableLocales && this.$i18n.availableLocales.length > 0) {
          const replacements = this.Configuration.i18n_replacements
          for (const locale of this.$i18n.availableLocales) {
            if (locale in replacements) {
              const translations = this.$i18n.getLocaleMessage(locale)
              const newTranslations = this.mergeTranslations(translations, replacements[locale])
              this.$i18n.setLocaleMessage(locale, newTranslations)
            }
          }
        }
      }
    },
    updateBgColor () {
      if (this.Configuration && this.Configuration.background_color && this.Configuration.background_color.length === 7) {
        this.darkBackground = !this.isLightColor(this.Configuration.background_color)
      }
    },
    async checkPlatformExists () {
      try {
        const response = await this.getAxiosRequest('config/ping/')
        if (response.status === 404) {
          this.redirectToNotFound()
        } else {
          await this.$store.dispatch('loadConfiguration')
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          this.redirectToNotFound()
        }
      }
    },
    redirectToNotFound () {
      window.location = 'https://www.kydemy.com/not-found'
    },
    getExcludedViews (extraViews) {
      const excluded = ['login', 'forgot-password', 'registration', 'registration-validation', 'wizard', 'streaming-session', 'class-streaming-session', 'event-streaming-session', 'link-payment']
      if (extraViews && extraViews.length > 0) {
        return excluded.concat(extraViews)
      }
      return excluded
    },
    reloadKydemy () {
      if (this.showClientErrorModal === 'offline') {
        window.location.reload(true)
      } else {
        this.showClientErrorModal = null
        this.$store.dispatch('reloadKydemy')
      }
    },
    onConnectionResume () {
      /* eslint no-console: "off" */
      console.log('Conection resume!!')
      if (this.showClientErrorModal === 'offline') {
        this.showClientErrorModal = null
      }
    },
    isDevMode () {
      return this.buildMode === 'development'
    },
    onConnectionLost () {
      /* eslint no-console: "off" */
      console.log('Conection lost!!')
      if (!this.isDevMode()) {
        this.showClientErrorModal = 'offline'
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/kydemy.scss";
</style>
