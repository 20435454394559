<template>
  <div class="kydemy login">
    <div class="min-header"/>
    <div
      class="section kydemy-background"
      :style="{'background-image': 'url(' +getThemeImage('background')+ ')', 'background-repeat': 'no-repeat'}"
    >
      <div class="container m-b-3">
        <div class="columns is-centered">
          <div class="column is-4 login-box-column">
            <div class="field is-flex is-horizontal-center">
              <figure class="image is-350">
                <img
                  :src="getThemeImage('logo')"
                  alt="Platform Logo"
                  class="platform-logo"
                >
              </figure>
            </div>
            <div
              class="box is-overflow-hidden is-relative has-border-rounded-large has-border-with-shadow-strong login-box-wrapper"
            >
              <transition
                name="fade"
                mode="out-in"
                :duration="{ enter: 200, leave: 240 }"
              >
                <div
                  v-if="displayMode==='login'"
                  class="loginBox"
                >
                  <h1 class="is-hidden">
                    <span v-if="pageTitle">{{ pageTitle }}</span>
                    <span v-else>{{ $t($route.meta.title) }}</span>
                  </h1>
                  <h2 class="title is-size-3 is-size-4-mobile m-b-2 m-t-1 has-text-primary-dark">
                    {{ $t('login.form_title') }}
                  </h2>
                  <form @submit.prevent="performLogin">
                    <div class="field">
                      <label
                        for="txtUsername"
                        class="label has-text-left is-size-7 has-text-primary-dark"
                      >
                        {{ $t('login.username') }}
                      </label>
                      <KydemyTextInput
                        id="txtUsername"
                        v-model="login.username"
                        :validation="$v.login.username"
                        :placeholder="$t('login.usernamePlaceholder')"
                        :min-length="3"
                        :auto-focus="true"
                        :auto-trim="true"
                        auto-complete="kydemyUsername"
                        left-icon="envelope"
                        size="medium"
                      />
                    </div>
                    <div class="field">
                      <label
                        for="txtPassword"
                        class="label has-text-left is-size-7 has-text-primary-dark"
                      >
                        {{ $t('login.password') }}
                      </label>
                      <KydemyTextInput
                        id="txtPassword"
                        v-model="login.password"
                        :validation="$v.login.password"
                        :placeholder="$t('login.passwordPlaceholder')"
                        size="medium"
                        type="password"
                        left-icon="lock"
                        :min-length="6"
                        :auto-trim="true"
                        auto-complete="kydemyPassword"
                      />
                    </div>
                    <div class="field is-horizontal m-b-1 m-t-1">
                      <div class="field-body">
                        <div class="field">
                          <input
                            id="remember_me"
                            v-model="rememberMe"
                            type="checkbox"
                            class="is-checkradio is-small"
                          >
                          <label
                            class="checkbox checkradio"
                            for="remember_me"
                          >
                            {{ $t('login.remember_me') }}
                          </label>
                        </div>
                        <a
                          class="field is-size-7 is-flex is-vertical-center is-horizontal-center"
                          @click="displayMode = 'forgot_password'"
                        >
                          {{ $t('login.forgot_password_link') }}
                        </a>
                      </div>
                    </div>
                    <p
                      v-if="loginError"
                      class="help is-danger m-b-05 m-t-0"
                    >
                      {{ $t('login.loginError') }}
                    </p>
                    <BulmaMessage
                      v-else-if="loginActiveError && isRegistrationEnabled"
                      translation-code="login.active_error"
                      icon="exclamation-circle"
                      bulma-class="warning"
                      size="small"
                    />
                    <div class="buttons is-centered">
                      <button
                        type="submit"
                        class="button is-primary is-uppercase is-medium is-marginless is-fullwidth has-text-weight-bold"
                        :class="{ 'is-loading': isLoading}"
                      >
                        {{ $t('login.loginButton') }}
                      </button>
                    </div>
                    <div
                      v-if="Configuration && Configuration.registrations === true"
                      class="buttons is-centered p-b-05 m-t-1"
                    >
                      <button
                        class="button is-info is-normal is-uppercase is-marginless is-fullwidth has-text-weight-bold"
                        @click.stop.prevent="$router.push({name: 'registration'})"
                      >
                        {{ $t('registration.login_button') }}
                      </button>
                    </div>
                    <div
                      v-if="isLoading"
                      class="loading-overlay"
                    >
                      <a class="button is-large is-loading"/>
                    </div>
                  </form>
                </div>
                <div
                  v-else-if="displayMode === 'forgot_password'"
                  key="2"
                  class="rememberPasswordBox"
                >
                  <form @submit.prevent="requestForgotPassword">
                    <h3 class="title is-4">
                      {{ $t('login.forgot_password_title') }}
                    </h3>
                    <div class="field">
                      <p>{{ $t('login.forgot_password_desc') }}</p>
                    </div>
                    <div class="field">
                      <p class="control has-icons-left has-icons-right">
                        <KydemyTextInput
                          v-model="forgot_password.email"
                          :validation="$v.forgot_password.email"
                          :placeholder="$t('login.forgot_password_placeholder')"
                          size="medium"
                          type="email"
                          left-icon="at"
                          :auto-trim="true"
                        />
                      </p>
                    </div>
                    <div class="field">
                      <button
                        class="button is-success"
                        :disabled="$v.forgot_password.$invalid"
                      >
                        {{ $t('login.reset_password_button') }}
                      </button>
                    </div>
                    <div
                      v-if="errorUserNotActive"
                      class="field message is-danger"
                    >
                      <div
                        class="message-body"
                        v-html="$t('login.user_not_active')"
                      />
                    </div>
                    <div
                      v-else
                      class="field message is-info is-small"
                    >
                      <div
                        class="message-body"
                        v-html="$t('login.username_reminder')"
                      />
                    </div>
                    <div class="field is-size-7">
                      <a @click="displayMode = 'login'">
                        {{ $t('login.cancel') }}
                      </a>
                    </div>
                  </form>
                  <div
                    v-if="forgot_loading"
                    class="loading-overlay"
                  >
                    <a class="button is-large is-loading"/>
                  </div>
                  <transition name="fade-modal">
                    <div
                      v-if="forgot_password_modal"
                      class="modal is-active"
                    >
                      <div class="modal-background"/>
                      <div class="modal-card">
                        <header class="modal-card-head">
                          <p class="modal-card-title">
                            {{ $t('login.email_sent') }}
                          </p>
                          <button
                            class="delete"
                            aria-label="close"
                            @click="closeForgotPasswordModal"
                          />
                        </header>
                        <section class="modal-card-body">
                          {{ $t('login.email_details') }}
                        </section>
                        <footer class="modal-card-foot has-text-centered">
                          <div class="level">
                            <div class="level-item">
                              <button
                                class="button"
                                @click="closeForgotPasswordModal"
                              >
                                {{ $t('login.close') }}
                              </button>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </transition>
                </div>
              </transition>
              <SocialNetworkIcons :configuration="Configuration"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBar
      :white-label="Configuration === null || Configuration.white_label === true"
    />
  </div>
</template>

<script>
import qs from 'qs'
import FooterBar from './layout/FooterBar'
import SocialNetworkIcons from './controls/SocialNetworkIcons'
import KydemyTextInput from './controls/KydemyTextInput'
import { minLength, maxLength, required, email } from 'vuelidate/lib/validators'
import { isMobile } from 'mobile-device-detect'
import { mapState } from 'vuex'
import BulmaMessage from '@/components/bulma/BulmaMessage'

export default {
  name: 'LoginScreen',
  components: {
    BulmaMessage,
    KydemyTextInput,
    SocialNetworkIcons,
    FooterBar
  },
  data () {
    return {
      pageTitle: null,
      displayMode: 'login',
      rememberMe: false,
      usernameClass: {
        'is-success': false,
        'is-danger': false
      },
      passwordClass: {
        'is-success': false,
        'is-danger': false
      },
      login: {
        username: null,
        password: null
      },
      forgot_password: {
        email: null
      },
      forgot_loading: false,
      forgot_password_modal: false,
      errorUserNotActive: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.pending,
      loginError: state => state.loginError,
      loginActiveError: state => state.loginActiveError,
      Configuration: state => state.Configuration
    })
  },
  watch: {
    '$store.getters.hasAccountData': function (val) {
      if (val === true) {
        this.redirectToLanding()
      }
    },
    Configuration () {
      this.updateTitle()
    }
  },
  async created () {
    if (localStorage.getItem('remember_me') !== null) {
      this.rememberMe = localStorage.getItem('remember_me') === 'true'
    } else {
      this.rememberMe = false
    }
    const forgotPassPage = localStorage.getItem('forgot-password-page')
    localStorage.removeItem('imp_fullname')
    localStorage.removeItem('imp_token')
    localStorage.removeItem('imp_account_id')

    const token = localStorage.getItem('token') !== null ? localStorage.getItem('token') : ''
    const accountID = localStorage.getItem('account_id') !== null ? localStorage.getItem('account_id') : 0
    if (this.rememberMe === true && token.length > 0 && accountID >= 0) {
      this.$store.dispatch('getAccountData')
    } else {
      this.$store.dispatch('clear')
    }
    if (this.$route.query.forgot_password) {
      this.displayMode = 'forgot_password'
    }
    if (forgotPassPage) {
      localStorage.setItem('forgot-password-page', forgotPassPage)
    }
    if (this.Configuration) {
      this.updateTitle()
    }
  },
  beforeMount () {
    if (this.Configuration) {
      this.updateTitle()
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.page && localStorage) {
      localStorage.setItem('forgot-password-page', this.$route.query.page)
    }
    this.$nextTick(() => {
      this.checkAutoLogin()
    })
  },
  methods: {
    async redirectToLanding () {
      let shouldRedirectToDefaultView = true
      if ((this.isEffectiveStudent() || this.isEffectiveRelative()) && this.isDigitalSignaturesEnabled()) {
        shouldRedirectToDefaultView = await this.checkRequiresSignature()
      }
      if (shouldRedirectToDefaultView) {
        this.navigateToDefaultView()
      }
    },
    updateTitle: function () {
      if (!this.pageTitle && this.Configuration && this.Configuration.product_name) {
        let bType = ''
        if (this.Configuration.business_type !== 'other') {
          const bTypes = this.$t('config.general_info.business_types')
          if (bTypes && bTypes.length > 0 && bTypes.find) {
            bType = bTypes.find(b => b.code === this.Configuration.business_type).name
          }
        }
        this.pageTitle = this.$t('page_titles.login_seo', {
          name: this.Configuration.product_name,
          btype: bType
        })
        if (this.pageTitle === 'page_titles.login_seo') {
          setTimeout(() => {
            this.pageTitle = null
            this.updateTitle()
          }, 50)
        }
        document.title = this.pageTitle

        // META
        const meta = document.createElement('meta')
        meta.setAttribute('name', 'description')
        meta.content = this.pageTitle
        document.getElementsByTagName('head')[0].appendChild(meta)

        // Schema.org
        const jsonld = {
          '@context': 'http://schema.org',
          '@type': 'WebApplication',
          applicationCategory: 'BusinessApplication',
          author: {
            '@id': 'https://www.kydemy.com'
          },
          browserRequirements: 'Requires JavaScript to be enabled, HTML5 support',
          copyrightHolder: {
            '@id': 'https://www.kydemy.com'
          },
          copyrightYear: new Date().getFullYear(),
          description: '',
          headline: this.pageTitle.split('-')[1],
          image: {
            '@type': 'ImageObject',
            height: '888',
            url: 'https://www.kydemy.com/logos/kydemy.svg',
            width: '680'
          },
          inLanguage: 'en',
          name: 'Kydemy',
          operatingSystem: 'Web Browser',
          publisher: {
            '@id': 'https://www.kydemy.com'
          },
          url: 'https://www.kydemy.com/caracteristicas',
          offers: {
            '@type': 'Offer',
            url: 'https://www.kydemy.com/formulario',
            priceCurrency: 'EUR',
            price: '60.00',
            availability: 'https://schema.org/OnlineOnly'
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.9',
            reviewCount: '8'
          }
        }
        const jsonTag = document.createElement('script')
        jsonTag.setAttribute('type', 'application/ld+json')
        jsonTag.innerText = JSON.stringify(jsonld)
        document.getElementsByTagName('head')[0].appendChild(jsonTag)
      }
    },
    checkAutoLogin () {
      if (this.$route.query.account_id && !isNaN(this.$route.query.account_id) && parseInt(this.$route.query.account_id) > 0 && this.$route.query.token && this.$route.query.token.length === 36) {
        const token = this.$route.query.token
        const accountID = this.$route.query.account_id
        localStorage.setItem('token', token)
        localStorage.setItem('account_id', accountID)
        localStorage.setItem('remember_me', 'true')
        localStorage.setItem('account_data', null)

        this.$store.commit('LOGIN_SUCCESS')
      } else if (this.$route.query && this.$route.query.mobile_redirect && isMobile) {
        window.location = 'kydemy://' + (window.location.hostname) + '/login'
      }
    },
    performLogin () {
      this.$v.login.$touch()
      if (this.$v.login.$invalid === false) {
        const shajs = require('sha.js')
        this.$store.dispatch('login',
          {
            username: shajs('sha256').update(this.login.username.toLowerCase()).digest('base64'),
            password: shajs('sha256').update(this.login.password).digest('base64'),
            rememberMe: this.rememberMe
          }
        )
      }
      return false
    },
    requestForgotPassword () {
      const self = this
      this.$v.forgot_password.$touch()
      if (this.$v.forgot_password.$invalid === false) {
        self.forgot_loading = true
        self.postAxiosRequest('accounts/forgot-password/?' + qs.stringify({
          email: self.forgot_password.email,
          lang: self.getLangCode()
        }), null).then(
          response => {
            self.forgot_loading = false
            self.forgot_password_modal = true
          }
        ).catch(e => {
          if (e.response.status === 403 || e.response.status === 404) {
            self.errorUserNotActive = true
          }
          self.forgot_loading = false
        })
      }
    },
    closeForgotPasswordModal () {
      this.forgot_password.email = ''
      this.forgot_password_modal = false
      this.displayMode = 'login'
    }
  },
  validations: {
    login: {
      required,
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30)
      }
    },
    forgot_password: {
      required,
      email: {
        required,
        email
      }
    }
  }
}
</script>
