var render = function render(_c,_vm){return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.parent.$t(_vm.props.tooltipTranslationCode)),expression:"parent.$t(props.tooltipTranslationCode)"}],staticClass:"icon",class:[
    _vm.props.bulmaClass ? 'has-text-' + _vm.props.bulmaClass : null,
    _vm.props.classes,
    {
      'is-medium': _vm.props.size === 'lg' || _vm.props.size === '2x',
      'is-large': _vm.props.size === '3x' || _vm.props.size === '4x'
    }
  ],style:({'color': _vm.props.color ? _vm.props.color : null})},[_c('font-awesome-icon',{attrs:{"icon":_vm.props.icon,"size":_vm.props.size}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }