<template>
  <div class="kydemy-month-calendar">
    <div class="columns is-variable is-1 is-mobile week-headers is-marginless is-paddingless">
      <div
        v-for="day in weekDays"
        :key="'weekday_'+day.short"
        class="column week-day is-capitalized"
      >
        {{ day.short }}
      </div>
    </div>
    <div class="columns is-variable is-1 is-marginless is-paddingless is-mobile kydemy-calendar is-multiline">
      <div
        v-for="day in monthDates"
        :key="day.iso"
        v-tooltip="{content: day.tooltip}"
        :class="[day.classes]"
        class="column week-day"
      >
        <button
          :class="{'is-primary': day.selected, 'is-disabled': disabled || day.disabled}"
          :disabled="isDayDisabled(day)"
          :style="{'background-color': day.color ? day.color : 'auto'}"
          class="is-white button is-rounded is-paddingless"
          @click="$event => dayClick(day, $event)"
        >
          {{ day.mnt.format('D') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import business from 'moment-business'
import KydemyMonthCalendarMixin from './KydemyMonthCalendarMixin'

const today = moment().startOf('day')
const dateFormatISO = 'YYYY-MM-DD'

export default {
  name: 'KydemyMonthCalendar',
  mixins: [KydemyMonthCalendarMixin],
  data () {
    return {
      weekDays: [],
      specialDates: new Map(),
      disableDays: false,
      monthDates: [],
      selectedDates: [],
      displayDate: null
    }
  },
  watch: {
    locale () {
      this.initCalendar()
    },
    value () {
      this.updateDisplayDate()
      this.updateCalendar()
    },
    highlightedDays () {
      this.updateCalendar()
    },
    disabledDays () {
      this.updateCalendar()
    },
    enabledDays () {
      this.updateCalendar()
    },
    displayDate (newVal) {
      this.$emit('displayDate', newVal)
    },
    displayMonth () {
      this.updateCalendar()
    },
    minDate () {
      this.updateCalendar()
    },
    maxDate () {
      this.updateCalendar()
    }
  },
  created () {
    this.initCalendar()
  },
  beforeMount () {
    this.updateCalendar()
  },
  methods: {
    initCalendar () {
      moment.locale(this.locale)
      let day = null
      const currentWeekDay = moment().weekday(0)
      this.weekDays = Array.apply(0, Array(7)).map(function (_, i) {
        day = {
          index: i,
          name: currentWeekDay.format('dddd'),
          short: currentWeekDay.format('ddd')
        }
        currentWeekDay.add('1', 'day')
        return day
      })
      this.updateDisplayDate()
    },
    prevMonth () {
      this.displayDate = this.displayDate.clone().subtract(1, 'month')
      this.updateCalendar()
    },
    nextMonth () {
      this.displayDate = this.displayDate.clone().add(1, 'month')
      this.updateCalendar()
    },
    prevYear () {
      this.displayDate = this.displayDate.clone().subtract(1, 'year')
      this.updateCalendar()
    },
    nextYear () {
      this.displayDate = this.displayDate.clone().add(1, 'year')
      this.updateCalendar()
    },
    setDisplayDate (mnt) {
      this.displayDate = mnt.clone()
      this.updateCalendar()
    },
    isDayDisabled (dayData) {
      return (this.disabled || dayData.disabled) || (this.onlySelectedClickable && dayData.selected === false)
    },
    updateDisplayDate () {
      if (this.value) {
        if (Array.isArray(this.value)) {
          this.selectedDates = this.value
          this.displayDate = this.value[0]
        } else {
          this.selectedDates = [this.value]
          this.displayDate = this.value
        }
      } else if (this.displayMonth) {
        if (this.displayMonth instanceof Object) {
          this.displayDate = this.displayMonth.clone()
        } else {
          this.displayDate = moment(this.displayMonth)
        }
      } else {
        this.selectedDates = []
        this.displayDate = today
      }
    },
    dayClick (dateData, event) {
      this.$emit('dayClick', dateData.mnt, event)
      if (this.selectable) {
        if (this.range) {
          if (this.selectedDates.length === 2) {
            this.selectedDates = [dateData.mnt]
          } else {
            this.selectedDates.push(dateData.mnt)
          }
        } else {
          const index = this.selectedDates.findIndex(sd => sd.format(dateFormatISO) === dateData.iso)
          if (index >= 0) {
            this.selectedDates.splice(index, 1)
          } else {
            if (this.multiple) {
              this.selectedDates.push(dateData.mnt)
            } else {
              this.selectedDates = [dateData.mnt]
            }
          }
        }
      }
      this.selectedDates.sort((sd1, sd2) => sd1.unix() - sd2.unix())
      if (this.value !== undefined) {
        if (this.multiple) {
          this.$emit('input', this.selectedDates)
        } else if (this.selectedDates.length > 0) {
          this.$emit('input', this.selectedDates[0])
        }
      } else {
        this.updateCalendar()
      }
    },
    updateSpecialDays () {
      this.specialDates.clear()
      const baseDayData = {
        highlighted: false,
        disabled: false,
        enabled: true,
        selected: false,
        inRange: false,
        rangeStart: false,
        rangeEnd: false,
        color: null
      }
      let auxDateISO = null
      if (this.highlightedDays && this.highlightedDays.length > 0) {
        this.highlightedDays.forEach(hd => {
          auxDateISO = hd.format(dateFormatISO)
          const newDateData = this.cloneObject(baseDayData, { highlighted: true })
          newDateData.color = hd.color ? hd.color : null
          newDateData.tooltip = hd.tooltip ? hd.tooltip : null
          this.specialDates.set(auxDateISO, newDateData)
        })
      }
      if (this.disabledDays && this.disabledDays.length > 0) {
        this.disabledDays.forEach(dd => {
          auxDateISO = dd.format(dateFormatISO)
          let newDateData = this.cloneObject(baseDayData, { disabled: true })
          if (this.specialDates.has(auxDateISO)) {
            newDateData = this.cloneObject(this.specialDates.get(auxDateISO), { disabled: true })
          }
          newDateData.color = dd.color ? dd.color : null
          newDateData.tooltip = dd.tooltip ? dd.tooltip : null
          this.specialDates.set(auxDateISO, newDateData)
        })
      }
      if (this.enabledDays && this.enabledDays.length > 0) {
        this.enabledDays.forEach(ed => {
          auxDateISO = ed.format(dateFormatISO)
          let newDateData = this.cloneObject(baseDayData, { enabled: true })
          if (this.specialDates.has(auxDateISO)) {
            newDateData = this.cloneObject(this.specialDates.get(auxDateISO), { disabled: false })
          }
          this.specialDates.set(auxDateISO, newDateData)
          this.disableDays = true
        })
      }
      if (this.selectedDates && this.selectedDates.length > 0) {
        this.selectedDates.forEach(sd => {
          if (sd && typeof sd === 'string') {
            sd = moment(sd)
          }
          auxDateISO = sd.format(dateFormatISO)
          let newDateData = this.cloneObject(baseDayData, { selected: true })
          if (this.specialDates.has(auxDateISO)) {
            newDateData = this.cloneObject(this.specialDates.get(auxDateISO), { selected: true })
          }
          newDateData.color = sd.color ? sd.color : null
          newDateData.tooltip = sd.tooltip ? sd.tooltip : null
          this.specialDates.set(auxDateISO, newDateData)
        })
      }
      if (this.range && this.selectedDates.length > 0) {
        if (this.selectedDates.length === 2) {
          const d1 = moment(this.selectedDates[0])
          const d2 = moment(this.selectedDates[1])
          const startISO = d1.format(dateFormatISO)
          const endISO = d2.format(dateFormatISO)
          if (d1.isBefore(d2)) {
            let auxDate = d1.clone().subtract(1, 'day')
            do {
              auxDate = auxDate.clone().add(1, 'day')
              auxDateISO = auxDate.format(dateFormatISO)
              let newDateData = this.cloneObject(baseDayData, { inRange: true })
              if (this.specialDates.has(auxDateISO)) {
                newDateData = this.cloneObject(this.specialDates.get(auxDateISO), { inRange: true })
              }
              newDateData.rangeStart = false
              newDateData.rangeEnd = false
              if (startISO === auxDateISO) {
                newDateData.rangeStart = true
              } else if (endISO === auxDateISO) {
                newDateData.rangeEnd = true
              }
              this.specialDates.set(auxDateISO, newDateData)
            } while (auxDateISO !== endISO)
          }
        } else if (this.selectedDates.length === 1) {
          const d1 = moment(this.selectedDates[0])
          const startISO = d1.format(dateFormatISO)
          if (this.specialDates.has(startISO)) {
            const newDateData = this.cloneObject(this.specialDates.get(startISO), { inRange: true })
            newDateData.rangeStart = true
            this.specialDates.set(startISO, newDateData)
          }
        }
      }
      return false
    },
    updateCalendar (newDate) {
      if (newDate === undefined) {
        newDate = this.displayDate
      }
      if (!newDate) {
        newDate = moment()
      } else if (typeof newDate === 'string') {
        newDate = moment(newDate)
      }
      newDate.locale(this.locale)
      this.updateSpecialDays()
      let isoDate = newDate.format(dateFormatISO)

      const calendarStart = newDate.clone().startOf('month').weekday(0)
      const calendarEnd = newDate.clone().endOf('month').weekday(6)
      const today = moment()

      const days = []
      let classes = ''
      let selected = null
      let disabled = null
      let dateData = null
      let dayColor = null
      let dayTooltip = null
      let hours = 0
      let minutes = 0
      let seconds = 0

      if (this.value && !Array.isArray(this.value) && (this.value.hours() > 0 || this.value.minutes() > 0 || this.value.seconds() > 0)) {
        hours = this.value.hours()
        minutes = this.value.minutes()
        seconds = this.value.seconds()
      }

      let day = calendarStart.clone().hours(hours).minutes(minutes).seconds(seconds)
      while (day.isSameOrBefore(calendarEnd, 'day')) {
        isoDate = day.format(dateFormatISO)
        classes = ''
        disabled = this.disableDays || this.isInMinMaxRange(day) === false
        selected = false
        dayColor = null
        dayTooltip = null
        if (this.specialDates.has(isoDate)) {
          dateData = this.specialDates.get(isoDate)
          selected = dateData.selected === true
          if (dateData.highlighted) {
            classes += ' is-hightlighted'
          }
          if (selected) {
            classes += ' is-selected'
          }
          if (dateData.color) {
            dayColor = dateData.color
          }
          if (dateData.tooltip) {
            dayTooltip = dateData.tooltip
          }
          disabled = dateData.disabled
          if (dateData.inRange) {
            if (dateData.rangeStart === true) {
              classes += ' range-start'
            } else if (dateData.rangeEnd === true) {
              classes += ' range-end'
            } else {
              classes += ' in-range'
            }
          }
        }
        if (!business.isWeekDay(day)) {
          classes += ' is-weekend'
        }
        if (!day.isSame(newDate, 'month')) {
          classes += ' is-adjacent'
        }
        if (day.isSame(today, 'day')) {
          classes += ' is-today'
        }
        const newDayData = {
          mnt: day,
          selected: selected,
          classes: classes.trim(),
          disabled: disabled,
          iso: isoDate,
          color: dayColor,
          tooltip: dayTooltip
        }

        days.push(newDayData)
        day = day.clone().add(1, 'day')
      }
      this.monthDates = days
    },
    isInMinMaxRange (dayMnt) {
      let inRange = true
      let minDate = null
      let maxDate = null
      if (this.minDate) {
        if (this.minDate instanceof Object) {
          minDate = this.minDate
        } else {
          minDate = this.getStringMoment(this.minDate)
        }
      }
      if (this.maxDate) {
        if (this.maxDate instanceof Object) {
          maxDate = this.maxDate
        } else {
          maxDate = this.getStringMoment(this.maxDate)
        }
      }
      if (minDate && minDate.isAfter(dayMnt, 'day')) {
        inRange = false
      }
      if (maxDate && maxDate.isBefore(dayMnt, 'day')) {
        inRange = false
      }
      return inRange
    },
    getStringMoment (strMnt) {
      switch (strMnt) {
        case 'yesterday':
          return moment().subtract(1, 'day').startOf('day')
        case 'tomorrow':
          return moment().add(1, 'day').startOf('day')
        default:
          return moment().startOf('day')
      }
    }
  }
}
</script>
