export default function modulesFunctionsPlugin (store) {
  store.getHeaderLocationID = function (response) {
    if (response && response.headers && 'location' in response.headers) {
      const tokens = response.headers.location.split('/')
      let objID = tokens[tokens.length - 1]
      if (objID.length === 0) {
        objID = tokens[tokens.length - 2]
      }
      return parseInt(objID)
    }
    return 0
  }
}
