<template functional>
  <span
    class="icon"
    :class="[
      props.bulmaClass ? 'has-text-' + props.bulmaClass : null,
      props.classes,
      {
        'is-medium': props.size === 'lg' || props.size === '2x',
        'is-large': props.size === '3x' || props.size === '4x'
      }
    ]"
    :style="{'color': props.color ? props.color : null}"
    v-tooltip="parent.$t(props.tooltipTranslationCode)"
  >
    <font-awesome-icon
      :icon="props.icon"
      :size="props.size"
    />
  </span>
</template>
<script>
export default {
  name: 'BulmaIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    bulmaClass: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    tooltipTranslationCode: {
      type: String,
      default: null
    }
  }
}
</script>
