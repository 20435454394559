<template functional>
  <div
    v-if="props.membershipData"
    v-tooltip="props.membershipData.name"
    class="tag notranslate"
    :class="{'has-text-white': !parent.isLightColor(props.membershipData.color), 'is-marginless': props.isMarginless}"
    :style="{backgroundColor: props.membershipData.color}"
    translate="no"
  >
    <abbr :title="props.membershipData.name">
      {{ props.membershipData.code }}
    </abbr>
  </div>
</template>
<script>
export default {
  name: 'MembershipTag',
  props: {
    membershipData: { type: Object, default: null },
    isMarginless: { type: Boolean, default: false }
  }
}
</script>
