import moment from 'moment'

export default {
  name: 'TimeKeeperMixin',
  methods: {
    formatTime (time) {
      const mnt = moment(time, 'HH:mm:ss')
      if (this.is24hFormat()) {
        return mnt.format('HH:mm')
      } else {
        return mnt.format('h:mm A')
      }
    },
    formatHours (hours) {
      if (hours === 0) {
        return '--'
      }
      return this.formatDuration(hours * 60 * 60 * 1000 * 1000000)
    },
    formatDuration (duration, asTime) {
      const dur = moment.duration(duration / 1000000)
      if (dur.asHours() >= 24) {
        const numHours = dur.asHours()
        const roundedHours = Math.floor(numHours)
        const numMinutes = Math.floor((numHours - roundedHours) * 60)
        return roundedHours + ':' + (numMinutes < 10 ? '0' + numMinutes : numMinutes)
      }
      let mins = Math.abs(Math.floor(dur.minutes())).toString()
      if (mins.length === 1) {
        mins = '0' + mins
      }
      return (dur.minutes() < 0 ? '-' : '') + Math.abs(Math.floor(dur.hours())) + ':' + mins
    },
    getElapsedTime (time, time2) {
      const mnt = moment(time, 'HH:mm:ss')
      if (time2 === undefined || time2 === null) {
        time2 = moment().day(mnt.day()).year(mnt.year()).month(mnt.month())
      } else {
        time2 = moment(time2, 'HH:mm:ss')
      }
      return this.formatDuration(moment.duration(time2.diff(mnt)))
    }
  }
}
