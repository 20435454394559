import rest from '../../rest'

const state = {
  subscriptionContents: null,
  subscriptionContentsLoaded: false
}

const SUBSCRIPTION_CONTENTS_LOADED = 'SUBSCRIPTION_CONTENTS_LOADED'
const SUBSCRIPTION_CONTENTS_CLEAR = 'SUBSCRIPTION_CONTENTS_CLEAR'

const mutations = {
  [SUBSCRIPTION_CONTENTS_LOADED] (state, subscriptionContents) {
    state.subscriptionContents = subscriptionContents
    state.subscriptionContentsLoaded = true
  },
  [SUBSCRIPTION_CONTENTS_CLEAR] (state) {
    state.subscriptionContents = null
    state.subscriptionContentsLoaded = false
  }
}

const requestSubscriptionContents = async () => {
  const apiResponse = await rest.apiGet('subscription/contents/')
  if (apiResponse.hasContent()) {
    return apiResponse.data
  }
  return null
}

const actions = {
  async reloadSubscriptionContents ({ commit }) {
    const contents = await requestSubscriptionContents()
    commit(SUBSCRIPTION_CONTENTS_LOADED, contents)
  },
  async loadSubscriptionContents ({ commit }) {
    if (state.subscriptionContentsLoaded === false) {
      const contents = await requestSubscriptionContents()
      commit(SUBSCRIPTION_CONTENTS_LOADED, contents)
    }
  },
  async clearSubscriptionContents ({ commit }) {
    commit(SUBSCRIPTION_CONTENTS_CLEAR)
  }
}

const streamingStore = {
  state,
  mutations,
  actions
}

export default streamingStore
