import moment from 'moment'

export default {
  name: 'KydemyTimePickerMixin',
  props: {
    value: { type: Object, default: moment().startOf('hour') },
    is24h: { type: Boolean, default: false },
    isUp: { type: Boolean, default: false },
    isRounded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
    size: { type: String, default: 'normal' },
    iconLeft: { type: [String, Array], default: 'clock' },
    displayDateFormat: { type: String, default: null }
  }
}
