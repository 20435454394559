import rest from '../../rest'

const REMITTANCE_ACCOUNTS = 'REMITTANCE_ACCOUNTS'

const state = {
  RemittanceAccounts: localStorage.getItem(REMITTANCE_ACCOUNTS) !== null ? JSON.parse(localStorage.getItem(REMITTANCE_ACCOUNTS)) : null,
  RemittanceAccountsLoaded: localStorage.getItem(REMITTANCE_ACCOUNTS) !== null
}

const actions = {
  async loadRemittanceAccounts ({
                                  commit,
                                  state
                                }, force) {
    if (!state.RemittanceAccountsLoaded || force === true) {
      state.RemittanceAccountsLoaded = false
      const apiResponse = await rest.apiGet('bank-accounts/creditor-accounts/')
      if (apiResponse.isOK()) {
        localStorage.setItem(REMITTANCE_ACCOUNTS, JSON.stringify(apiResponse.data))
        commit(REMITTANCE_ACCOUNTS)
      } else {
        localStorage.setItem(REMITTANCE_ACCOUNTS, null)
        state.RemittanceAccountsLoaded = false
      }
    }
  }
}

const mutations = {
  [REMITTANCE_ACCOUNTS] (state) {
    state.RemittanceAccounts = JSON.parse(localStorage.getItem(REMITTANCE_ACCOUNTS))
    state.RemittanceAccountsLoaded = true
  }
}

export default {
  state,
  getters: {},
  actions,
  mutations
}
