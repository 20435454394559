<template>
  <div
    v-if="isTimeKeeperRecorderEnabled"
    class="timekeeper-wrapper"
    :class="{'active': active}"
  >
    <div
      :class="{'show': active, 'in-break': inBreak}"
      class="timekeeper-background"
    />
    <div
      :class="{'timekeeper-button': !active, 'recorder-pane': active, 'animating': isAnimating, 'pending-recording': hasPendingRecording, 'in-break': inBreak}"
      class="kydemy timekeeper-recorder"
    >
      <transition-group
        name="groupfade"
        mode="out-in"
      >
        <button
          v-if="!active"
          :key="'timekeeper-button'"
          class="box-button"
          @click="active = true"
        >
          <span
            :class="{'has-text-info': !hasPendingRecording, 'has-text-warning': inBreak, 'has-text-danger': hasPendingRecording}"
            class="icon is-large"
          >
            <font-awesome-icon
              icon="business-time"
              size="2x"
            />
          </span>
          <span
            v-if="inBreak"
            class="break-label is-size-3 has-text-danger has-text-weight-bold"
          >
            {{ $t('member.timekeeper.in_break_label') }}
          </span>
        </button>
        <div
          v-else
          :key="'timekeeper-recorder-box'"
          class="timekeeper-recorder-box"
        >
          <nav class="level is-mobile top">
            <div class="level-left">
              <div class="level-item">
                <a
                  class="button is-info is-small"
                  @click="goToTimeRecords"
                >
                  <span class="icon">
                    <font-awesome-icon icon="list" />
                  </span>
                </a>
              </div>
              <div class="level-item">
                <span class="is-size-5 is-uppercase has-text-weight-bold has-text-grey">{{ todayTitle }}</span>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item close-buttons-item">
                <transition-group
                  name="buttonfade"
                  mode="out-in"
                >
                  <a
                    :key="'btn_close_time_recorder'"
                    class="icon has-text-info close-tasks-list"
                    @click="active = false"
                  >
                    <font-awesome-icon
                      size="lg"
                      icon="times-circle"
                    />
                  </a>
                </transition-group>
              </div>
            </div>
          </nav>
          <div
            v-if="dayRecord"
            class="contents is-relative p-t-05 p-1"
          >
            <transition
              v-if="dayRecord.start_time === null"
              name="fade"
            >
              <div class="field has-text-centered">
                <button
                  class="button is-medium is-info"
                  @click="startWork"
                >
                  <span class="icon">
                    <font-awesome-icon icon="play" />
                  </span>
                  <span>{{ $t('member.timekeeper.start_work') }}</span>
                </button>
              </div>
            </transition>
            <template v-else>
              <template v-if="dayRecord.end_time === null">
                <div class="field has-text-centered">
                  <button
                    v-if="dayRecord.in_break === false"
                    class="button is-medium is-warning"
                    @click="pauseWork"
                  >
                    <span class="icon">
                      <font-awesome-icon icon="pause" />
                    </span>
                    <span>{{ $t('member.timekeeper.pause_work') }}</span>
                  </button>
                  <button
                    v-else
                    class="button is-medium is-warning"
                    @click="resumeWork"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['far', 'play-circle']" />
                    </span>
                    <span>{{ $t('member.timekeeper.resume_work') }}</span>
                  </button>
                </div>
                <div class="field has-text-centered">
                  <button
                    class="button is-medium is-danger"
                    @click="endWork"
                  >
                    <span class="icon">
                      <font-awesome-icon icon="stop" />
                    </span>
                    <span>{{ $t('member.timekeeper.end_work') }}</span>
                  </button>
                </div>
              </template>
            </template>
            <div class="has-border-with-shadow has-border-rounded is-clipped">
              <table
                v-if="dayRecord"
                class="table is-fullwidth is-striped has-border-rounded"
              >
                <tbody>
                  <tr>
                    <th>{{ $t('member.timekeeper.day_start') }}</th>
                    <td class="has-text-right">
                      {{ dayRecord.start_time ? formatTime(dayRecord.start_time) : '--' }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('member.timekeeper.day_end') }}</th>
                    <td class="has-text-right">
                      {{ dayRecord.end_time ? formatTime(dayRecord.end_time) : '--' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="is-middle">
                      <span>{{ $t('member.timekeeper.day_breaks') }}</span>
                      <transition
                        name="slideupdownsmall"
                      >
                        <button
                          v-if="dayRecord.start_time && dayRecord.end_time && editBreaks === false"
                          :key="'button-edit-breaks'"
                          class="button is-small is-info ml1"
                          @click="editBreaks = true"
                        >
                          <span class="icon"><font-awesome-icon icon="edit" /></span>
                        </button>
                      </transition>
                    </th>
                    <td class="has-text-right is-middle">
                      <span v-if="dayRecord.breaks_duration">
                        {{ formatDuration(dayRecord.breaks_duration) }}
                      </span>
                      <span
                        v-else
                        v-tooltip="$t('member.timekeeper.no_breaks')"
                        class="tag is-warning"
                      >
                        {{ dayRecord.expected_breaks ? (dayRecord.expected_breaks * 60) : '--' }}
                      </span>
                    </td>
                  </tr>
                  <transition name="fade">
                    <tr
                      v-if="dayRecord.start_time && dayRecord.end_time && editBreaks === true"
                      :key="'button-edit-breaks-row'"
                      class="edit-row"
                    >
                      <td>
                        <div class="buttons has-addons nowrap">
                          <button
                            class="button is-small is-warning"
                            @click="editBreaks = false"
                          >
                            <span class="icon"><font-awesome-icon icon="undo" /></span>
                          </button>
                          <button
                            class="button is-small is-success"
                            @click="saveBreaksEdit"
                          >
                            <span class="icon"><font-awesome-icon icon="save" /></span>
                          </button>
                        </div>
                      </td>
                      <td>
                        <KydemyTextInput
                          v-model="editBreaksValue"
                          :validation="$v.editBreaksValue"
                          type="number"
                          left-icon="pause"
                          size="small"
                        />
                      </td>
                    </tr>
                  </transition>
                  <tr v-if="dayRecord.end_time === null">
                    <th>{{ $t('member.timekeeper.day_hours') }}</th>
                    <td class="has-text-right">
                      {{ dayRecord.work_duration ? formatDuration(dayRecord.work_duration, true) + ' ' : '--' }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('member.timekeeper.day_effective_hours') }}</th>
                    <td class="has-text-right">
                      {{ dayRecord.end_time === null ? effectiveElapsedTime : formatDuration(dayRecord.work_duration) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <transition name="fade">
              <div
                v-if="dayRecord && dayRecord.start_time && dayRecord.end_time"
                class="buttons is-centered p-t-1"
              >
                <button
                  v-if="dayRecord.in_break === false"
                  class="button is-normal is-danger"
                  @click="unstopWorkConfirmation = true"
                >
                  <span class="icon">
                    <font-awesome-icon icon="undo" />
                  </span>
                  <span>{{ $t('member.timekeeper.unstop_work') }}</span>
                </button>
              </div>
            </transition>
            <div
              v-if="loading"
              class="loading-overlay"
            >
              <a class="button is-large is-loading" />
            </div>
          </div>
        </div>
      </transition-group>
      <ModalConfirmation
        :title="$t('member.timekeeper.confirm_unstop_title')"
        :content="$t('member.timekeeper.confirm_unstop_content')"
        :content-bold="$t('modal_confirmation.cannot_be_undone')"
        :show="unstopWorkConfirmation"
        :footer-buttons="[{icon: 'undo', class: 'is-warning', text: $t('member.timekeeper.confirm_unstop_button'),function: unstopWork}]"
        @modalCancel="unstopWorkConfirmation = false"
      />
      <ModalConfirmation
        :title="$t('member.timekeeper.confirm_logout_title')"
        :content="$t('member.timekeeper.confirm_logout_content')"
        :show="showLogOutConfirmationModal"
        :footer-buttons="[
          {icon: 'sign-out-alt', text: $t('member.timekeeper.confirm_logout_button_logout'),function: performLogout},
          {icon: 'pause', class: 'is-warning', text: $t('member.timekeeper.confirm_logout_button_pause'),function: pauseWorkAndLogout},
          {icon: 'stop', class: 'is-danger', text: $t('member.timekeeper.confirm_logout_button_stop'),function: stopWorkAndLogout}
        ]"
        :add-close-footer-button="false"
        @modalCancel="showLogOutConfirmationModal = false"
      />
    </div>
    <SnackBar />
  </div>
</template>
<script>
import SnackBar from './controls/SnackBar'
import moment from 'moment'
import TimeKeeperMixin from '../mixins/time-keeper-mixin'
import ModalConfirmation from './controls/ModalConfirmation'
import KydemyTextInput from './controls/KydemyTextInput'
import { required, minValue, maxValue, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'TimeKeeperRecorder',
  components: {
    KydemyTextInput,
    ModalConfirmation,
    SnackBar
  },
  mixins: [TimeKeeperMixin],
  data () {
    return {
      isTimeKeeperRecorderEnabled: false,
      loading: true,
      active: false,
      hasPendingRecording: false,
      isAnimating: false,
      dayRecord: null,
      unstopWorkConfirmation: false,
      editBreaks: false,
      editBreaksValue: 0,
      firstTimeActive: false,
      showLogOutConfirmationModal: false
    }
  },
  computed: {
    inBreak () {
      return this.dayRecord && this.dayRecord.in_break === true
    },
    todayTitle () {
      return moment().format('dddd D, MMMM')
    },
    todayISO () {
      return moment().format('YYYY-MM-DD')
    },
    effectiveElapsedTime () {
      if (this.dayRecord.breaks_duration !== null) {
        return this.formatDuration(this.dayRecord.work_duration - this.dayRecord.breaks_duration)
      } else {
        return this.formatDuration(this.dayRecord.work_duration - this.dayRecord.expected_breaks * 60 * 60 * 1000000000)
      }
    }
  },
  watch: {
    editBreaks () {
      let breaksVal = 0
      if (this.dayRecord.breaks_duration && this.dayRecord.breaks_duration > 0) {
        breaksVal = this.formatDuration(this.dayRecord.breaks_duration)
      } else if (this.$store.getters.timeKeeperSettings.break_hours > 0) {
        breaksVal = this.$store.getters.timeKeeperSettings.break_hours * 60
      }
      this.editBreaksValue = breaksVal
    },
    active (newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden'
        document.body.parentNode.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        document.body.parentNode.style.overflow = 'auto'
      }
    },
    '$store.getters.hasTimeKeeperSettings' (newVal, oldVal) {
      this.checkTimeRecorderEnabled()
      this.active = (oldVal === null && newVal === true && this.isTimeKeeperRecorderEnabled)
    },
    '$store.getters.hasSettings' () {
      if (this.isSettingEnabled('TIME_KEEPER_ENABLED')) {
        if (this.$store.getters.hasTimeKeeperSettings === null) {
          this.$store.dispatch('loadTimeKeeperSettings')
        }
        this.checkTimeRecorderEnabled()
      } else {
        this.checkTimeRecorderEnabled()
      }
    },
    accountID (newVal) {
      if (newVal) {
        this.checkTimeRecorderEnabled()
      }
    }
  },
  mounted () {
    this.$root.$on('logout', this.handleLogout)
    if (this.$eventHub) {
      this.$eventHub.$on('keyEscape', () => { this.active = false })
    }
    this.checkTimeRecorderEnabled()
    const self = this
    window.addEventListener('beforeunload', (event) => {
      if (self.canLogOut() === false) {
        if (this.isTimeKeeperRecorderEnabled) {
          this.showLogOutConfirmationModal = true
        }
        event.preventDefault()
        event.returnValue = self.$t('member.timekeeper.confirm_logout_content')
      }
    })
  },
  methods: {
    handleLogout () {
      if (this.canLogOut()) {
        this.performLogout()
      } else if (this.isTimeKeeperRecorderEnabled) {
        this.showLogOutConfirmationModal = true
      }
    },
    pauseWorkAndLogout () {
      const self = this
      this.pauseWork().then(() => {
        self.performLogout()
      })
    },
    stopWorkAndLogout () {
      const self = this
      this.endWork().then(() => {
        self.performLogout()
      })
    },
    performLogout () {
      this.showLogOutConfirmationModal = false
      this.dayRecord = null
      this.editBreaks = false
      this.firstTimeActive = false
      this.$store.dispatch('logout')
    },
    checkTimeRecorderEnabled () {
      this.isTimeKeeperRecorderEnabled = this.isSettingEnabled('TIME_KEEPER_ENABLED') && this.$store.getters.isTimeKeeperEnabled && this.$store.getters.isImpersonating === false
      if (this.isTimeKeeperRecorderEnabled && this.dayRecord === null) {
        this.loadTimeKeeperDayRecord()
      }
    },
    loadTimeKeeperDayRecord () {
      if (this.accountID === null) {
        return
      }
      const self = this
      self.dayRecord = null
      self.loading = true
      self.getAxiosRequest(`staff/${this.accountID}/timekeeper/record/${self.todayISO}/?offset=${moment().utcOffset()}`).then(
        response => {
          if (response.status === 200) {
            self.dayRecord = response.data
          } else if (response.status === 204) {
            self.dayRecord = {
              account_id: self.accountID,
              date: self.todayISO,
              start_time: null,
              end_time: null,
              work_duration: 0,
              breaks_duration: 0,
              extra_time_duration: 0
            }
          }
          self.loading = false
        }
      ).catch(
        () => {
          self.dayRecord = null
          self.loading = false
        }
      )
    },
    updateTimeKeeperDayRecord (action, successMessage, breaksVal) {
      const self = this
      self.loading = true
      return new Promise((resolve) => {
        let url = `staff/${this.accountID}/timekeeper/record/${self.todayISO}/${action}/?offset=${moment().utcOffset()}`
        if (action === 'breaks') {
          url += '&breaks=' + breaksVal
        }
        self.putAxiosRequest(url).then(
          response => {
            SnackBar.show(successMessage)
            if (response.status === 200) {
              self.dayRecord = response.data
              self.loading = false
              self.$forceUpdate()
              self.$eventHub.$emit('timekeeper')
            } else {
              self.loadTimeKeeperDayRecord()
            }
            resolve()
          }
        ).catch(
          () => {
            self.loading = false
            self.loadTimeKeeperDayRecord()
            SnackBar.error(self.$t('member.timekeeper.error_updating'))
            resolve()
          }
        )
      })
    },
    startWork () {
      return this.updateTimeKeeperDayRecord('start', this.$t('member.timekeeper.work_started'))
    },
    pauseWork () {
      return this.updateTimeKeeperDayRecord('pause', this.$t('member.timekeeper.work_paused'))
    },
    resumeWork () {
      return this.updateTimeKeeperDayRecord('resume', this.$t('member.timekeeper.work_resumed'))
    },
    unstopWork () {
      return this.updateTimeKeeperDayRecord('unstop', this.$t('member.timekeeper.work_resumed'))
    },
    endWork () {
      return this.updateTimeKeeperDayRecord('stop', this.$t('member.timekeeper.work_ended'))
    },
    saveBreaksEdit () {
      this.$v.editBreaksValue.$touch()
      if (this.$v.editBreaksValue.$invalid === false) {
        this.updateTimeKeeperDayRecord('breaks', this.$t('member.timekeeper.breaks_updated'), this.editBreaksValue)
        this.editBreaks = false
      }
    },
    goToTimeRecords () {
      if ('isMetaKydemy' in this && this.isMetaKydemy === true) {
        this.$router.push({
          name: 'account-timekeeper',
          params: { id: this.accountID }
        })
      } else {
        this.$router.push({
          name: 'member-details',
          params: { id: this.accountID },
          query: { tab: 'timekeeper' }
        })
      }
      this.active = false
    },
    canLogOut () {
      return this.isTimeKeeperRecorderEnabled === false || (this.dayRecord === null || (this.dayRecord.end_time !== null || this.dayRecord.start_time === null))
    }
  },
  validations: {
    editBreaksValue: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(60 * 12)
    }
  }
}
</script>
