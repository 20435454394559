import rest from '../../rest'

const STORAGE_KEY = 'STYLES'
const STYLES_LOADED = 'STYLES_LOADED'
const STYLES_CLEAR = 'STYLES_CLEAR'

const state = {
  styles: localStorage.getItem(STORAGE_KEY) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY)),
  stylesLoaded: localStorage.getItem(STORAGE_KEY) !== null,
  loadingStyles: false
}

const mutations = {
  [STYLES_LOADED] (state) {
    const stylesDataStr = localStorage.getItem(STORAGE_KEY)
    if (stylesDataStr && stylesDataStr.length > 0) {
      state.stylesLoaded = true
      state.styles = JSON.parse(stylesDataStr)
    } else {
      state.stylesLoaded = false
      state.styles = null
    }
    state.loadingStyles = false
  },
  [STYLES_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY)
    state.stylesLoaded = false
    state.styles = null
  }
}

const actions = {
  async getStyles ({ commit }) {
    if (state.loadingStyles === false && state.stylesLoaded === false) {
      state.loadingStyles = true
      commit(STYLES_CLEAR)
      const apiResponse = await rest.apiGet('classes/styles/')
      if (apiResponse.hasContent()) {
        const sortedStyles = apiResponse.data.sort((a, b) => a.name.localeCompare(b.name))
        localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedStyles))
        commit(STYLES_LOADED)
      } else {
        localStorage.removeItem(STORAGE_KEY)
        commit(STYLES_LOADED)
      }
    }
  },
  async clearStyles ({ commit }) {
    commit(STYLES_CLEAR)
  }
}
export default {
  state,
  mutations,
  actions
}
