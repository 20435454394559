import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import Login from '@/components/Login'

Vue.use(Router)

const router = new Router({
  /* mode: 'history', */
  routes: [
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        title: 'page_titles.login',
        requiresAuth: false
      }
    },
    {
      name: 'forgot-password',
      path: '/forgot-password',
      component: () => import(/* webpackChunkName: "kydemy-forgot-password" */ './components/ForgotPassword'),
      meta: {
        title: 'page_titles.forgot_password',
        requiresAuth: false
      }
    },
    {
      name: 'registration',
      path: '/registration',
      component: () => import(/* webpackChunkName: "kydemy-registration" */ './components/student/StudentRegistration'),
      meta: {
        title: 'config.registrations.title',
        requiresAuth: false
      }
    },
    {
      name: 'registration-validation',
      path: '/registration/activate',
      component: () => import(/* webpackChunkName: "kydemy-registration" */ './components/student/StudentRegistrationValidation'),
      meta: {
        title: 'page_titles.registration_validation',
        requiresAuth: false
      }
    },
    {
      name: 'notification-settings',
      path: '/notifications',
      component: () => import(/* webpackChunkName: "kydemy-registration" */ './components/student/StudentNotifications'),
      meta: {
        title: 'page_titles.student_notifications',
        requiresAuth: false
      }
    },
    {
      name: 'registration-confirmation',
      path: '/registration/confirmation',
      component: () => import(/* webpackChunkName: "kydemy-registration" */ './components/student/StudentRegistrationConfirmation'),
      meta: {
        title: 'page_titles.registration_confirmation',
        requiresAuth: false
      }
    },
    {
      name: 'registration-filter',
      path: '/registration/:alias',
      component: () => import(/* webpackChunkName: "kydemy-registration" */ './components/student/StudentRegistration'),
      meta: {
        title: 'config.registrations.title',
        requiresAuth: false
      }
    },
    {
      name: 'wizard',
      path: '/kydemy/wizard',
      component: () => import('./components/wizard/Wizard'),
      meta: {
        title: 'page_titles.wizard',
        requiresAuth: true,
        requiresRole: 'admin'
      }
    },
    {
      name: 'admin-overview',
      path: '/kydemy',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyOverview'),
      meta: {
        title: 'page_titles.kydemy',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    }, {
      name: 'students',
      path: '/kydemy/members',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyMembers'),
      meta: {
        title: 'page_titles.accounts',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    }, {
      name: 'registrations',
      path: '/kydemy/members/registrations',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyRegistrations'),
      meta: {
        title: 'page_titles.registrations',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'member-details',
      path: '/kydemy/members/:id',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyMemberDetails'),
      meta: {
        title: 'page_titles.account_details',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'classes-list',
      path: '/kydemy/classes',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyClasses'),
      meta: {
        title: 'page_titles.classes',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'class-details',
      path: '/kydemy/classes/:id',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyClassDetails'),
      meta: {
        title: 'page_titles.class_details',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'finances',
      path: '/kydemy/finances',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyFinances'),
      meta: {
        title: 'page_titles.finances',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'messages',
      path: '/kydemy/messages',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyMessages'),
      meta: {
        title: 'page_titles.messages',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'events',
      path: '/kydemy/events',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyEvents'),
      meta: {
        title: 'page_titles.events',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'event-details',
      path: '/kydemy/events/:id',
      component: () => import(/* webpackChunkName: "kydemy-manager" */ './components/admin/KydemyEventEditor'),
      meta: {
        title: 'page_titles.event_details',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'configuration',
      path: '/kydemy/config',
      component: () => import(/* webpackChunkName: "kydemy-admin" */ './components/admin/KydemyConfig'),
      meta: {
        title: 'page_titles.configuration',
        requiresAuth: true,
        requiredRole: 'admin'
      }
    },
    {
      name: 'import-students',
      path: '/kydemy/config/import/students',
      component: () => import(/* webpackChunkName: "kydemy-admin" */ './components/admin/KydemyImportWizard'),
      meta: {
        title: 'page_titles.import_students',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    {
      name: 'reports',
      path: '/kydemy/reports',
      component: () => import(/* webpackChunkName: "kydemy-reports" */ './components/admin/KydemyReports'),
      meta: {
        title: 'page_titles.reports',
        requiresAuth: true,
        requiredRole: 'manager'
      }
    },
    // STUDENT
    {
      name: 'landing',
      path: '/landing',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentLanding'),
      meta: {
        title: 'page_titles.kydemy',
        requiresAuth: true,
        requiredRole: 'student'
      }
    },
    {
      name: 'student-classes',
      path: '/classes',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentClasses'),
      meta: {
        title: 'page_titles.classes',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-grades',
      path: '/student/grades',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentGrades.vue'),
      meta: {
        title: 'page_titles.grades',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-documents',
      path: '/student/documents',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentDocumentsAndSignatures.vue'),
      meta: {
        title: 'documents.documents',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'sign-document',
      path: '/student/documents/sign',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/SignDocument.vue'),
      props: true,
      meta: {
        title: 'documents.documents',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-class',
      path: '/classes/:id',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentClass'),
      meta: {
        title: 'page_titles.class_details',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-events',
      path: '/events',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/admin/KydemyEvents'),
      meta: {
        title: 'page_titles.events',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-event',
      path: '/events/:id',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentEvent'),
      meta: {
        title: 'page_titles.event_details',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-videos',
      path: '/videos',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentVideos'),
      meta: {
        title: 'page_titles.videos',
        requiresAuth: true,
        requiredRole: ['student', 'relative', 'admin', 'manager', 'teacher']
      }
    },
    {
      name: 'student-files',
      path: '/files',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentFiles'),
      meta: {
        title: 'page_titles.files',
        requiresAuth: true,
        requiredRole: ['student', 'relative', 'admin', 'manager', 'teacher']
      }
    },
    {
      name: 'student-payments',
      path: '/payments',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentPayments'),
      meta: {
        title: 'page_titles.student_payments',
        requiresAuth: true,
        requiredRole: 'student'
      }
    },
    {
      name: 'student-attendance',
      path: '/attendance',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentAttendance'),
      meta: {
        title: 'page_titles.student_attendance',
        requiresAuth: true,
        requiredRole: 'student'
      }
    },
    {
      name: 'student-profile',
      path: '/profile',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentProfile'),
      meta: {
        title: 'page_titles.profile',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'student-messages',
      path: '/messages',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentMessages'),
      meta: {
        title: 'page_titles.messages',
        requiresAuth: true,
        requiredRole: ['student', 'relative']
      }
    },
    {
      name: 'reset-password',
      path: '/reset-password',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentResetPassword'),
      meta: {
        title: 'page_titles.reset_password',
        requiresAuth: true,
        requiredRole: null
      }
    },
    {
      name: 'student-gallery',
      path: '/gallery',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StudentGallery'),
      meta: {
        title: 'page_titles.gallery',
        requiresAuth: true,
        requiredRole: ['student', 'teacher']
      }
    },
    // TEACHER
    {
      name: 'teacher-landing',
      path: '/teacher/landing',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/teacher/TeacherLanding'),
      meta: {
        title: 'page_titles.kydemy',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-messages',
      path: '/teacher/messages',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/student/StudentMessages'),
      meta: {
        title: 'page_titles.messages',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-classes',
      path: '/teacher/classes',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/teacher/TeacherClasses'),
      meta: {
        title: 'page_titles.classes',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-evaluations',
      path: '/teacher/evaluations',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/teacher/TeacherEvaluations'),
      meta: {
        title: 'page_titles.evaluations',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-events',
      path: '/teacher/events',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/admin/KydemyEvents'),
      meta: {
        title: 'page_titles.events',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-class',
      path: '/teacher/class/:id',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/teacher/TeacherClass'),
      meta: {
        title: 'page_titles.class_details',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    {
      name: 'teacher-student',
      path: '/teacher/students/:id',
      component: () => import(/* webpackChunkName: "kydemy-teacher" */ './components/teacher/TeacherStudent'),
      meta: {
        title: 'page_titles.account_details',
        requiresAuth: true,
        requiredRole: 'teacher'
      }
    },
    // RELATIVES
    {
      name: 'relative-landing',
      path: '/relative/landing',
      component: () => import(/* webpackChunkName: "kydemy-relative" */ './components/relative/RelativeLanding'),
      meta: {
        title: 'page_titles.kydemy',
        requiresAuth: true,
        requiredRole: 'relative'
      }
    },
    {
      name: 'relative-payments',
      path: '/relative/payments',
      component: () => import(/* webpackChunkName: "kydemy-relative" */ './components/relative/RelativePayments'),
      meta: {
        title: 'page_titles.student_payments',
        requiresAuth: true,
        requiredRole: 'relative'
      }
    },
    {
      name: 'relative-attendance',
      path: '/relative/attendance',
      component: () => import(/* webpackChunkName: "kydemy-relative" */ './components/relative/RelativeAttendance'),
      meta: {
        title: 'page_titles.relatives_attendance',
        requiresAuth: true,
        requiredRole: 'relative'
      }
    },
    {
      name: 'relative-student-profile',
      path: '/profile/:id',
      component: () => import(/* webpackChunkName: "kydemy-relative" */ './components/student/StudentProfile'),
      meta: {
        title: 'page_titles.relatives_profile',
        requiresAuth: true,
        requiredRole: 'relative'
      }
    },
    // STREAMING
    {
      name: 'streaming-session',
      path: '/online/:id',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StreamingSession'),
      meta: {
        title: 'page_titles.streaming_session',
        requiresAuth: true,
        requiredRole: null
      }
    },
    {
      name: 'class-streaming-session',
      path: '/classes/:classID/online/:id',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StreamingSession'),
      meta: {
        title: 'page_titles.streaming_session',
        requiresAuth: true,
        requiredRole: null
      }
    },
    {
      name: 'event-streaming-session',
      path: '/events/:eventID/online/:id',
      component: () => import(/* webpackChunkName: "kydemy-student" */ './components/student/StreamingSession'),
      meta: {
        title: 'page_titles.streaming_session',
        requiresAuth: true,
        requiredRole: null
      }
    },
    // LINK PAYMENT
    {
      name: 'link-payment',
      path: '/student/payments/links/:uuid/',
      component: () => import('./components/student/StudentLinkPayment'),
      meta: {
        title: 'page_titles.link_payment',
        requiresAuth: false
      }
    },
    {
      name: 'subscription-registration',
      path: '/subscriptions',
      component: () => import('./components/student/Subscriptions/StudentSubscriptionRegistration'),
      meta: {
        title: 'page_titles.subscription_registration',
        requiresAuth: false
      }
    },
    {
      name: 'subscriber-landing',
      path: '/subscription',
      component: () => import(/* webpackChunkName: "kydemy-subscriber" */ './components/subscriber/SubscriberLanding'),
      meta: {
        title: 'page_titles.subscriber_landing',
        requiresAuth: true,
        requiredRole: 'subscriber'
      }
    },
    {
      name: 'subscriber-configuration',
      path: '/subscription/config',
      component: () => import(/* webpackChunkName: "kydemy-subscriber" */ './components/subscriber/SubscriberConfiguration'),
      meta: {
        title: 'page_titles.subscriber_config',
        requiresAuth: true,
        requiredRole: 'subscriber'
      }
    },
    {
      name: 'subscriber-class',
      path: '/subscription/:id',
      component: () => import(/* webpackChunkName: "kydemy-subscriber" */ './components/subscriber/SubscriberClass'),
      meta: {
        title: 'page_titles.subscriber_class',
        requiresAuth: true,
        requiredRole: 'subscriber'
      }
    },
    // OTHER
    {
      path: '*',
      redirect: '/login'
    }
  ]
})

router.updatePageTitle = (route) => {
  let title = 'Kydemy'
  if (route.meta.title) {
    title += ' - ' + window.i18n.t(route.meta.title)
  }
  document.title = title
}

router.beforeEach((to, from, next) => {
  if (to.query && to.query.lang && to.query.lang.length === 2 && to.query.lang !== router.app.$i18n.locale.substring(0, 2)) {
    window.location.reload()
  }
  const redirectLogin = function (nextCallback) {
    console.log('REDIRECT LOGIN !!!!!!')
    nextCallback({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  }
  const continueNextRoute = function () {
    router.updatePageTitle(to)
    next()
  }
  if (to.meta.requiresAuth === true) {
    if (store.getters.hasAccountData) {
      const userRoles = store.getters.accountRoles
      if (to.meta.requiredRole !== null) {
        const redirectDefaultView = function (nextCallback) {
          let viewName = 'login'
          if (userRoles.indexOf('manager') >= 0 || userRoles.indexOf('admin') >= 0) {
            viewName = 'admin-overview'
          } else if (userRoles.indexOf('teacher') >= 0) {
            viewName = 'teacher-landing'
          } else if (userRoles.indexOf('relative') >= 0) {
            viewName = 'relative-landing'
          } else if (userRoles.indexOf('subscriber') >= 0) {
            viewName = 'subscriber-landing'
          } else {
            viewName = 'landing'
          }
          nextCallback({ name: viewName })
        }
        if (userRoles.indexOf('admin') >= 0 ||
          (to.meta.requiredRole instanceof Array && to.meta.requiredRole.filter(value => userRoles.indexOf(value) >= 0)) ||
          (typeof to.meta.requiredRole === 'string' && userRoles.indexOf(to.meta.requiredRole) >= 0)) {
          continueNextRoute()
        } else {
          redirectDefaultView(next)
        }
      } else {
        continueNextRoute()
      }
    } else {
      /* eslint no-console: "off" */
      console.log('!!!! NO HAS ACCOUNT DATA')
      redirectLogin(next)
    }
  } else {
    continueNextRoute()
  }
})

router.onError(error => {
  if (/loading chunk/i.test(error.message)) {
    /* eslint no-console: "off" */
    console.log('OUTDATED VUE BUILD, reloading.... if this problem persists you might need to rebuild the project')
    // NOTHING TO DO
    // window.location.reload(true)
  } else {
    /* eslint no-console: "off" */
    console.log('Unhanded error: ', error)
  }
})

export default router
