const state = {
  streamingClassesLoaded: false,
  streamingClasses: []
}

const STREAMING_CLASSES_LOADED = 'STREAMING_CLASSES_LOADED'

const mutations = {
  [STREAMING_CLASSES_LOADED] (state, streamingClasses) {
    state.streamingClassesLoaded = true
    state.streamingClasses = streamingClasses
  }
}
const actions = {
  updateNumStreamingClasses ({ commit }, numClasses) {
    if (numClasses === 0) {
      commit(STREAMING_CLASSES_LOADED, [])
    } else {
      this._vm.$eventHub.$emit('get-streaming-classes')
    }
  },
  updateStreamingClasses ({ commit }, classes) {
    commit(STREAMING_CLASSES_LOADED, classes)
  }
}

const streamingStore = {
  state,
  mutations,
  actions
}

export default streamingStore
