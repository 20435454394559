<template>
  <div />
</template>
<script>
import ErrorsMixin from '../../mixins/errors-mixin'
import Crisp from '../../mixins/crisp'

let i18n = null

export default {
  name: 'SnackBar',
  data () {
    return {
      showSnackBar: false
    }
  },
  watch: {
    show: function (value) {
      if (value === true) {
        this.showSnackBar = true
        const self = this
        window.setTimeout(function () {
          self.showSnackBar = false
          self.show = false
        }, this.duration)
      }
    }
  },
  beforeMount () {
    i18n = this.$i18n
  },
  showHtml (html, duration) {
    if (!duration) {
      duration = 3000
    }
    const snackBar = window.document.createElement('div')
    const snackBarInner = window.document.createElement('div')
    snackBar.classList.add('snackbar')
    snackBarInner.classList.add('snackbar-inner')
    snackBarInner.innerHTML = html
    snackBar.appendChild(snackBarInner)
    snackBar.appendChild(snackBarInner)
    window.document.body.appendChild(snackBar)
    snackBar.classList.add('is-active')
    return new Promise(resolve => {
      window.setTimeout(function () {
        snackBar.classList.add('hide')
        window.setTimeout(function () {
          window.document.body.removeChild(snackBar)
          resolve()
        }, 500)
      }, duration)
    })
  },
  async show (text, duration, extraClasses, showChat) {
    if (!duration) {
      duration = 3000
    }
    const snackBar = window.document.createElement('div')
    const snackBarInner = window.document.createElement('div')
    snackBar.classList.add('snackbar')
    snackBarInner.classList.add('snackbar-inner')
    snackBarInner.innerText = text
    snackBar.appendChild(snackBarInner)
    snackBar.appendChild(snackBarInner)
    window.document.body.appendChild(snackBar)
    snackBar.classList.add('is-active')
    if (extraClasses && extraClasses.length > 0) {
      snackBar.classList.add(extraClasses)
    }
    return new Promise(resolve => {
      window.setTimeout(function () {
        snackBar.classList.add('hide')
        window.setTimeout(function () {
          window.document.body.removeChild(snackBar)
          resolve()
        }, 500)
        if (showChat === true) {
          ErrorsMixin.methods.postFrontEndError(text)
          Crisp.methods.showErrorMessage(text)
        }
      }, duration)
    })
  },
  error: function (text, duration, showChat) {
    if (!duration) {
      duration = 5000
    }
    if (showChat === undefined) {
      showChat = true
    }
    return this.show(text, duration, 'is-danger', showChat)
  },
  showT (transCode, duration) {
    return this.showTP(transCode, null, duration)
  },
  showTP (transCode, transParams, duration) {
    return this.show(i18n.t(transCode, transParams), duration)
  },
  errorT (transCode, duration, showChat) {
    return this.error(i18n.t(transCode), duration, showChat)
  },
  errorTP (transCode, transParams, duration, showChat) {
    return this.error(i18n.t(transCode, transParams), duration, showChat)
  }
}
</script>
