import axios from 'axios'
import store from './store'
import config from './config'
import download from 'downloadjs'
import bowser from 'bowser'

export default {
  getRequestOptions (baseOptions, extraHeaders) {
    if (!baseOptions) {
      baseOptions = { headers: null }
    }
    let buildVersion = config.BuildVersion
    if (process.env.NODE_ENV === 'development') {
      buildVersion = 'dev'
    } else if (process.env.NODE_ENV === 'test') {
      buildVersion = 'test'
    }
    const browserDetails = bowser.getParser(navigator.userAgent)
    const newHeaders = {
      Accept: 'application/com.kydemy.' + buildVersion + '+json',
      'X-Kydemy-URL': window.location.pathname + window.location.hash + window.location.search,
      'X-Kydemy-Route': (window.__KYDEMY__ || window.Kydemy)?.$route.name,
      'X-Kydemy-Browser': browserDetails ? browserDetails.getBrowserName() + ' ' + browserDetails.getBrowserVersion() + ' @ ' + browserDetails.getOSName() : null
    }
    if (localStorage) {
      const token = localStorage.getItem('token')
      const accId = localStorage.getItem('account_id')
      if (token !== null && token.length > 0) {
        newHeaders.Authorization = 'KYDEMY token="' + token + '", account_id="' + accId + '"'
      }
    }
    if (extraHeaders) {
      for (const key in extraHeaders) {
        if (key in extraHeaders) {
          newHeaders[key] = extraHeaders[key]
        }
      }
    }
    baseOptions.headers = newHeaders
    return baseOptions
  },
  postAxiosRequest (relUrl, data, extraHeaders, progressFnx) {
    const requestOptions = this.getRequestOptions({ onUploadProgress: progressFnx }, extraHeaders)
    return this.performRequest(axios.post, [config.API + '/api/' + relUrl, data, requestOptions])
  },
  getAxiosRequest: function (relUrl, options) {
    const requestOptions = this.getRequestOptions(options)
    const absUrl = relUrl.match(/^https?:/) === null ? config.API + '/api/' + relUrl : relUrl
    return this.performRequest(axios.get, [absUrl, requestOptions])
  },
  getAxiosFileBinary (relUrl) {
    return this.getAxiosRequest(relUrl, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.ms-excel'
      }
    })
  },
  getAxiosFile: function (relUrl, fileName) {
    return this.getAxiosRequest(relUrl, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.ms-excel'
      }
    }).then(
      (response) => {
        const fileNameHeader = 'x-suggested-filename'
        const suggestedFileName = response.headers[fileNameHeader]
        const effectiveFileName = (suggestedFileName === undefined ? fileName : suggestedFileName)
        download(response.data, effectiveFileName)
      }
    )
  },
  putAxiosRequest: function (relUrl, data) {
    const requestOptions = this.getRequestOptions()
    return this.performRequest(axios.put, [config.API + '/api/' + relUrl, data, requestOptions])
  },
  deleteAxiosRequest: function (relUrl) {
    const requestOptions = this.getRequestOptions()
    return this.performRequest(axios.delete, this.getRequestParams(relUrl, requestOptions))
  },
  getRequestParams (relURL, requestOptions, data) {
    const params = [config.API + '/api/' + relURL]
    if (data !== undefined) {
      params.push(data)
    }
    params.push(requestOptions)
    return params
  },
  async apiHead (relURL, options) {
    const requestOptions = this.getRequestOptions(options)
    return this.performRequestNew(axios.head, this.getRequestParams(relURL, requestOptions))
  },
  async apiGet (relURL, options) {
    const requestOptions = this.getRequestOptions(options)
    return this.performRequestNew(axios.get, this.getRequestParams(relURL, requestOptions))
  },
  async apiPost (relURL, data, extraHeaders, progressFnx) {
    const requestOptions = this.getRequestOptions({ onUploadProgress: progressFnx }, extraHeaders)
    return this.performRequestNew(axios.post, this.getRequestParams(relURL, requestOptions, data))
  },
  async apiPut (relURL, data) {
    const requestOptions = this.getRequestOptions()
    return this.performRequestNew(axios.put, this.getRequestParams(relURL, requestOptions, data))
  },
  async apiDelete (relURL) {
    const requestOptions = this.getRequestOptions()
    return this.performRequestNew(axios.delete, this.getRequestParams(relURL, requestOptions))
  },
  async performRequestNew (axiosFnx, fnxParams) {
    try {
      const response = await axiosFnx.apply(null, fnxParams)
      return new KydemyApiResponse(response)
    } catch (err) {
      return new KydemyApiResponse(err)
    }
  },
  performRequest (axiosFnx, fnxParams) {
    return new Promise(
      (resolve, reject) => {
        axiosFnx.apply(null, fnxParams).then(resolve).catch(
          err => {
            const errorCode = err.response && err.response.status ? err.response.status : 0
            if (errorCode === 401) {
              const authErr = err.response.headers && err.response.headers.authorization ? err.response.headers.authorization.toLowerCase() : ''
              if (authErr === 'expired') {
                store.dispatch('expiredClient')
              } else if (authErr === 'different') {
                store.dispatch('differentClient')
              } else if (window.location.hash === '#/login' || window.location.hash.includes('#/registration')) {
                reject(err)
              } else {
                store.dispatch('reloadKydemy')
              }
            } else if (errorCode === 410) {
              store.dispatch('outdatedClient')
            } else {
              reject(err)
            }
          }
        )
      }
    )
  },
  _getWsURI () {
    const token = localStorage.getItem('token')
    const accID = localStorage.getItem('account_id')
    return config.API.replace('http', 'ws') + '/api/ws/?token=' + token + '&account_id=' + accID
  },
  newWebSocket () {
    return new WebSocket(this._getWsURI())
  }
}

class KydemyApiResponse {
  constructor (values) {
    const response = values.response ? values.response : values
    let responseStatus = 418 // TEAPOT!
    if ('status' in response) {
      responseStatus = response.status
    }
    this.status = responseStatus
    this.data = response.data ? response.data : null
    this.error = 'stack' in values ? values : null
    this.headers = response.headers ? response.headers : null
  }

  isOK () {
    return this.status && this.status >= 200 && this.status < 300
  }

  isNotFound () {
    return this.status === 404
  }

  isError () {
    return this.error !== null || !this.isOK()
  }

  isErrorOrNotFound () {
    return this.isNotFound() || this.isError()
  }

  hasContent () {
    return this.isOK() && this.data !== null
  }

  getLocationID () {
    if (this.isOK() && this.headers && 'location' in this.headers) {
      const tokens = this.headers.location.split('/')
      let objID = tokens[tokens.length - 1]
      if (objID.length === 0) {
        objID = tokens[tokens.length - 2]
      }
      return parseInt(objID)
    }
    return 0
  }
}
