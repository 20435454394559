import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const validLangs = ['en', 'es', 'ca', 'fr']
const loadedLanguages = []
let i18n = null

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  const langCode = lang.split('-')[0].toLowerCase()
  const langMeta = document.createElement('meta')
  langMeta.setAttribute('http-equiv', 'content-language')
  langMeta.setAttribute('content', langCode)
  document.head.appendChild(langMeta)
  if (langCode === 'es') {
    const noTranslateMeta = document.createElement('meta')
    noTranslateMeta.setAttribute('name', 'google')
    noTranslateMeta.setAttribute('content', 'notranslate')
    document.head.appendChild(noTranslateMeta)
  }
  return lang
}

async function loadLocales (arrLocales) {
  if (arrLocales && arrLocales.length > 0) {
    await loadLanguageAsync(arrLocales.shift())
    loadLocales(arrLocales)
  }
}

function mergeTranslations (translations, replacements) {
  let auxVal = null
  for (const key in replacements) {
    auxVal = replacements[key]
    if (typeof auxVal === 'object') {
      translations[key] = mergeTranslations(translations[key], auxVal)
    } else if (Array.isArray(auxVal)) {
      translations[key] = auxVal
    } else {
      translations[key] = auxVal
    }
  }
  return translations
}

function loadLanguageAsync (lang) {
  if (!loadedLanguages.includes(lang)) {
    return import(/* webpackChunkName: "lang-[request]" */ `@/langs/${lang}`).then(msgs => {
      i18n.setLocaleMessage(lang, msgs.default)
      loadedLanguages.push(lang)
      setI18nLanguage(lang)
      const tokens = document.title.split('-')
      if (tokens.length > 1 && i18n.te(tokens[1].trim())) {
        document.title = i18n.t(tokens[1].trim())
      }
      if (i18n.KydemyConfiguration && i18n.KydemyConfiguration.i18n_replacements && lang in i18n.KydemyConfiguration.i18n_replacements) {
        const translations = i18n.getLocaleMessage(lang)
        const locRep = i18n.KydemyConfiguration.i18n_replacements[lang]
        const newTranslations = mergeTranslations(translations, locRep)
        i18n.setLocaleMessage(lang, newTranslations)
      }
      return lang
    })
  }
  return Promise.resolve(setI18nLanguage(lang))
}

let userLang = null
if (navigator.languages !== undefined && navigator.languages !== null && navigator.languages.length > 0) {
  for (let index = 0; userLang === null && index < navigator.languages.length; index++) {
    for (let langIndex = 0; userLang === null && langIndex < validLangs.length; langIndex++) {
      if (navigator.languages[index].toLowerCase().indexOf(validLangs[langIndex]) >= 0) {
        userLang = navigator.languages[index]
      }
    }
  }
}
if (userLang === null) {
  userLang = (navigator.language || navigator.userLanguage).toLowerCase()
}
if (window.location.hash && window.location.hash.length > 0 && window.location.hash.indexOf('?') >= 0) {
  const params = window.location.hash.split('?')[1].split('&')
  let param, key, value
  for (let index = 0; index < params.length; index++) {
    param = params[index]
    if (param.indexOf('=') > 0) {
      key = param.split('=')[0]
      value = param.split('=')[1]
      if (key === 'lang') {
        const newLang = value.trim().toLowerCase()
        if (validLangs.includes(newLang)) {
          userLang = newLang
          if (localStorage) {
            localStorage.setItem('forced_lang_code', newLang)
          }
        }
      }
    }
  }
} else if (localStorage && localStorage.getItem('forced_lang_code') !== null) {
  const newLang = localStorage.getItem('forced_lang_code')
  if (validLangs.includes(newLang)) {
    userLang = newLang
  }
}

let locale = 'en-US'
if (userLang.indexOf('es-') >= 0 || userLang === 'es') {
  locale = 'es-ES'
  require('moment/locale/es')
} else if (userLang.indexOf('ca-') >= 0 || userLang === 'ca') {
  locale = 'ca-ES'
  require('moment/locale/ca')
} else if (userLang.indexOf('fr-') >= 0 || userLang === 'fr') {
  locale = 'fr-FR'
  require('moment/locale/fr')
} else if (userLang.indexOf('en-') >= 0 || userLang === 'en') {
  locale = 'en-US'
}
const locales = [locale]
if (!locales.includes('en-US')) {
  locales.unshift('en-US')
}
loadLocales(locales)

window.i18nMissing = {}
const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'fr-FR': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'es-ES': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
}
numberFormats['ca-ES'] = numberFormats['es-ES']

i18n = new VueI18n({
  locale: locale,
  messages: null,
  missing: (locale, key, vm) => {
    if (window.i18nMissing[locale] === undefined) {
      window.i18nMissing[locale] = []
    }
    if (window.i18nMissing[locale].indexOf(key) < 0) {
      window.i18nMissing[locale].push(key)
    }
  },
  numberFormats: numberFormats
})
i18n.KydemyConfiguration = null

window.i18nMissing.toDictionary = function (locale) {
  let dicLog = ''
  for (let index = 0; index < window.i18nMissing[locale].length; index++) {
    dicLog += window.i18nMissing[locale][index] + ': \'\',\n'
  }
  /* eslint no-console: "off" */
  console.log(dicLog)
}
window.i18n = i18n

export default i18n
