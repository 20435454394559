<template>
  <div class="tasks-wrapper">
    <div
      class="tasks-background"
      :class="{'show': active}"
    />
    <div
      class="kydemy kydemy-tasks"
      :class="{'active': active,'tasks-button': !active, 'tasks-list': active, 'animating': isAnimating, 'has-tasks-pending': hasTasksPending}"
    >
      <transition-group
        name="groupfade"
        mode="out-in"
      >
        <button
          v-if="!active"
          :key="'tasks-button'"
          class="box-button"
          @click="active = true"
        >
          <span
            class="icon is-large"
            :class="{'has-text-primary': !hasTasksPending, 'has-text-danger': hasTasksPending}"
          >
            <font-awesome-icon
              icon="tasks"
              size="2x"
            />
          </span>
        </button>
        <div
          v-else
          :key="'tasks-list'"
          class="task-list-container"
        >
          <nav class="level is-mobile top-task-list">
            <div class="level-left">
              <div class="level-item">
                <div class="buttons">
                  <button
                    v-show="!selectedTask"
                    v-tooltip="$t('tasks.new_task')"
                    class="button is-small is-primary"
                    @click="createNewTask"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                </div>
              </div>
            </div>
            <div class="level-item">
              <h2 class="title is-size-5 has-text-grey-dark">
                {{ $t('tasks.title') }}
              </h2>
            </div>
            <div class="level-right">
              <div class="level-item close-buttons-item">
                <transition-group
                  name="buttonfade"
                  mode="out-in"
                >
                  <a
                    v-if="selectedTask === null"
                    :key="'btn_close_tasks'"
                    class="icon has-text-primary close-tasks-list"
                    @click="active = false"
                  >
                    <font-awesome-icon icon="times" />
                  </a>
                  <a
                    v-else
                    :key="'btn_close_task'"
                    class="icon has-text-primary close-tasks-list"
                    @click="cancelEditTask"
                  >
                    <font-awesome-icon icon="chevron-left" />
                  </a>
                </transition-group>
              </div>
            </div>
          </nav>
          <div class="tasks-global-content is-relative">
            <transition-group
              :name="selectedTask ? 'slide-left' : 'slide-right'"
              mode="in-out"
            >
              <div
                v-if="selectedTask === null"
                :key="'task-contents-list'"
              >
                <div class="task-list-content has-text-left is-relative">
                  <div
                    v-if="numVisibleTasks > 0"
                    class="panel"
                  >
                    <transition-group name="fade">
                      <a
                        v-for="task in filteredTasks"
                        :key="'task_'+task.task_id"
                        class="panel-block task-list-item is-size-7"
                        :class="['task_status_'+task.status]"
                        @click="selectedTask = task"
                      >
                        <span
                          v-tooltip="$t('tasks.priorities.'+task.priority)"
                          class="tag tag-priority is-uppercase"
                          :class="'priority_'+task.priority"
                        >
                          {{ task.priority.substring(0, 3) }}
                        </span>
                        <span class="is-ellipsis">
                          {{ getTaskTitle(task) }}
                        </span>
                        <span
                          class="icon tag-status"
                          :class="[getTaskIconColor(task)]"
                        >
                          <font-awesome-icon :icon="getTaskIcon(task)" />
                        </span>
                      </a>
                    </transition-group>
                  </div>
                  <div
                    v-else
                    class="p-1"
                  >
                    <div class="message">
                      <div class="message-body has-text-centered is-italic">
                        {{ $t('tasks.no_tasks') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="task-footer">
                  <div class="level is-mobile p-05">
                    <div class="level-left">
                      <div class="level-item p-t-02">
                        <label class="label is-size-6">
                          {{ $t('tasks.show_done') }}:
                        </label>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <switches
                          v-model="showDone"
                          theme="bulma"
                          color="blue"
                          :emit-on-mount="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="editMode"
                :key="'task-editor'"
                class="task-details"
              >
                <div class="has-text-left p-1 edit-task-content">
                  <div class="field">
                    <label class="label is-small">
                      {{ $t('tasks.task_title') }}
                    </label>
                    <KydemyTextInput
                      v-model="selectedTask.title"
                      :validation="$v.selectedTask.title"
                      :auto-focus="true"
                      left-icon="pen"
                      size="small"
                    />
                  </div>
                  <div class="field">
                    <label class="label is-small">
                      {{ $t('tasks.task_description') }}
                    </label>
                    <KydemyTextInput
                      v-model="selectedTask.description"
                      type="textarea"
                      :validation="$v.selectedTask.description"
                      left-icon="align-left"
                      size="small"
                    />
                  </div>
                  <div class="field">
                    <label class="label is-small">
                      {{ $t('tasks.task_priority') }}
                    </label>
                    <div class="control">
                      <div class="buttons has-addons is-flow-centered">
                        <button
                          v-for="(priority, key) in $t('tasks.priorities')"
                          :key="'priority_'+priority.toLowerCase()"
                          v-tooltip="priority"
                          class="button is-small is-rounded is-uppercase"
                          :class="{'is-info': selectedTask.priority === key}"
                          @click="selectedTask.priority = key"
                        >
                          {{ priority.substring(0, 3) }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label is-small">
                      {{ $t('tasks.task_assignee') }}
                    </label>
                    <div class="level">
                      <div class="level-item">
                        <div class="buttons has-addons">
                          <button
                            class="button is-small is-rounded"
                            :class="{'is-info': selectedTaskAssigneeRole}"
                            @click="selectedTaskAssigneeRole = true"
                          >
                            {{ $t('tasks.assignee_role') }}
                          </button>
                          <button
                            class="button is-small is-rounded"
                            :class="{'is-info': !selectedTaskAssigneeRole}"
                            @click="selectedTaskAssigneeRole = false"
                          >
                            {{ $t('tasks.assignee_member') }}
                          </button>
                        </div>
                      </div>
                      <div class="level-item is-flow-centered">
                        <transition-group
                          name="groupfadenew"
                          mode="out-in"
                          class="is-fullwidth"
                        >
                          <RoleSelector
                            v-if="selectedTaskAssigneeRole"
                            :key="'role-selector'"
                            v-model="selectedRoles"
                            :multiple="false"
                            size="small"
                            :disabled-roles="['student', 'relative', 'lead']"
                          />

                          <member-search-input
                            v-else-if="selectedAssignee === null"
                            :key="'assignee-selector'"
                            :search-mode="null"
                            :search-role="null"
                            class-mode="singles"
                            size="small"
                            :simple="true"
                            :is-rounded="true"
                            :is-floating-results="true"
                            search-members-u-r-l="messages/recipients/"
                            @onMemberSelected="onSelectMember"
                          />
                          <div
                            v-else
                            :key="'selected-assignee'"
                            class="box class-student is-paddingless"
                          >
                            <div class="columns is-marginless is-mobile">
                              <div class="column is-narrow p-05">
                                <figure class="image is-24x24 avatar">
                                  <img
                                    :alt="selectedAssignee.firstname"
                                    :src="selectedAssignee.avatar.thumbnail_url"
                                  >
                                </figure>
                              </div>
                              <div class="column is-middle p-05">
                                <span class="is-size-7">
                                  {{ selectedAssignee.firstname + ' ' + selectedAssignee.lastname }}
                                </span>
                              </div>
                              <div class="column is-middle p-05 is-narrow">
                                <a
                                  class="delete"
                                  @click="selectedAssignee = null"
                                />
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </div>
                    </div>
                    <transition name="slideupdown">
                      <span
                        v-if="$v.selectedTask.assigned_role.$error"
                        class="help has-text-danger has-text-centered"
                      >
                        {{ $t('validations.messages.select_role_error') }}
                      </span>
                    </transition>
                    <transition name="slideupdown">
                      <span
                        v-if="$v.selectedTask.assigned_account_id.$error"
                        class="help has-text-danger has-text-centered"
                      >
                        {{ $t('validations.messages.select_user_error') }}
                      </span>
                    </transition>
                  </div>
                  <div class="field">
                    <label class="label is-small">
                      {{ $t('tasks.task_deadline') }}
                    </label>
                    <div class="level">
                      <div class="level-item">
                        <div class="buttons has-addons">
                          <button
                            class="button is-small is-rounded"
                            :class="{'is-info': !selectedTaskHasDeadline}"
                            @click="disableTaskDeadLine"
                          >
                            {{ $t('tasks.no_deadline') }}
                          </button>
                          <button
                            class="button is-small is-rounded"
                            :class="{'is-info': selectedTaskHasDeadline}"
                            @click="enableTaskDeadLine"
                          >
                            {{ $t('tasks.select_deadline') }}
                          </button>
                        </div>
                      </div>
                      <div class="level-item is-flow-centered">
                        <p style="max-width: 10rem">
                          <KydemyDateTimePickerInput
                            v-model="selectedTask.complete_by"
                            :locale="getLangCode()"
                            :icon-left="['far', 'calendar-check']"
                            icon-right="angle-down"
                            :auto-close="true"
                            :is-up="true"
                            :is-right="true"
                            :disabled="!selectedTaskHasDeadline"
                            :min-date="today"
                            size="small"
                            format-pattern="D MMM YYYY"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="task-footer">
                  <div class="level is-mobile p-t-05">
                    <div class="level-item">
                      <button
                        class="button is-small is-warning is-rounded"
                        @click="cancelEditTask"
                      >
                        <span class="icon">
                          <font-awesome-icon icon="undo" />
                        </span>
                        <span>{{ $t('tasks.cancel_button') }}</span>
                      </button>
                    </div>
                    <div class="level-item">
                      <button
                        class="button is-small is-primary is-rounded"
                        @click="saveTask"
                      >
                        <span class="icon">
                          <font-awesome-icon icon="save" />
                        </span>
                        <span>{{ $t('tasks.save_task') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                :key="'task-contents-details'"
                class="task-details"
              >
                <div class="task-details-content">
                  <h3 class="title is-size-4 has-text-primary-dark ">
                    {{ getTaskTitle(selectedTask) }}
                  </h3>
                  <div
                    class="message"
                    :class="[getTaskDescriptionColor(selectedTask)]"
                  >
                    <div class="message-body">
                      {{ getTaskDescription(selectedTask) }}
                    </div>
                  </div>
                  <div class="field task-tags is-grouped is-grouped-multiline is-grouped-centered">
                    <div class="control">
                      <div class="tags has-addons">
                        <span
                          v-tooltip="$t('tasks.priority')"
                          class="tag is-dark is-uppercase"
                        >
                          <font-awesome-icon icon="certificate" />
                        </span>
                        <span
                          class="tag is-uppercase"
                          :class="'priority_'+selectedTask.priority"
                        >
                          {{ $t('tasks.priorities.' + selectedTask.priority) }}
                        </span>
                      </div>
                    </div>
                    <div class="control">
                      <div class="tags has-addons">
                        <span
                          v-tooltip="$t('tasks.assigned_to')"
                          class="tag is-dark is-uppercase"
                        >
                          <font-awesome-icon icon="user-check" />
                        </span>
                        <span class="tag is-uppercase is-info">
                          {{ getTaskAssignee(selectedTask) }}
                        </span>
                      </div>
                    </div>
                    <div class="control">
                      <div class="tags has-addons">
                        <span
                          v-tooltip="$t('tasks.status')"
                          class="tag is-dark is-uppercase"
                        >
                          <font-awesome-icon icon="pen" />
                        </span>
                        <span
                          class="tag is-uppercase"
                          :class="[getTaskDescriptionColor(selectedTask)]"
                        >
                          {{ $t('tasks.statuses.' + selectedTask.status) }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="selectedTask.complete_by"
                      class="control"
                    >
                      <div class="tags has-addons">
                        <span
                          v-tooltip="$t('tasks.complete_by')"
                          class="tag is-dark is-uppercase"
                        >
                          <font-awesome-icon icon="calendar-check" />
                        </span>
                        <span class="tag is-uppercase is-danger">
                          {{ selectedTask.complete_by | shortdate(getLangCode()) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="task-footer">
                  <p
                    v-if="selectedTask.status === 'done' && selectedTask.type === 'setup'"
                    class="is-size-7 p-t-03"
                  >
                    <span class="icon has-text-success">
                      <font-awesome-icon icon="check" />
                    </span>
                    <span>{{ $t('tasks.setup_task_done') }}</span>
                  </p>
                  <div
                    v-else
                    class="level is-mobile p-t-05"
                  >
                    <template v-if="selectedTask.type === 'custom'">
                      <div
                        v-if="selectedTask.status !== 'done' && hasRouteLink(selectedTask)"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-info is-rounded"
                          @click="clickSetupTask(selectedTask)"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="link" />
                          </span>
                          <span>{{ $t('tasks.fix_now') }}</span>
                        </button>
                      </div>
                      <div
                        v-else-if="selectedTask.status !== 'done'"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-info is-rounded"
                          @click="editTask(selectedTask)"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="edit" />
                          </span>
                          <span>{{ $t('tasks.edit_task') }}</span>
                        </button>
                      </div>
                      <div
                        v-if="selectedTask.status !== 'done'"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-success is-rounded"
                          @click="updateTaskStatus(selectedTask, 'done')"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="check" />
                          </span>
                          <span>{{ $t('tasks.mark_done') }}</span>
                        </button>
                      </div>
                      <div
                        v-if="selectedTask.status === 'done'"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-warning is-rounded"
                          @click="updateTaskStatus(selectedTask, 'pending')"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="undo" />
                          </span>
                          <span>{{ $t('tasks.mark_undone') }}</span>
                        </button>
                      </div>
                      <div
                        v-if="selectedTask.code === null"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-danger is-rounded"
                          @click="showDeleteTaskModal = true"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="trash" />
                          </span>
                          <span>{{ $t('tasks.delete_task') }}</span>
                        </button>
                      </div>
                    </template>
                    <template v-else-if="selectedTask.type === 'setup'">
                      <div
                        v-if="selectedTask.status !== 'done'"
                        class="level-item"
                      >
                        <button
                          class="button is-small is-info is-rounded"
                          @click="clickSetupTask(selectedTask)"
                        >
                          <span class="icon">
                            <font-awesome-icon icon="link" />
                          </span>
                          <span>{{ $t('tasks.fix_now') }}</span>
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </transition-group>
            <div
              v-if="loading"
              :key="'loading-overlay'"
              class="loading-overlay"
            >
              <a class="button is-large is-loading" />
            </div>
          </div>
        </div>
      </transition-group>
      <modal-confirmation
        v-if="showDeleteTaskModal"
        :show="showDeleteTaskModal"
        :title="$t('tasks.confirm_delete_task')"
        :content="$t('tasks.confirm_delete_content')"
        :content-bold="$t('modal_confirmation.cannot_be_undone')"
        :footer-buttons="[{icon: 'trash', class: 'is-danger', text: $t('tasks.delete_task'),function: deleteTask}]"
        @modalCancel="showDeleteTaskModal = false"
      />
    </div>
    <SnackBar />
    <div
      class="overlay-pending-tasks"
      :class="{show: showPendingTaskOverlay}"
    >
      <div class="box">
        <p class="is-size-4 has-text-weight-bold">
          {{ $t('tasks.pending_tasks') }}
        </p>
        <div class="arrow-right" />
        <button
          v-if="isAdmin()"
          class="delete"
          aria-label="close"
          @click="showPendingTaskOverlay = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Switches from 'vue-switches'
import KydemyTextInput from './controls/KydemyTextInput'
import { minLength, maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import RoleSelector from './admin/MemberDetailsPanes/RoleSelector'
import MemberSearchInput from './controls/MemberSearchInput'
import moment from 'moment'
import ModalConfirmation from './controls/ModalConfirmation'
import SnackBar from './controls/SnackBar'
import KydemyDateTimePickerInput from './controls/calendars/KydemyDateTimePickerInput'
import Crisp from '@/mixins/crisp'

const today = moment()

export default {
  name: 'KydemyTasks',
  mixins: [Crisp],
  components: {
    KydemyDateTimePickerInput,
    SnackBar,
    ModalConfirmation,
    MemberSearchInput,
    RoleSelector,
    KydemyTextInput,
    Switches
  },
  data () {
    return {
      showPendingTaskOverlay: false,
      showDeleteTaskModal: false,
      active: false,
      showDone: false,
      selectedTask: null,
      isAnimating: false,
      tasks: [],
      loading: true,
      loadTasksTimeOut: null,
      hasTasksPending: false,
      editMode: false,
      selectedTaskHasDeadline: false,
      selectedTaskAssigneeRole: true,
      selectedRoles: [],
      selectedAssignee: null,
      today: today
    }
  },
  computed: {
    numVisibleTasks () {
      if (!this.tasks || this.tasks.length === 0) {
        return 0
      } else if (this.showDone) {
        return this.tasks.length
      } else {
        return this.tasks.filter(t => t.status !== 'done').length
      }
    },
    filteredTasks () {
      return this.tasks.filter(t => t.status !== 'done' || this.showDone)
    }
  },
  watch: {
    active (newVal) {
      if (newVal) {
        this.showPendingTaskOverlay = false
        document.body.style.overflow = 'hidden'
        document.body.parentNode.style.overflow = 'hidden'
        this.hideChatButton()
      } else {
        document.body.style.overflow = 'auto'
        document.body.parentNode.style.overflow = 'auto'
        this.showChatButton()
      }
    },
    editMode (newVal) {
      if (this.loadTasksTimeOut !== null) {
        clearTimeout(this.loadTasksTimeOut)
      }
      if (newVal) {
        this.loadTasks()
      }
    },
    selectedTask () {
      const self = this
      self.isAnimating = true
      setTimeout(() => { self.isAnimating = false }, 600)
    },
    selectedRoles (newVal) {
      this.selectedTask.assigned_role = newVal[0]
    },
    selectedAssignee (selMember) {
      if (selMember) {
        this.selectedTask.assigned_account_id = selMember.account_id
      } else {
        this.selectedTask.assigned_account_id = null
      }
    },
    hasTasksPending (newVal) {
      if (newVal === true) {
        const item = sessionStorage.getItem('hidePendingTasks')
        if (item !== 'true') {
          this.showPendingTaskOverlay = true
          sessionStorage.setItem('hidePendingTasks', 'true')
        }
      }
    }
  },
  created () {
    this.loadTasks()
    if (this.$eventHub) {
      this.$eventHub.$on('update-tasks', this.loadTasks)
      this.$eventHub.$on('create-new-task', () => {
        this.active = true
        this.createNewTask()
      })
      this.$eventHub.$on('keyEscape', () => {
        this.showPendingTaskOverlay = false
        this.active = false
      })
      this.$eventHub.$on('show-tasks', () => {
        this.active = true
      })
    }
  },
  mounted () {
    document.getElementById('app').classList.add('has-tasks')
  },
  methods: {
    enableTaskDeadLine () {
      this.selectedTaskHasDeadline = true
      this.selectedTask.complete_by = moment().add(1, 'week')
    },
    disableTaskDeadLine () {
      this.selectedTaskHasDeadline = false
      this.selectedTask.complete_by = null
      this.$eventHub.$emit('expand-dropdown')
    },
    deleteTask () {
      const self = this
      self.loading = true
      self.deleteAxiosRequest(`tasks/${self.selectedTask.task_id}/`)
        .then(
          () => {
            self.selectedTask = null
            self.cancelEditTask()
            self.loadTasks()
            SnackBar.show(self.$t('tasks.task_deleted'))
          }
        )
        .catch(
          () => {
            self.loading = false
          }
        )
    },
    saveTask () {
      this.$v.selectedTask.$touch()
      if (this.$v.$invalid === false) {
        const self = this
        self.loading = true
        if (!self.selectedTask.task_id || self.selectedTask.task_id === 0) {
          self.postAxiosRequest('tasks/', self.selectedTask)
            .then(
              () => {
                self.cancelEditTask()
                self.loadTasks()
                SnackBar.show(self.$t('tasks.task_created'))
              }
            )
            .catch(
              () => {
                self.loading = false
              }
            )
        } else {
          self.putAxiosRequest(`tasks/${self.selectedTask.task_id}/`, self.selectedTask)
            .then(
              () => {
                self.cancelEditTask()
                self.loadTasks()
                SnackBar.show(self.$t('tasks.task_saved'))
              }
            )
            .catch(
              () => {
                self.loading = false
              }
            )
        }
      }
    },
    onSelectMember (member) {
      this.selectedAssignee = member
    },
    getTaskIconColor (task) {
      switch (task.status) {
        case 'done':
          return 'has-text-success'
        case 'pending':
          return 'has-text-warning'
        case 'overdue':
          return 'has-text-danger'
      }
    },
    getTaskDescriptionColor (task) {
      switch (task.status) {
        case 'done':
          return 'is-success'
        case 'pending':
          return 'is-warning'
        case 'overdue':
          return 'is-danger'
      }
    },
    getTaskIcon (task) {
      switch (task.status) {
        case 'done':
          return 'check'
        case 'pending':
          return 'clock'
        case 'overdue':
          return 'exclamation'
      }
    },
    getTaskTitle (task) {
      if (task.title) {
        return task.title
      } else if (task.code) {
        return this.$t('tasks.titles.' + task.code)
      }
    },
    getTaskDescription (task) {
      if (task.description) {
        return task.description
      } else if (task.code) {
        return this.$t('tasks.descriptions.' + task.code)
      }
    },
    getTaskAssignee (task) {
      if (task.assigned_role) {
        return this.$t('member.roles.' + task.assigned_role)
      } else if (task.assignee && task.assignee.firstname) {
        return this.getFullNameShort(task.assignee)
      }
    },
    clickSetupTask (task) {
      const routeParams = this.$t('tasks.routes.' + task.code)
      this.$router.push(routeParams).catch(() => { /* SAME ROUTE */ })
      this.selectedTask = null
      this.active = false
    },
    hasRouteLink (task) {
      const routeParams = this.$i18n.messages[this.$i18n.locale].tasks.routes[task.code]
      return routeParams !== null && routeParams !== undefined
    },
    loadTasks () {
      if (this.loadTasksTimeOut !== null) {
        clearTimeout(this.loadTasksTimeOut)
      }
      this.loadTasksTimeOut = null
      const self = this
      self.loading = true
      let selectedTaskID = null
      if (self.selectedTask) {
        selectedTaskID = self.selectedTask.task_id
      }
      self.getAxiosRequest('tasks/')
        .then(
          response => {
            self.tasks = response.data.map(t => {
              if (t.complete_by) {
                t.complete_by = moment(t.complete_by)
              }
              return t
            })
            self.loading = false
            self.hasTasksPending = self.tasks && self.tasks.length > 0 && self.tasks.filter(t => { return t.status !== 'done' && ['normal', 'high', 'maximum'].indexOf(t.priority) >= 0 }).length > 0
            self.loadTasksTimeOut = setTimeout(self.loadTasks, 5 * 60 * 1000)
            if (selectedTaskID && selectedTaskID > 0) {
              self.selectedTask = self.tasks.find(t => t.task_id === selectedTaskID)
            }
          }
        ).catch(
        () => {
          self.loading = false
        }
      )
    },
    editTask (task) {
      this.editMode = true
      this.selectedTask = task
      this.selectedTaskHasDeadline = task.complete_by !== null
    },
    updateTaskStatus (task, status) {
      const self = this
      self.loading = true
      self.putAxiosRequest(`tasks/${task.task_id}/status/`, { status: status })
        .then(
          () => {
            sessionStorage.setItem('hidePendingTasks', 'true')
            self.loadTasks()
          }
        )
        .catch(
          () => {
            self.loading = false
          }
        )
    },
    createNewTask () {
      this.editMode = true
      this.selectedTask = {
        task_id: 0,
        type: 'custom',
        priority: 'normal',
        code: null,
        title: '',
        assigned_role: null,
        assigned_account_id: null,
        description: '',
        status: 'pending',
        datetime: null,
        complete_by: null
      }
    },
    clearForm () {
      if (!this.selectedTask) {
        return
      }
      this.selectedTask.title = ''
      this.selectedTask.description = ''
      this.selectedTask.priority = 'normal'
      this.selectedTask.type = 'custom'
      this.selectedTask.title = ''
      this.selectedTask.task_id = 0
      this.selectedTask.code = null
      this.selectedTask.status = 'pending'
      this.selectedTask.assigned_role = null
      this.selectedTask.assigned_account_id = null
      this.selectedTask.complete_by = null
      this.selectedTask.datetime = null
      this.$v.$reset()
    },
    cancelEditTask () {
      this.selectedTask = null
      this.editMode = false
      this.clearForm()
    }
  },
  validations: {
    selectedTask: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5000)
      },
      assigned_role: {
        required: requiredIf(function () {
          return this.selectedTaskAssigneeRole === true
        })
      },
      assigned_account_id: {
        required: requiredIf(function () {
          return this.selectedTaskAssigneeRole === false
        })
      },
      assigned_account_role: {}
    }
  }
}
</script>
