import rest from '../../rest'
import qs from 'qs'

const state = {
  events: [],
  eventsLoaded: false,
  venues: [],
  venuesLoaded: false,
  eventTypes: [],
  eventTypesLoaded: false
}

const EVENTS_LOADED = 'EVENTS_LOADED'
const EVENTS_CLEAR = 'EVENTS_CLEAR'
const VENUES_LOADED = 'VENUES_LOADED'
const VENUES_CLEAR = 'VENUES_CLEAR'
const EVENT_TYPES_LOADED = 'EVENT_TYPES_LOADED'
const EVENT_TYPES_CLEAR = 'EVENT_TYPES_CLEAR'

const mutations = {
  [EVENTS_LOADED] (state, events) {
    state.eventsLoaded = true
    state.events = events
  },
  [EVENTS_CLEAR] (state) {
    state.eventsLoaded = false
    state.events = null
  },
  [VENUES_LOADED] (state, venues) {
    state.venuesLoaded = true
    state.venues = venues
  },
  [VENUES_CLEAR] (state) {
    state.venuesLoaded = false
    state.venues = null
  },
  [EVENT_TYPES_LOADED] (state, eventTypes) {
    state.eventTypesLoaded = true
    state.eventTypes = eventTypes
  },
  [EVENT_TYPES_CLEAR] (state) {
    state.eventTypesLoaded = false
    state.eventTypes = null
  }
}

const actions = {
  async loadEvents ({ commit }) {
    const apiResponse = await rest.apiGet('events/')
    if (apiResponse.hasContent()) {
      commit(EVENTS_LOADED, apiResponse.data)
    } else {
      commit(EVENTS_LOADED, [])
    }
  },
  async clearEvents ({ commit }) {
    commit(EVENTS_CLEAR)
  },
  async reloadEvents ({ dispatch }) {
    await dispatch('clearEvents')
    await dispatch('loadEvents')
  },
  async deleteEvent ({
                       commit,
                       dispatch
                     }, eventID) {
    await rest.deleteAxiosRequest('events/' + eventID + '/')
    await dispatch('reloadEvents')
  },
  async saveEvent ({
                     commit,
                     dispatch
                   }, newEvent) {
    let response = null
    if (newEvent.event_id !== null && newEvent.event_id > 0) {
      response = await rest.putAxiosRequest('events/' + newEvent.event_id + '/', newEvent)
    } else {
      response = await rest.postAxiosRequest('events/', newEvent)
    }
    await dispatch('reloadEvents')
    return response
  },
  async loadVenues ({ commit }) {
    const response = await rest.getAxiosRequest('venues/')
    if (response.status) {
      if (response.status === 200) {
        commit(VENUES_LOADED, response.data)
      } else if (response.status === 204) {
        commit(VENUES_LOADED, [])
      }
    }
  },
  async clearVenues ({ commit }) {
    commit(VENUES_CLEAR)
  },
  async reloadVenues ({ dispatch }) {
    await dispatch('clearVenues')
    await dispatch('loadVenues')
  },
  async deleteVenue ({
                       commit,
                       dispatch
                     }, venueID) {
    await rest.deleteAxiosRequest('venues/' + venueID + '/')
    await dispatch('reloadVenues')
  },
  async saveVenue ({
                     commit,
                     dispatch
                   }, {
                     venueData,
                     isLocation
                   }) {
    let response = null
    const urlToken = isLocation ? 'locations/' : 'venues/'
    if (venueData.venue_id !== null && venueData.venue_id > 0) {
      response = await rest.putAxiosRequest(urlToken + venueData.venue_id + '/', venueData)
    } else {
      response = await rest.postAxiosRequest(urlToken, venueData)
    }
    await dispatch('reloadVenues')
    return response
  },
  async loadEventTypes ({ commit }) {
    const response = await rest.getAxiosRequest('events/types/')
    if (response.status) {
      if (response.status === 200) {
        commit(EVENT_TYPES_LOADED, response.data)
      } else if (response.status === 204) {
        commit(EVENT_TYPES_LOADED, [])
      }
    }
  },
  async clearEventTypes ({ commit }) {
    commit(EVENT_TYPES_CLEAR)
  },
  async reloadEventTypes ({ dispatch }) {
    await dispatch('clearEventTypes')
    await dispatch('loadEventTypes')
  },
  async deleteEventType ({
                           commit,
                           dispatch
                         }, eventTypeID) {
    await rest.deleteAxiosRequest('events/types/' + eventTypeID + '/')
    await dispatch('reloadEventTypes')
  },
  async saveEventType ({
                         commit,
                         dispatch
                       }, newEventType) {
    let response = null
    if (newEventType.eventType_id !== null && newEventType.eventType_id > 0) {
      response = await rest.putAxiosRequest('events/types/' + newEventType.event_type_id + '/', newEventType)
    } else {
      response = await rest.postAxiosRequest('events/types/', newEventType)
    }
    await dispatch('reloadEventTypes')
    return response
  },
  async saveEventPicture (_, payload) {
    const extraHeaders = { 'Content-Type': 'multipart/form-data' }
    let imgID = null
    try {
      const response = await rest.postAxiosRequest('images/?' + qs.stringify(payload.params, { encodeValuesOnly: true }), payload.picture, extraHeaders)
      imgID = this.getHeaderLocationID(response)
    } catch (err) {}
    return imgID
  }
}
export default {
  state,
  mutations,
  actions
}
