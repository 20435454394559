import rest from '../../rest'
import ColorHash from 'color-hash'

const STORAGE_KEY = 'CLASSROOMS'
const STORAGE_KEY_LOCATIONS = 'LOCATIONS'
const CLASSROOMS_LOADED = 'CLASSROOMS_LOADED'
const CLASSROOMS_CLEAR = 'CLASSROOMS_CLEAR'
const LOCATIONS_CLEAR = 'LOCATIONS_CLEAR'
const ALL_LOCATIONS_KEY = 'ALL_LOCATIONS'
const ALL_LOCATIONS_LOADED = 'ALL_LOCATIONS_LOADED'

const colorHasher = new ColorHash({ lightness: 0.5 })

const state = {
  classrooms: localStorage.getItem(STORAGE_KEY) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY)),
  classroomsLoaded: localStorage.getItem(STORAGE_KEY) !== null,
  locations: localStorage.getItem(STORAGE_KEY_LOCATIONS) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY_LOCATIONS)),
  loadingClassrooms: false,
  isMultiLocation: localStorage.getItem(STORAGE_KEY_LOCATIONS) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY_LOCATIONS)).length > 1,
  allLocations: localStorage.getItem(ALL_LOCATIONS_KEY) === null ? null : JSON.parse(localStorage.getItem(ALL_LOCATIONS_KEY)),
  allLocationsLoaded: localStorage.getItem(ALL_LOCATIONS_KEY) !== null
}

const mutations = {
  [CLASSROOMS_LOADED] (state) {
    const classroomsDataStr = localStorage.getItem(STORAGE_KEY)
    if (classroomsDataStr && classroomsDataStr.length > 0) {
      state.classroomsLoaded = true
      state.classrooms = JSON.parse(classroomsDataStr)
    } else {
      state.classroomsLoaded = false
      state.classrooms = null
    }
    const locationsDataStr = localStorage.getItem(STORAGE_KEY_LOCATIONS)
    if (locationsDataStr && locationsDataStr.length > 0) {
      state.locations = JSON.parse(locationsDataStr)
      state.isMultiLocation = state.locations.length > 1
    } else {
      state.locations = null
      state.isMultiLocation = false
    }
    state.loadingClassrooms = false
  },
  [ALL_LOCATIONS_LOADED] (state) {
    const allLocationsDataStr = localStorage.getItem(ALL_LOCATIONS_KEY)
    if (allLocationsDataStr && allLocationsDataStr.length > 0) {
      state.allLocationsLoaded = true
      state.allLocations = JSON.parse(allLocationsDataStr)
    } else {
      state.allLocationsLoaded = false
      state.allLocations = null
    }
  },
  [CLASSROOMS_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY)
    state.classroomsLoaded = false
    state.classrooms = null
  },
  [LOCATIONS_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY_LOCATIONS)
    localStorage.removeItem(ALL_LOCATIONS_KEY)
    state.locations = null
    state.allLocations = null
    state.allLocationsLoaded = false
  }
}

const actions = {
  async getAllLocations ({ commit }) {
    const apiResponse = await rest.apiGet('locations/')
    if (apiResponse.hasContent()) {
      const locationData = apiResponse.data.map(loc => {
        const tokens = loc.name.split(' ')
        loc.code = ''
        for (const token of tokens) {
          loc.code += token.substring(0, 1).toUpperCase()
          if (loc.code.length >= 5) {
            break
          }
        }
        loc.code = loc.code.toUpperCase()
        loc.color = loc.color ? loc.color : colorHasher.hex(loc.code)
        return loc
      })
      localStorage.setItem(ALL_LOCATIONS_KEY, JSON.stringify(locationData))
      commit(ALL_LOCATIONS_LOADED)
    }
  },
  async getClassrooms ({ commit }) {
    if (state.loadingClassrooms === false && state.classroomsLoaded === false) {
      state.loadingClassrooms = true
      commit(CLASSROOMS_CLEAR)
      const apiResponse = await rest.apiGet('locations/classrooms/')
      if (apiResponse.hasContent()) {
        const sortedClassrooms = apiResponse.data.sort((a, b) => a.name.localeCompare(b.name))
        const locMap = new Map()
        for (const cr of sortedClassrooms) {
          if (cr.location && cr.location.enabled === true) {
            if (!locMap.has(cr.location.location_id)) {
              locMap.set(cr.location.location_id, cr.location)
            }
          }
        }
        const sortedLocations = Array.from(locMap.values()).sort((a, b) => a.name.localeCompare(b.name)).map(l => {
          const tokens = l.name.split(' ')
          l.code = ''
          for (const token of tokens) {
            l.code += token.substring(0, 1).toUpperCase()
            if (l.code.length >= 5) {
              break
            }
          }
          l.code = l.code.toUpperCase()
          l.color = l.color ? l.color : colorHasher.hex(l.code)
          return l
        })
        localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedClassrooms))
        localStorage.setItem(STORAGE_KEY_LOCATIONS, JSON.stringify(sortedLocations.filter(loc => loc.location_id > 0)))
        commit(CLASSROOMS_LOADED)
      } else {
        localStorage.removeItem(STORAGE_KEY)
        commit(CLASSROOMS_LOADED)
      }
    }
  },
  async clearClassrooms ({ commit }) {
    commit(CLASSROOMS_CLEAR)
  },
  async clearClassroomsAndLocations ({ commit }) {
    commit(LOCATIONS_CLEAR)
    commit(CLASSROOMS_CLEAR)
  }
}
export default {
  state,
  mutations,
  actions
}
