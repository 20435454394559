<template>
  <div
    class="panel member-search-input"
    :class="{'is-borderless p-0': isBorderless}"
  >
    <div
      class="panel-block input-wrapper"
      :class="{'is-simple': simple === true, 'is-borderless p-0': isBorderless, 'is-paddingless': isPaddingless}"
    >
      <p
        class="control has-icons-left has-icons-right"
        :class="{'is-loading': loadingResults, 'is-medium': size === 'medium', 'is-large': size === 'large', 'is-small': size === 'small'}"
      >
        <input
          ref="searchBox"
          class="input"
          type="text"
          :placeholder="$t('member.search.search')"
          :disabled="disabled"
          :autofocus="autoFocus"
          :class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-small': size === 'small', 'is-rounded': isRounded}"
          @input="searchMembers($event)"
          @keydown="onSearchMemberKeyDown"
        >
        <span class="icon is-medium is-left">
          <font-awesome-icon icon="user" />
        </span>
        <span
          v-if="!loadingResults"
          class="icon is-medium is-right"
        >
          <font-awesome-icon icon="search" />
        </span>
      </p>
      <a
        v-if="showDeleteButton"
        class="delete is-medium"
        @click="clearSearchMember"
      />
    </div>
    <div
      v-if="searchResults !== null && searchResults !== false"
      class="search-results"
      :class="{'is-absolute has-background-white has-border-with-shadow-strong': isFloatingResults, 'is-up': isUp}"
      :style="{zIndex: isFloatingResults ? 3 : 'unset'}"
    >
      <template v-if="searchResults.levelOk && searchResults.levelOk.length > 0">
        <div
          v-if="searchRole !== 'teacher'"
          class="panel-heading is-size-6"
        >
          {{ $t('member.search.meets_requirements') }}
        </div>
        <MemberSearchItem
          v-for="result in searchResults.levelOk"
          :key="result.account_id"
          :member="result"
          :search-mode="searchMode"
          :search-role="searchRole"
          @onMemberSelected="selectMember"
          @onClearSearch="clearSearchMember"
        />
      </template>
      <template v-if="searchResults.styleOk && searchResults.styleOk.length > 0">
        <div class="panel-heading is-size-6">
          {{ $t('member.search.insufficient_level') }}
        </div>
        <MemberSearchItem
          v-for="result in searchResults.styleOk"
          :key="result.account_id"
          :member="result"
          :search-mode="searchMode"
          :search-role="searchRole"
          :is-small="true"
          @onMemberSelected="selectMember"
          @onClearSearch="clearSearchMember"
        />
      </template>
      <template v-if="searchResults.other && searchResults.other.length > 0">
        <div class="panel-heading is-size-6">
          {{ $t('member.search.other') }}
        </div>
        <MemberSearchItem
          v-for="result in searchResults.other"
          :key="result.account_id"
          :member="result"
          :search-mode="searchMode"
          :search-role="searchRole"
          :is-small="true"
          @onMemberSelected="selectMember"
          @onClearSearch="clearSearchMember"
        />
      </template>
      <template v-if="searchResults && searchResults.length > 0">
        <MemberSearchItem
          v-for="result in searchResults"
          :key="result.account_id"
          :member="result"
          :search-mode="searchMode"
          :search-role="searchRole"
          :is-small="true"
          @onMemberSelected="selectMember"
          @onClearSearch="clearSearchMember"
        />
      </template>
    </div>
    <span
      v-else-if="searchResults === false"
      class="panel-block message"
      :class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-small': size === 'small'}"
    >
      <div class="message-body">
        {{ $t('member.search.no_results') }}
      </div>
    </span>
  </div>
</template>

<script>
import MemberSearchItem from './MemberSearchItem'
import qs from 'qs'
import axios from 'axios'

export default {
  components: { MemberSearchItem },
  props: {
    classID: {
      type: Number,
      default: null
    },
    searchMode: {
      type: String,
      default: null
    },
    searchRole: {
      type: String,
      default: null
    },
    classMode: {
      type: String,
      default: null
    },
    showDeleteButton: Boolean,
    size: {
      type: String,
      default: 'normal'
    },
    simple: Boolean,
    ignoreCurrent: Boolean,
    searchMembersURL: {
      type: String,
      default: null
    },
    excludeIDs: {
      type: Array,
      default: null
    },
    isRounded: Boolean,
    isFloatingResults: Boolean,
    isUp: Boolean,
    isBorderless: {
      type: Boolean,
      default: false
    },
    isPaddingless: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchActive: false,
      loadingResults: false,
      searchTimeout: null,
      cancelToken: null,
      searchResults: null,
      searchURL: null,
      effectiveExcludedIDs: []
    }
  },

  watch: {
    searchMode (newVal) {
      if (newVal !== null) {
        this.searchActive = true
        const self = this
        setTimeout(function () {
          if (self.$refs && self.$refs.searchBox) {
            self.$refs.searchBox.focus()
          }
        }, 100)
      }
    },
    searchResults (newVal) {
      this.$emit('searchResults', newVal)
    }
  },
  mounted () {
    if (this.autoFocus === true) {
      setTimeout(this.focus, 500)
    }
  },
  methods: {
    focus () {
      if (this.$refs.searchBox && this.$refs.searchBox.focus) {
        this.$refs.searchBox.focus()
      }
    },
    searchMembers (event) {
      this.waitAndSearchClassMembers(event.target.value, this.searchMode)
    },
    waitAndSearchClassMembers (search, searchMode) {
      this.loadingResults = false
      this.cancelSearch()
      this.searchTimeout = setTimeout(this.searchClassMembers, 500, search, searchMode)
    },
    cancelSearch () {
      if (this.cancelToken != null) {
        this.cancelToken.cancel()
      }
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout)
      }
      this.cancelToken = null
      this.searchTimeout = null
      this.loadingResults = false
      this.searchResults = null
    },
    searchClassMembers (search, searchMode) {
      this.cancelSearch()

      const CancelToken = axios.CancelToken
      this.cancelToken = CancelToken.source()
      const self = this
      const searchStr = search.trim()

      const clearSearch = function () {
        self.loadingResults = false
        self.cancelToken = null
        self.searchTimeout = null
      }
      if (searchStr.length >= 2) {
        this.loadingResults = true
        const axiosCancelToken = {
          cancelToken: self.cancelToken.token
        }
        if (self.classID !== null && !isNaN(self.classID)) {
          let classMode = self.classMode
          if (classMode === undefined) {
            classMode = 'couples'
          }
          const params = qs.stringify({
            search: searchStr,
            leader: searchMode === 'leader' || searchMode === 'student' || searchMode === 'teacher',
            follower: searchMode === 'follower' || searchMode === 'student' || searchMode === 'teacher',
            mode: classMode
          }, { encodeValuesOnly: true })
          let apiMethod = null
          if (this.searchRole === 'member') {
            apiMethod = 'members'
          } else if (this.searchRole === 'teacher') {
            apiMethod = 'teachers'
          }
          if (apiMethod !== null) {
            self.getAxiosRequest('classes/' + self.classID + '/' + apiMethod + '/?' + params, axiosCancelToken).then(
              response => {
                clearSearch()
                if (response.status === 204) {
                  self.searchResults = false
                } else {
                  self.processSearchResults(self.filterResults(response.data))
                }
              }).catch(
              () => {
                clearSearch()
              })
          }
        } else {
          const params = qs.stringify({
            search: searchStr,
            inactive: false,
            limit: 8
          }, { encodeValuesOnly: true })
          if (!this.searchMembersURL) {
            this.searchURL = 'members/advanced/'
          } else {
            this.searchURL = this.searchMembersURL
          }
          self.getAxiosRequest(this.searchURL + '?' + params, axiosCancelToken).then(
            response => {
              clearSearch()
              if (response.status === 204) {
                self.searchResults = false
              } else {
                self.searchResults = self.filterResults(response.data)
              }
            }
          ).catch(
            () => {
              clearSearch()
            })
        }
      }
    },
    filterResults (data) {
      if (data.results) {
        data = data.results
      }
      if (!this.excludeIDs) {
        this.effectiveExcludedIDs = []
      } else {
        this.effectiveExcludedIDs = this.excludeIDs
      }
      if (this.ignoreCurrent && this.ignoreCurrent === true) {
        this.effectiveExcludedIDs.push(this.accountID)
      }
      if (this.effectiveExcludedIDs.length > 0) {
        const newData = []
        let accID
        for (let index = 0; index < data.length; index++) {
          accID = data[index].account_id
          if (this.effectiveExcludedIDs.indexOf(accID) < 0) {
            newData.push(data[index])
          }
        }
        data = newData
      }
      if (data.length === 0) {
        return false
      }
      return data
    },
    processSearchResults (data) {
      let results = {
        levelOk: [],
        styleOk: [],
        other: []
      }
      if (data === false || data.length === 0) {
        results = false
      } else if (this.searchRole === 'teacher') {
        results.levelOk = data
      } else if (this.simple !== true) {
        let member = null
        for (let index = 0; index < data.length; index++) {
          member = data[index]
          if (member.style_ok === true) {
            if (member.level_ok === true) {
              results.levelOk.push(member)
            } else {
              results.styleOk.push(member)
            }
          } else {
            results.other.push(member)
          }
        }
      } else {
        results = data
      }
      this.searchResults = results
    },
    clearSearchMember (emmit) {
      this.searchActive = false
      this.searchResults = null
      this.$refs.searchBox.value = ''
      if (emmit !== false) {
        this.$emit('onClearSearch')
      }
    },
    selectMember (member) {
      this.$emit('onMemberSelected', member)
      this.clearSearchMember(false)
    },
    onSearchMemberKeyDown (ev) {
      if (ev.keyCode === 13 && this.searchResults !== null && this.searchResults.levelOk && this.searchResults.levelOk.length === 1) {
        this.selectMember(this.searchResults.levelOk[0])
      }
    }
  }
}
</script>
