<template>
  <div
    class="kydemy-datetime-picker-wrapper card"
    :class="{'is-fullwidth': isFullWidth}"
  >
    <div class="card-header calendar-header">
      <div class="card-header-title">
        <nav class="level level-year is-mobile">
          <div class="level-left">
            <a @click="prevYear">
              <span class="icon has-text-white">
                <font-awesome-icon icon="angle-left" />
              </span>
            </a>
          </div>
          <div class="level-item">
            <button
              :disabled="disabled"
              class="button is-primary"
              @click="selYear"
            >
              <span
                class="has-text-white"
              >{{ displayDate && displayDate.format ? displayDate.format('YYYY') : '--' }}</span>
            </button>
          </div>
          <div class="level-right">
            <a @click="nextYear">
              <span class="icon has-text-white">
                <font-awesome-icon icon="angle-right" />
              </span>
            </a>
          </div>
        </nav>
        <nav class="level level-month is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a @click="prevMonth">
                <span class="icon is-large has-text-white">
                  <font-awesome-icon
                    icon="chevron-circle-left"
                    size="lg"
                  />
                </span>
              </a>
            </div>
          </div>
          <div class="level-item">
            <button
              :disabled="disabled"
              class="button is-large is-primary"
              @click="selMonth"
            >
              <span class="title is-uppercase has-text-white is-hidden-mobile">
                {{ displayDate && displayDate.format ? displayDate.format('MMMM') : '--' }}
              </span>
              <span class="title is-uppercase has-text-white is-hidden-tablet">
                {{ displayDate && displayDate.format ? displayDate.format('MMM') : '---' }}
              </span>
            </button>
          </div>
          <div class="level-right">
            <div class="level-item">
              <a @click="nextMonth">
                <span class="icon is-large has-text-white">
                  <font-awesome-icon
                    icon="chevron-circle-right"
                    size="lg"
                  />
                </span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="card-content">
      <div class="content-wrapper is-relative">
        <transition-group
          name="groupfade2"
          mode="out-in"
        >
          <KydemyMonthCalendar
            v-show="displayMode==='day'"
            ref="monthCalendar"
            :key="'kydemy-month-calendar'"
            v-model="selectedDate"
            :locale="locale"
            :highlighted-days="highlightedDays"
            :disabled-days="disabledDays"
            :enabled-days="enabledDays"
            :range="range"
            :multiple="multiple"
            :selectable="selectable"
            :disabled="disabled"
            :only-selected-clickable="onlySelectedClickable"
            :display-month="displayMonth"
            :min-date="minDate"
            :max-date="maxDate"
            @displayDate="onDisplayDateUpdate"
          />
          <div
            v-show="displayMode==='month'"
            :key="'displayMode_month'"
            class="kydemy-month-picker"
          >
            <div class="columns is-multiline is-mobile is-variable is-1">
              <div
                v-for="(month,index) in displayMonths"
                :key="'month_'+index"
                class="column is-one-third is-month"
              >
                <button
                  :class="{'is-primary': month.mnt.month() === displayDate.month()}"
                  class="button is-white is-medium is-uppercase is-size-6"
                  :disabled="disabled"
                  @click="selectMonth(displayDate.clone().month(month.mnt.month()))"
                >
                  {{ month.name }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-show="displayMode==='year'"
            :key="'displayMode_year'"
            class="kydemy-year-picker"
          >
            <div class="columns is-variable is-1 is-multiline is-mobile">
              <div
                v-for="year in displayYears"
                :key="'year_'+year"
                class="column is-one-fifth is-year"
              >
                <button
                  :disabled="disabled"
                  :class="{'is-primary': displayDate && displayDate.year() === year }"
                  class="button is-white is-normal is-size-6"
                  @click="selectYear(year)"
                >
                  <span
                    v-if="year === -1"
                    class="icon"
                  >
                    <font-awesome-icon icon="arrow-circle-left" />
                  </span>
                  <span
                    v-else-if="year === 1"
                    class="icon"
                  >
                    <font-awesome-icon icon="arrow-circle-right" />
                  </span>
                  <span v-else>{{ year }}</span>
                </button>
              </div>
            </div>
          </div>
        </transition-group>
        <template v-if="timeEnabled">
          <hr class="pickers-separator">
          <KydemyTimePicker
            v-model="selectedDate"
            :is24h="is24h"
            :disabled="disabled"
            @displayDate="onDisplayDateUpdate"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import KydemyMonthCalendar from './KydemyMonthCalendar'
import moment from 'moment'
import KydemyMonthCalendarMixin from './KydemyMonthCalendarMixin'
import KydemyTimePicker from './KydemyTimePicker'

const today = moment().startOf('day')

export default {
  name: 'KydemyDateTimePicker',
  components: {
    KydemyTimePicker,
    KydemyMonthCalendar
  },
  mixins: [KydemyMonthCalendarMixin],
  props: {
    interactive: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    is24h: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      displayMode: 'day',
      displayDate: null,
      selectedDate: null,
      displayMonths: null,
      displayYears: null
    }
  },
  watch: {
    locale () {
      this.initCalendar()
    },
    value (newVal) {
      if (newVal) {
        if (Array.isArray(newVal)) {
          this.displayDate = newVal[0]
        } else {
          this.displayDate = newVal
        }
      }
      this.selectedDate = newVal
      this.$emit('input', newVal)
    },
    displayDate (newValue, oldValue) {
      if (newValue && typeof newValue === 'string') {
        this.displayDate = moment(newValue)
        return
      }
      this.$emit('displayDate', newValue)
      if (newValue && newValue.locale) {
        newValue.locale(this.locale)
        let isDifferentYear = false
        if (oldValue && newValue) {
          const oldValMnt = moment(oldValue)
          const newValueMnt = moment(newValue)
          isDifferentYear = oldValMnt.year() !== newValueMnt.year()
        }
        if (oldValue === null || isDifferentYear) {
          this.updateYearsList()
        }
      }
    },
    selectedDate (newVal) {
      this.$emit('input', newVal)
    },
    interactive (newVal) {
      if (newVal) {
        this.displayMode = 'year'
      }
    }
  },
  created () {
    this.initCalendar()
  },
  methods: {
    initCalendar () {
      moment.locale(this.locale)
      if (this.displayDate === null) {
        if (this.value) {
          this.displayDate = Array.isArray(this.value) ? this.value[0] : this.value
        } else {
          this.displayDate = today
        }
      }
      this.selectedDate = this.value
      let month = null
      let currentMonth = moment().startOf('year')
      this.displayMonths = Array.apply(0, Array(12)).map(function (_, i) {
        month = {
          index: i,
          name: currentMonth.format('MMMM'),
          short: currentMonth.format('MMM'),
          mnt: currentMonth
        }
        currentMonth = currentMonth.clone().add('1', 'month')
        return month
      })
      if (this.interactive) {
        this.displayMode = 'year'
      }
    },
    onDisplayDateUpdate (displayDate) {
      this.displayDate = displayDate
    },
    prevMonth () {
      this.$refs.monthCalendar.prevMonth()
    },
    nextMonth () {
      this.$refs.monthCalendar.nextMonth()
    },
    prevYear () {
      this.$refs.monthCalendar.prevYear()
    },
    nextYear () {
      this.$refs.monthCalendar.nextYear()
    },
    selYear () {
      this.displayMode = 'year'
    },
    selMonth () {
      this.displayMode = 'month'
    },
    updateSelectedDate (mnt) {
      this.$refs.monthCalendar.setDisplayDate(mnt)
    },
    selectMonth (mnt) {
      this.updateSelectedDate(mnt)
      this.displayMode = 'day'
    },
    selectYear (year) {
      if (!this.displayDate || !this.displayDate.year) {
        return
      }
      let currentYear = this.displayDate.year()
      if (year === -1) {
        currentYear -= 22
      } else if (year === 1) {
        currentYear += 22
      } else {
        currentYear = year
      }
      this.updateSelectedDate(this.displayDate.clone().year(currentYear))
      if (year > 1000) {
        if (this.interactive === true) {
          this.displayMode = 'month'
        } else {
          this.displayMode = 'day'
        }
      }
    },
    updateYearsList () {
      if (!this.displayDate || !this.displayDate.year) {
        return
      }
      const newYear = this.displayDate.year()
      const years = [-1]
      const maxYear = newYear + 11
      for (let index = newYear - 11; index <= maxYear; index++) {
        years.push(index)
      }
      years.push(1)
      this.displayYears = years
    }
  }
}
</script>
