<template functional>
  <footer class="footer powered-by has-secondary-font">
    <div
      v-if="!props.whiteLabel"
      class="container"
    >
      <div class="content has-text-centered is-size-7">
        <p>
          Powered by
        </p>
        <a href="https://www.kydemy.com">
          <img
            alt="Kydemy"
            src="../../assets/powered-by-kydemy.png"
          >
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterBar',
  props: { whiteLabel: { type: Boolean, default: true } }
}
</script>
