<template functional>
  <div
    :class="[
      props.bulmaClass ? 'is-' + props.bulmaClass : 'has-text-grey',
      props.bulmaTextClass ? 'has-text-' + props.bulmaTextClass : null,
      {[`is-${props.size}`]: props.size},
    ]"
    :style="{'max-width': props.maxWidth ? props.maxWidth : null}"
    class="message"
  >
    <div class="message-body">
      <div class="media">
        <div class="media-left">
          <span
            class="icon"
            :class="[props.bulmaClass ? 'has-text-' + props.bulmaClass : 'has-text-grey', {'is-large': !props.size || props.size !== 'small'}]"
          >
            <font-awesome-icon
              :icon="props.icon"
              :size="props.size === 'small' ? '2x' : '3x'"
            />
          </span>
        </div>
        <div class="media-content">
          <p
            :class="[{
              'is-size-65': props.size==='small', 'is-uppercase': props.isUppercase,
              'has-text-centered': props.textAlign === 'center','has-text-right': props.textAlign === 'right', 'has-text-left': props.textAlign === 'left'
            }, props.bulmaTextClass ? 'has-text-' + props.bulmaTextClass : null]"
            v-html="parent.$t(props.translationCode, props.translationParams)"
          />
          <p
            v-if="props.extraContent"
            :class="{
              'is-size-65': props.size==='small', 'is-uppercase': props.isUppercase,
              'has-text-centered': props.textAlign === 'center','has-text-right': props.textAlign === 'right', 'has-text-left': props.textAlign === 'left'
            }"
            v-html="props.extraContent"
          />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BulmaMessage',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    translationCode: {
      type: String,
      required: true
    },
    bulmaClass: {
      type: String,
      default: null
    },
    bulmaTextClass: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    isUppercase: {
      type: Boolean,
      default: true
    },
    textAlign: {
      type: String,
      default: 'center'
    },
    maxWidth: {
      type: String,
      default: null
    },
    extraContent: {
      type: String,
      default: null
    },
    translationParams: {
      type: Object,
      default: null
    }
  }
}
</script>
