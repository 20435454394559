import rest from '../../rest'

const STORAGE_KEY = 'TAX_RATES'
const TAX_RATES_LOADED = 'TAX_RATES_LOADED'
const TAX_RATES_CLEAR = 'TAX_RATES_CLEAR'

const state = {
  taxRates: localStorage.getItem(STORAGE_KEY) === null ? null : JSON.parse(localStorage.getItem(STORAGE_KEY)),
  taxRatesLoaded: localStorage.getItem(STORAGE_KEY) !== null,
  loadingTaxRates: false
}

const mutations = {
  [TAX_RATES_LOADED] (state) {
    const taxRatesDataStr = localStorage.getItem(STORAGE_KEY)
    if (taxRatesDataStr && taxRatesDataStr.length > 0) {
      state.taxRatesLoaded = true
      state.taxRates = JSON.parse(taxRatesDataStr)
    } else {
      state.taxRatesLoaded = false
      state.taxRates = null
    }
    state.loadingTaxRates = false
  },
  [TAX_RATES_CLEAR] (state) {
    localStorage.removeItem(STORAGE_KEY)
    state.taxRatesLoaded = false
    state.taxRates = null
  }
}

const actions = {
  async getTaxRates ({ commit }) {
    if (state.loadingTaxRates === false) {
      state.loadingTaxRates = true
      commit(TAX_RATES_CLEAR)
      const response = await rest.getAxiosRequest('payments/tax-rates/')
      if (response.status === 200) {
        const sortedTaxRates = response.data.sort((a, b) => a.name.localeCompare(b.name))
        localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedTaxRates))
        commit(TAX_RATES_LOADED)
      } else {
        localStorage.removeItem(STORAGE_KEY)
        commit(TAX_RATES_LOADED)
      }
    }
  },
  async clearTaxRates ({ commit }) {
    commit(TAX_RATES_CLEAR)
  }
}
export default {
  state,
  mutations,
  actions
}
