export default {
  name: 'KydemyMonthCalendarMixin',
  props: {
    value: { type: [Object, Array], default: null },
    locale: { type: String, default: null },
    highlightedDays: { type: Array, default: null },
    disabledDays: { type: Array, default: null },
    enabledDays: { type: Array, default: null },
    range: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    timeEnabled: { type: Boolean, default: false },
    onlySelectedClickable: { type: Boolean, default: false },
    displayMonth: { type: [Object, String], default: null },
    minDate: { type: [Object, String], default: null },
    maxDate: { type: [Object, String], default: null }
  }
}
