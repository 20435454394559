let config = null
if (process.env.NODE_ENV === 'production') {
  config = require('../config/prod.env').default
} else {
  config = require('../config/dev.env').default
}

config.HOST = window.location.hostname
config.PORT = config.PORT ? config.PORT : window.location.port
config.PROTOCOL = config.PROTOCOL ? config.PROTOCOL : window.location.protocol.replace(':', '')
export default {
  API: config.PROTOCOL + '://' + config.HOST + ':' + config.PORT,
  Host: config.HOST,
  Port: config.PORT,
  AppName: 'Kydemy',
  BuildVersion: config.BUILD,
  BuildMode: process.env.NODE_ENV
}
