const state = {
  roomState: 'new',
  canSendMic: false,
  canSendWebcam: false,
  webcamInProgress: false,
  canChangeWebcam: false,
  audioOnly: false,
  peersMap: new Map(),
  producers: [],
  producerMap: new Map(),
  consumerMap: new Map(),
  displayMode: 'grid',
  allStudentsMuted: false
}

// Kydemy Mutations
const PEER_MUTE = 'PEER_MUTE'
const MUTE_STUDENTS = 'MUTE_STUDENTS'
const PEER_HOST = 'PEER_HOST'
const PEER_MIRRORED = 'PEER_MIRRORED'
const CONNECTION_QUALITY = 'CONNECTION_QUALITY'
const ALLOW_STUDENT_DEBTS = 'ALLOW_STUDENT_DEBTS'
// MediaSoup Mutations
const SET_ROOM_STATE = 'SET_ROOM_STATE'
const SET_MEDIA_CAPABILITIES = 'SET_MEDIA_CAPABILITIES'
const SET_WEBCAM_IN_PROGRESS = 'SET_WEBCAM_IN_PROGRESS'
const SET_CAN_CHANGE_WEBCAM = 'SET_CAN_CHANGE_WEBCAM'
const SET_ROOM_ACTIVE_SPEAKER = 'SET_ROOM_ACTIVE_SPEAKER'
const ADD_PEER = 'ADD_PEER'
const REMOVE_PEER = 'REMOVE_PEER'
const ADD_PRODUCER = 'ADD_PRODUCER'
const REMOVE_PRODUCER = 'REMOVE_PRODUCER'
const ADD_CONSUMER = 'ADD_CONSUMER'
const REMOVE_CONSUMER = 'REMOVE_CONSUMER'
const SET_PRODUCER_SCORE = 'SET_PRODUCER_SCORE'
const SET_CONSUMER_SCORE = 'SET_CONSUMER_SCORE'
const SET_PRODUCER_PAUSED = 'SET_PRODUCER_PAUSED'
const SET_PRODUCER_RESUMED = 'SET_PRODUCER_RESUMED'
const SET_CONSUMER_PAUSED = 'SET_CONSUMER_PAUSED'
const SET_CONSUMER_RESUMED = 'SET_CONSUMER_RESUMED'
const SET_PRODUCER_TRACK = 'SET_PRODUCER_TRACK'
const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS'
const DISPLAY_MODE = 'DISPLAY_MODE'
const ALL_STUDENTS_MUTED = 'ALL_STUDENTS_MUTED'

const mutations = {
  [DISPLAY_MODE] (state, { mode }) {
    state.displayMode = mode
  },
  [ALL_STUDENTS_MUTED] (state, { mutedGuests }) {
    state.allStudentsMuted = mutedGuests
  },
  [ALLOW_STUDENT_DEBTS] (state, { allowDebts }) {
    state.allowStudentDebts = allowDebts
  },
  [PEER_MIRRORED] (state, { peerId, mirrored }) {
    // Nothing to do
  },
  [PEER_MUTE] (state, { peerId, mute }) {
    // Nothing to do
  },
  [MUTE_STUDENTS] (state, { peerId, muted }) {
    state.allStudentsMuted = muted
  },
  [PEER_HOST] (state, { peerId, isHost }) {
    state.peersMap.get(peerId).isHost = isHost
  },
  [CONNECTION_QUALITY] (state, { peerId, quality }) {
    // Nothing to do
  },
  [SET_ROOM_STATE] (state, newState) {
    state.roomState = newState
  },
  [SET_MEDIA_CAPABILITIES] (state, canSendMic, canSendWebcam) {
    state.canSendMic = canSendMic
    state.canSendWebcam = canSendWebcam
  },
  [SET_WEBCAM_IN_PROGRESS] (state, flag) {
    state.webcamInProgress = flag
  },
  [SET_CAN_CHANGE_WEBCAM] (state, flag) {
    state.canChangeWebcam = flag
  },
  [SET_ROOM_ACTIVE_SPEAKER] (state, peerId) {
    // Nothing to do
  },
  [ADD_PEER] (state, peer) {
    state.peersMap.set(peer.id, peer)
  },
  [REMOVE_PEER] (state, peerId) {
    state.peersMap.delete(peerId)
  },
  [ADD_CONSUMER] (state, { peerId, consumer }) {
    const peer = state.peersMap.get(peerId)
    consumer.peer = peer
    if (peer) {
      peer.consumers.push(consumer)
    }
    state.consumerMap.set(consumer.id, consumer)
  },
  [ADD_PRODUCER] (state, producer) {
    state.producers.push(producer)
    state.producerMap.set(producer.id, producer)
  },
  [REMOVE_PRODUCER] (state, { producerId }) {
    const producer = state.producerMap.get(producerId)
    if (producer) {
      state.producerMap.delete(producerId)
      const prodIndex = state.producers.indexOf(producer)
      if (prodIndex >= 0) {
        state.producers.splice(prodIndex, 1)
      }
    }
  },
  [SET_PRODUCER_TRACK] (state, { producerId, track }) {
    const producer = state.producerMap.get(producerId)
    if (producer) {
      producer.track = track
    }
  },
  [SET_PRODUCER_SCORE] (state, { producerId, score }) {
    // const producer = state.producerMap.get(producerId)
    // if (producer && score && Array.isArray(score) && score.length > 0) {
    //   let sum = 0
    //   score.forEach((s) => {
    //     sum += s.score
    //   })
    //   producer.score = sum / score.length
    //
    //   let finalQuality = 10
    //   for (const prod of state.producers) {
    //     if (prod.quality && prod.quality < finalQuality) {
    //       finalQuality = prod.quality
    //     }
    //   }
    //   this.dispatch('updateConnectionQuality', { peerId: null, quality: finalQuality })
    // }
  },
  [SET_CONSUMER_SCORE] (state, { consumerId, score }) {
    // const consumer = state.consumerMap.get(consumerId)
    // if (consumer && score) {
    //   const consumerScore = Array.isArray(score) ? score : [score]
    //   let sum = 0
    //   consumerScore.forEach((s) => {
    //     sum += s.score
    //   })
    //   consumer.score = sum / consumerScore.length
    //   const peer = consumer.peer
    //   if (peer) {
    //     let finalQuality = 10
    //     for (const cons of peer.consumers) {
    //       if (cons.quality && cons.quality < finalQuality) {
    //         finalQuality = cons.quality
    //       }
    //     }
    //     this.dispatch('updateConnectionQuality', { peerId: peer.id, quality: finalQuality })
    //   }
    // }
  },
  [SET_PRODUCER_PAUSED] (state, { producerId }) {
    // eslint-disable-next-line no-console
    console.log(`[!!! TODO !!!] SET_PRODUCER_PAUSED [${producerId}]`)
  },
  [SET_PRODUCER_RESUMED] (state, { producerId }) {
    // eslint-disable-next-line no-console
    console.log(`[!!! TODO !!!] SET_PRODUCER_RESUMED [${producerId}]`)
  },
  [SET_CONSUMER_PAUSED] (state, { consumerId }) {
    // eslint-disable-next-line no-console
    console.log(`[!!! TODO !!!] SET_CONSUMER_PAUSED [${consumerId}]`)
  },
  [SET_CONSUMER_RESUMED] (state, { consumerId }) {
    // eslint-disable-next-line no-console
    console.log(`[!!! TODO !!!] SET_CONSUMER_RESUMED [${consumerId}]`)
  },
  [REMOVE_ALL_NOTIFICATIONS] (state) {
    // eslint-disable-next-line no-console
    console.log('[!!! TODO !!!] REMOVE_ALL_NOTIFICATIONS')
  },
  [REMOVE_CONSUMER] (state, { consumerId, peerId }) {
    state.consumerMap.delete(consumerId)
    const peer = state.peersMap.get(peerId)
    if (peer) {
      let consIndex = -1
      let index = 0
      for (const cons of peer.consumers) {
        if (cons.id === consumerId) {
          consIndex = index
          break
        }
        index++
      }
      if (index > 0) {
        peer.consumers.splice(consIndex, 1)
      }
    }
  }
}

const actions = {
  updateConnectionQuality ({ commit }, { peerId, quality }) {
    commit(CONNECTION_QUALITY, { peerId, quality })
  },
  SET_ROOM_STATE ({ commit }, payload) {
    commit(SET_ROOM_STATE, payload.state)
  },
  REMOVE_ALL_NOTIFICATIONS ({ commit }, payload) {
    commit(REMOVE_ALL_NOTIFICATIONS, payload)
  },
  SET_MEDIA_CAPABILITIES ({ commit }, payload) {
    commit(SET_MEDIA_CAPABILITIES, payload.canSendMic, payload.canSendWebcam)
  },
  SET_WEBCAM_IN_PROGRESS ({ commit }, payload) {
    commit(SET_WEBCAM_IN_PROGRESS, payload.flag)
  },
  SET_CAN_CHANGE_WEBCAM ({ commit }, payload) {
    commit(SET_CAN_CHANGE_WEBCAM, payload)
  },
  SET_ROOM_ACTIVE_SPEAKER ({ commit }, payload) {
    commit(SET_ROOM_ACTIVE_SPEAKER, payload)
  },
  ADD_PEER ({ commit }, payload) {
    commit(ADD_PEER, payload.peer)
  },
  PEER_HOST ({ commit }, payload) {
    commit(PEER_HOST, payload)
  },
  REMOVE_PEER ({ commit }, payload) {
    commit(REMOVE_PEER, payload.peerId)
  },
  SET_CONSUMER_CURRENT_LAYERS ({ commit }, payload) {
    // eslint-disable-next-line no-console
    console.log('----> TODO - SET_CONSUMER_CURRENT_LAYERS', payload)
  },
  ADD_PRODUCER ({ commit }, payload) {
    commit(ADD_PRODUCER, payload.producer)
  },
  REMOVE_PRODUCER ({ commit }, payload) {
    commit(REMOVE_PRODUCER, payload)
  },
  ADD_CONSUMER ({ commit }, payload) {
    const { peerId, consumer } = payload
    commit(ADD_CONSUMER, { peerId, consumer })
  },
  SET_PRODUCER_SCORE ({ commit }, payload) {
    const { producerId, score } = payload
    commit(SET_PRODUCER_SCORE, { producerId, score })
  },
  SET_CONSUMER_SCORE ({ commit }, payload) {
    const { consumerId, score } = payload
    commit(SET_CONSUMER_SCORE, { consumerId, score })
  },
  SET_PRODUCER_PAUSED ({ commit }, payload) {
    const { producerId } = payload
    commit(SET_PRODUCER_PAUSED, { producerId })
  },
  SET_PRODUCER_RESUMED ({ commit }, payload) {
    const { producerId } = payload
    commit(SET_PRODUCER_RESUMED, { producerId })
  },
  SET_CONSUMER_PAUSED ({ commit }, payload) {
    const { consumerId } = payload
    commit(SET_CONSUMER_PAUSED, { consumerId })
  },
  SET_CONSUMER_RESUMED ({ commit }, payload) {
    const { consumerId } = payload
    commit(SET_CONSUMER_RESUMED, { consumerId })
  },
  REMOVE_CONSUMER ({ commit }, payload) {
    const { consumerId, peerId } = payload
    commit(REMOVE_CONSUMER, { consumerId, peerId })
  },
  PEER_MIRRORED ({ commit }, payload) {
    commit(PEER_MIRRORED, payload)
  },
  PEER_MUTE ({ commit }, payload) {
    commit(PEER_MUTE, payload)
  },
  MUTE_STUDENTS ({ commit }, payload) {
    commit(MUTE_STUDENTS, payload)
  },
  DISPLAY_MODE ({ commit }, payload) {
    commit(DISPLAY_MODE, payload)
  },
  ALL_STUDENTS_MUTED ({ commit }, payload) {
    commit(ALL_STUDENTS_MUTED, payload)
  },
  ALLOW_STUDENT_DEBTS ({ commit }, payload) {
    commit(ALLOW_STUDENT_DEBTS, payload)
  },
  SET_PRODUCER_TRACK ({ commit }, payload) {
    commit(SET_PRODUCER_TRACK, payload)
  }
}

const getters = {
  me () {
    return state
  }
}

const store = {
  state,
  mutations,
  actions,
  getters
}

export default store
