<template>
  <div
    class="control has-icons-right kydemy-text-input"
    :class="[{'has-icons-left': leftIcon || flagIconCode, 'is-loading': loading || loadingNoDisable}, extraClasses]"
    :style="{width: fieldWidth, 'max-width': maxWidth ? maxWidth : null }"
  >
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      ref="txtTextArea"
      v-model="inputText"
      class="textarea"
      v-tooltip="{ content: tooltip, trigger: 'manual', show: focused, placement: 'bottom', classes: tooltipClass }"
      :placeholder="placeholder"
      :minlength="minLength"
      :maxlength="maxLength"
      :autofocus="autoFocus"
      :class="{'is-danger': validation && validation.$error, [`is-${size}`]: size, 'is-rounded': isRounded, 'has-text-right': hasTextRight,'has-text-centered': hasTextCenter}"
      :style="{'padding-left': leftIcon ? '2rem' : '0'}"
      :disabled="disabled || loading"
      :rows="rows"
    />
    <input
      v-else
      :id="id"
      ref="txtInput"
      v-model="inputText"
      v-tooltip="{ content: tooltip, trigger: 'manual', show: focused, placement: 'bottom', classes: tooltipClass }"
      class="input"
      :type="type"
      :minlength="minLength"
      :maxlength="maxLength"
      :autocomplete="'disabled_'+Math.random()*10000"
      :autofocus="autoFocus"
      :class="{'is-danger': validation && validation.$error, [`is-${size}`]: size, 'is-rounded': isRounded, 'has-text-right': hasTextRight,'has-text-centered': hasTextCenter}"
      :disabled="disabled || loading"
      :placeholder="placeholder"
      @click.stop.prevent="$emit('click', this)"
      @keydown="onKeyDown"
      @blur="onBlur"
      @focus="onFocus"
    >
    <span
      v-if="flagIconCode"
      class="icon is-left"
    >
      <span
        class="flag-icon"
        :class="'flag-icon-'+getLangIconCode(flagIconCode)"
      />
    </span>
    <span
      v-else-if="leftIcon"
      class="icon is-small is-left"
    >
      <font-awesome-icon :icon="leftIcon"/>
    </span>
    <span
      v-if="addClear && inputText.length > 0"
      class="icon is-medium is-right has-text-danger-dark cursor-pointer"
      @click="clearField"
    >
      <font-awesome-icon size="xl" icon="times-circle"/>
    </span>
    <span
      v-else-if="rightIcon && !loading && !loadingNoDisable"
      class="icon is-small is-right"
      :class="rightIconClass"
    >
      <font-awesome-icon :icon="rightIcon"/>
    </span>
    <transition name="slideupdown">
      <span
        v-if="errorMessage"
        class="help has-text-danger has-text-centered"
      >
        {{ errorMessage }}
      </span>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'KydemyTextInput',
  props: {
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    leftIcon: { type: [String, Array], default: 'search' },
    forcedRightIcon: { type: [String, Array], default: null },
    validation: { type: Object, default: null },
    minLength: { type: Number, default: 1 },
    maxLength: { type: Number, default: 10000 },
    rows: { type: Number, default: 2 },
    id: { type: [String], default: null },
    errorMessages: { type: Object, default: null },
    autoComplete: { type: [String], default: null },
    autoFocus: { type: [Boolean], default: false },
    size: { type: String, default: 'normal' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingNoDisable: { type: Boolean, default: false },
    isFullWidth: { type: Boolean, default: false },
    minWidth: { type: String, default: null },
    maxWidth: { type: String, default: null },
    isRounded: { type: Boolean, default: false },
    hasTextRight: { type: Boolean, default: false },
    hasTextCenter: { type: Boolean, default: false },
    autoTrim: { type: Boolean, default: false },
    tooltip: { type: String, default: null },
    tooltipClass: { type: String, default: 'kydemyTooltip' },
    flagIconCode: { type: String, default: null },
    extraClasses: { type: String, default: null },
    addClear: { type: Boolean, default: false }
  },
  data () {
    return {
      inputText: '',
      errorMessage: null,
      focused: false
    }
  },
  computed: {
    fieldWidth () {
      if (this.isFullWidth) {
        return '100%'
      } else if (this.minWidth) {
        return this.minWidth
      }
      return 'auto'
    },
    rightIconClass () {
      if (this.forcedRightIcon) {
        return 'has-text-grey'
      }
      if (this.validation) {
        if (this.validation.$error) {
          return 'has-text-danger'
        } else if (this.validation.$invalid === false) {
          return 'has-text-success'
        }
      }
      return null
    },
    rightIcon () {
      if (this.forcedRightIcon) {
        return this.forcedRightIcon
      }
      if (this.validation) {
        if (this.validation.$error) {
          return 'exclamation-triangle'
        } else if (this.validation.$invalid === false) {
          return 'check'
        } else if (this.inputText && this.inputText.length > 0 && this.inputText.trim().length > 0) {
          return 'exclamation'
        } else {
          return 'ellipsis-h'
        }
      }
      return null
    }
  },
  watch: {
    value (newVal) {
      if (newVal !== this.inputText) {
        this.inputText = newVal
        this.validation.$reset()
        this.$forceUpdate()
      }
    },
    inputText (newVal) {
      if (this.value !== newVal) {
        if (this.type === 'number') {
          this.$emit('input', Number(newVal))
        } else {
          if (this.autoTrim === true) {
            this.$emit('input', newVal.trim())
          } else {
            this.$emit('input', newVal)
          }
        }
      }
    },
    validation: {
      handler (newVal) {
        if (newVal.$error) {
          this.errorMessage = this.getErrorMessage(newVal)
        } else {
          this.errorMessage = null
        }
      },
      deep: true
    }
  },
  beforeMount () {
    this.inputText = this.value ? this.value : ''
  },
  mounted () {
    if (this.autoFocus === true) {
      setTimeout(this.focus, 500)
    }
  },
  methods: {
    clearField () {
      this.inputText = ''
    },
    getErrorMessage (validation) {
      let selectedError = null
      for (const key in validation) {
        if (!key.includes('$') && key in validation && validation[key] === false) {
          selectedError = key
          break
        }
      }
      if (selectedError) {
        if (this.errorMessages && this.errorMessages[selectedError]) {
          return this.errorMessages[selectedError]
        }
        switch (selectedError) {
          case 'required':
            return this.$t('validations.messages._default')
          case 'email':
            return this.$t('validations.messages.email')
          case 'minLength':
            return this.$t('validations.messages.minLength', { num: this.validation && this.validation.$params && this.validation.$params.minLength ? this.validation.$params.minLength.min : 1 })
          case 'maxLength':
            return this.$t('validations.messages.maxLength', { num: this.validation && this.validation.$params && this.validation.$params.maxLength ? this.validation.$params.maxLength.max : 1 })
          case 'minValue':
            return this.$t('validations.messages.minValue', { num: this.validation && this.validation.$params && this.validation.$params.minValue ? this.validation.$params.minValue.min : 1 })
          case 'maxValue':
            return this.$t('validations.messages.maxValue', { num: this.validation && this.validation.$params && this.validation.$params.maxValue ? this.validation.$params.maxValue.max : 1 })
          case 'url':
            return this.$t('validations.messages.url')
        }
      }
      return null
    },
    onBlur () {
      this.focused = false
      this.$emit('blur')
    },
    onFocus () {
      this.focused = true
      this.$emit('focus')
    },
    focus () {
      if (this.type === 'textarea' && this.$refs.txtTextArea && this.$refs.txtTextArea.focus) {
        this.$refs.txtTextArea.focus()
      } else if (this.$refs.txtInput && this.$refs.txtInput.focus) {
        this.$refs.txtInput.focus()
      }
    },
    onKeyDown (event) {
      let keyCode = null
      if (event.key !== undefined) {
        keyCode = event.code
      } else if (event.keyIdentifier !== undefined) {
        keyCode = event.keyIdentifier
      } else if (event.keyCode !== undefined) {
        keyCode = event.keyCode
      }

      if (keyCode === 13 || keyCode === 'Enter') {
        this.$emit('enter-key')
      } else if (keyCode === 27 || keyCode === 'Escape') {
        this.$emit('esc-key')
      } else if (keyCode === 38 || keyCode === 'ArrowUp') {
        this.$emit('up-key')
      } else if (keyCode === 40 || keyCode === 'ArrowDown') {
        this.$emit('down-key')
      }
    }
  }
}
</script>
