/* eslint-disable no-console */

import rest from './rest'

class KSocket {
  constructor (eventHub) {
    this._reconnectTimeout = null
    this._ws = null
    this._hub = eventHub
    this._statusInterval = null
    this._connected = false
    this._closeCnx = false
  }

  connect () {
    this._closeCnx = false
    if (this._ws == null && this._statusInterval === null) {
      this._ws = this._createWebSocket()
      this._statusInterval = setInterval(this.requestStatusUpdate.bind(this), 60000 * 5) // 5 min
    }
  }

  sendMessage (type, data) {
    if (!this._connected) {
      console.log('[WS:send] cannot send, connection not ready, trying again in 1s')
      setTimeout(this.sendMessage.bind(this, type, data), 1000)
      return
    }
    const message = {
      type,
      data
    }
    console.log(`[WS:send] Sending message "${type}"`, message.data)
    this._ws.send(JSON.stringify(message))
  }

  requestStatusUpdate () {
    this.sendMessage('status')
  }

  _processMessageResponse (data) {
    const wsMessage = JSON.parse(data)
    this._hub.$emit('ws-' + wsMessage.type, wsMessage.data)
  }

  close () {
    if (this._ws != null) {
      this._closeCnx = true
      this._ws.close()
      clearTimeout(this._reconnectTimeout)
      clearInterval(this._statusInterval)
      this._ws = null
      this._reconnectTimeout = null
      this._statusInterval = null
    }
  }

  onOpen (e) {
    console.log('[WS:open] Connection established' + e)
    this.requestStatusUpdate()
    this._connected = true
  }

  onMessage (e) {
    console.log(`[WS:message] Data received from server: ${e.data}`)
    this._processMessageResponse(e.data)
  }

  onClose (e) {
    this._connected = false
    if (e.wasClean || this._closeCnx === true) {
      console.log(`[WS:close] Connection closed cleanly, code=${e.code} reason=${e.reason}`)
    } else {
      console.log('[WS:close] Connection died.... trying to reconnect in 10s')
      this.tryReconnect()
    }
  }

  onError (error) {
    console.log(`[WS:error] Connection error, code=${error.code} reason=${error.reason}. Trying again in 10s`)
    this.tryReconnect()
  }

  tryReconnect () {
    if (this._reconnectTimeout != null) {
      return
    }
    this._reconnectTimeout = setTimeout(this._reconnect.bind(this), 10000)
  }

  _createWebSocket () {
    const ws = rest.newWebSocket()
    ws.onopen = this.onOpen.bind(this)
    ws.onmessage = this.onMessage.bind(this)
    ws.onclose = this.onClose.bind(this)
    ws.onerror = this.onError.bind(this)
    return ws
  }

  _reconnect () {
    console.log('[WS:reconnect] Attempting to reconnect', this._ws)
    this._reconnectTimeout = null
    this._ws = this._createWebSocket()
  }
}

export default KSocket
